@use "../utils/mixins";
@use "../utils/variables";

nav {

    @include mixins.breakpoint() {

        background-color: variables.$dark_midnight_blue;

        flex: 0 0;

    }

    .navigation__container {

        @include mixins.breakpoint() {

            padding: 8px 0 0 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

        }
        
        .push__right {
          margin-left: auto;
        }
        
        li {
            .set__bulb__icon{
              color: yellow;
              font-size: 21px;
              position: absolute;
              line-height: 37px;
              margin-left: -2px;
            }
            
            .empty__space::before{
              content: '..';
              opacity: 0;
            }

            .nav__link {

                @include mixins.breakpoint() {
					font: inherit;
                    font-size: 18px;
                    line-height: 40px;

                    font-weight: variables.$medium;

                    color: variables.$white;
					cursor: pointer;

					border:0;
					background: none;

                    display: block;
                    padding: 0 16px;

                    text-decoration: none;


                }

                &:hover {

                    @include mixins.breakpoint() {

                        background-color: rgba(variables.$white, 0.15);

                    }

                }

                &.is-active {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;
                        background-color: variables.$white;

                    }

                }

            }

        }

    }

}
