@use "../utils/mixins";

.date-range-dropdown {
  z-index: 99;
  
  .date-range-dropdown-trigger {
    width: 100%;
    background-color: #fff;
    border: solid 1px #cccccc;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 8px;
    display: block;
    height: 40px;
  }
  .date-range-dropdown-trigger:hover {
    border: 1px solid #999;
  }
  
  .date-range-dropdown-trigger:focus {
    border: 1px solid #000;
  }
  
  .date-range-dropdown-trigger:after {
    border: none;
    content: 'expand_more';
    font-family: 'Material Icons Outlined';
    pointer-events: none;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .dropdown__content{
    margin-top: -9px;
    width: 400px;
    padding: 0px;
    right: inherit;
    border-radius: 2px;
    background: white;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    @include mixins.box-shadow(0 0 0 1px rgba(0,0,0,0.10));
  }
  
  .dropdown__content::before{
    visibility: hidden;
  }
}

.dropdown_overlay {
  z-index: 98;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

}
