@use "../utils/mixins";

.page__head {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    .page__actions {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;

            margin: 0 0 0 auto;

        }

        li {

            @include mixins.breakpoint() {

                margin: 0 0 0 16px;

            }

        }

    }

}