@use "../utils/mixins";

.action__list {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    li {

        @include mixins.breakpoint() {
            margin: 0 8px 0 0;
        }

        &:last-child {
            @include mixins.breakpoint() {
                margin: 0;
            }
        }

    }

}
