@use "../utils/mixins";
@use "../utils/variables";

.assoc-g__head {

}

.assoc-g__body {

    .assoc-g__column {

        @include mixins.breakpoint() {

            height: 540px;

            border: solid 1px variables.$medium_gray;

            display: flex;
            flex-direction: column;

        }

        .assoc-g__column__head {

            @include mixins.breakpoint() {

                flex: none;

                height: 46px;

                font-size: 16px;
                line-height: 46px;
                font-weight: variables.$bold;

                padding: 0 16px;

                border-bottom: solid 1px variables.$medium_gray;

                color: variables.$black;
                background-color: variables.$light_gray;

            }

        }

        .assoc-g__column__body {

            @include mixins.breakpoint() {
                flex: 0 0 0;
            }

            .assoc-g__table-container {
            }

        }

    }

    .assoc-g__transition-controls {

        @include mixins.breakpoint() {

            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }

        .btn {

            @include mixins.breakpoint() {
                margin: 0 0 16px 0;
            }

            &:last-child {

                @include mixins.breakpoint() {
                    margin: 0;
                }

            }

            .material-icons-outlined {
                @include mixins.breakpoint() {
                    margin: 0;
                }
            }

        }

    }

}

.assoc-g__foot {

}

.assoc-g__controls {

    @include mixins.breakpoint() {

        height: 100%;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

    }

    li {

        @include mixins.breakpoint() {

            margin: 0 16px 0 0;

        }

        &:last-child {

            @include mixins.breakpoint() {
                margin: 0;
            }

        }

        button {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;
				border:0!important;
				cursor: pointer;

                font-size: 16px;
                line-height: 24px;
                font-weight: variables.$bold;

                text-decoration: none;


            }

            &:hover {

                @include mixins.breakpoint() {
                    color: variables.$black;
                }

            }

        }

    }

}
