@use "../utils/mixins";
@use "../utils/variables";

.breadcrumbs__nav {

    @include mixins.breakpoint() {

        background-color: variables.$white;

        flex: 0 0;

    }

    .breadcrumb {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;

        }

        .breadcrumb-item {

            @include mixins.breakpoint() {

                margin: 0 8px 0 0;

                font-size: 16px;
                line-height: 32px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                text-transform: capitalize;

            }

			&.revision {
				text-transform: initial;
			}

            &:not(:last-child) {

                &::after {

                    @include mixins.breakpoint() {

                        content: '>';

                        display: block;

                        font-size: 16px;
                        line-height: 32px;

                        margin: 0 0 0 8px;

                    }

                }

            }

            a {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;
                    text-decoration: none;

                    @include mixins.transition(all 300ms ease-in-out 0ms);

                }

                &:hover {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                    }

                }

            }

            &.active {

            }

        }

    }

}
