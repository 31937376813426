@use "../utils/mixins";
@use "../utils/variables";

// Checkbox with select

.smart_checkbox {
	position: relative;
	.checkbox {
		display: block;

		width: 16px;
		height: 16px;

		background-color: variables.$white;
		border: solid 1px variables.$dark_gray;
		border-radius: 2px;

		margin: 0 8px 0 0;

		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: center center;

		background-size: 12px 12px;

		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+);

		will-change: background-color;

		@include mixins.transition(all 300ms ease-in-out 0ms);
		&.checked {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+)!important;
			background-color: variables.$dark_midnight_blue;
			border-color: variables.$dark_midnight_blue;
		}
	}
	.select {
		width: 200px;
		position: absolute;
		left: 20px;
		top: 20px;
		background: #fff;
		padding: 5px 0;
		border-radius: 5px;
		color: variables.$black;
		z-index: +1;
		@include mixins.box-shadow(0 5px 25px 0 rgba(0, 0, 0, 0.25));
		.option {
			padding:10px;
			cursor: pointer;
			&:hover {
				background: variables.$light_gray;
			}
		}
	}
	.exit_select {
		width: 100vw;
		height: 100vw;
		top:0;
		left:0;
		position: fixed;
		z-index: 0;
	}
}
