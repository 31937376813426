@use "../utils/mixins";
@use "../utils/variables";

.data-set__container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

    }

    .data-set {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;

            padding: 8px 0;

			&.second {
				flex: none;
			}

        }


        span {

            @include mixins.breakpoint() {

                // flex: 1 1 0; Fix for overflowing text in Salutation

                text-align: left;
                padding: 0 0 0 8px;
                word-break: break-all;

            }

            &.data-label {

                @include mixins.breakpoint() {

                    text-align: right;
                    padding: 0 8px 0 0;

                }
				button {
					margin-top:-10px;
					margin-left: 10px;
					//color: $blue!important;
					//border-bottom: $blue 1px solid!important;
				}

            }
			&.red-value {
				color: variables.$amaranth_red;
			}

        }

    }

}
