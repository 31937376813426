@use "../utils/mixins";
@use "../utils/variables";

//  FORM LINK
.form-link {

    @include mixins.breakpoint() {

        font-size: 16px;
        line-height: 24px;

        color: variables.$blue;
        border-bottom: solid 1px variables.$blue;

        text-decoration: none;

        display: inline-block;

        white-space: nowrap;

        @include mixins.transition(all 300ms ease-in-out 0ms);

    }

    &:hover {

        @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;
            border-color: variables.$dark_midnight_blue;

        }

    }

}
