@use "../utils/mixins";

.container {

    @include mixins.breakpoint() {

        width: 1300px;
        margin: 0 auto;
    
    }

    &.narrow {

        @include mixins.breakpoint() {

            width: 840px;
            margin: 0 auto;

        }

    }

    &.middle-narrow {
        @include mixins.breakpoint() {
            width: 550px;
            margin: 0;
        }
    }

    &.narrower {

      @include mixins.breakpoint() {

          width: 440px;
          margin: 0 auto;

      }

  }

}