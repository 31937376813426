.prorated_fees_title {
  font-size: 18px;
  margin-bottom: 20px;
}

.add_prorated_fee_btn {
  margin-top: 14px;
  padding: 0 !important;
}

.prorated_fees .prorated_fee:last-child {
  margin-bottom: 16px;
}

.prorated_fees .prorated_fee:first-child {
  margin-top: 22px !important;
}

.prorated_fee {
  margin-top: 0 !important;
}

.horizontal_rule {
  border: 0.5px solid #cccccc;
  margin-top: 25px;
  margin-bottom: 25px;
}

.scissors-icon-container {
  background-image: url("../img/scissors.svg");
  background-size: cover;
  width: 18px;
  height: 18px;
  cursor: default !important;
}

.prorated-amount {
  margin-left: 3px;
}

.prorated-codes-dropdown {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: end;
}
