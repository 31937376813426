//  SPECIFIC MOBILE FIRST BREAKPOINT MIXIN FOR SCREENS
@mixin breakpoint($args: null) {
	//  CASE IF NO DATA IS PASSED WE SHALL OUTPUT MEDIA QUERY ONLY FOR SCREEN THIS MEANS STYLES WILL BE GLOBALLY AND INHERITABLE BY UPPER BREAKPOINTS
	@if $args == null {
		@media screen {
			@content;
		}
	}
	//  CASE IF DATA IS PASSED WE GENERATE BREAKPOINT FOR PASSED BREAKPOINT
	@else {
		@media screen and (min-width: $args) {
			@content;
		}
	}
}

@mixin msbp() {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}

//  BOX SHADOW
@mixin box-shadow($args...) {
	-webkit-box-shadow: $args;
	-moz-box-shadow: $args;
	-ms-box-shadow: $args;
	box-shadow: $args;
}

//  TRANSFORM
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

//  TRANSITION
@mixin transition($transitions) {
	-webkit-transition: $transitions;
	-moz-transition: $transitions;
	-ms-transition: $transitions;
	transition: $transitions;
}

@mixin clearfix() {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

//  KEYFRAMES MIXIN
@mixin keyframes($keyframes-name) {
	@-webkit-keyframes #{$keyframes-name} {
		@content;
	}
	@-moz-keyframes #{$keyframes-name} {
		@content;
	}
	@-o-keyframes #{$keyframes-name} {
		@content;
	}
	@keyframes #{$keyframes-name} {
		@content;
	}
}

//  ANIMATION MIXIN
@mixin animation($args...) {
	-webkit-animation: $args;
	-moz-animation:    $args;
	-o-animation:      $args;
	animation:         $args;
}

//  PLACEHOLDER
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

//	USERSELECT
@mixin user-select($args...) {
	-webkit-user-select: $args;
	-khtml-user-select: $args;
	-moz-user-select: $args;
	-ms-user-select: $args;
	user-select: $args;
}

//  FILTER
@mixin filter($args...) {
    -webkit-filter: $args;
    -moz-filter: $args;
    -o-filter: $args;
    -ms-filter: $args;
    filter: $args;
}