@use "../utils/mixins";
@use "../utils/variables";

footer {

    @include mixins.breakpoint() {

        margin: auto 0 0 0;

        border-top: solid 48px variables.$dark_midnight_blue;

    }

    .footer__container {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: column;

        }

        .footer__navigation {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;
                justify-content: center;

                padding: 16px 0;

            }

            .footer__navigation__link {

                @include mixins.breakpoint() {

                    margin: 0 16px;

                    font-size: 16px;
                    line-height: 24px;

                    display: block;

                    color: variables.$dark_midnight_blue;


                    text-decoration: none;

                }

                &:hover {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                    }

                }

            }

        }

        .footer__copyright {

            @include mixins.breakpoint() {

                border-top: solid 1px variables.$random_gray;

                padding: 16px 0;

            }

            p {

                @include mixins.breakpoint() {

                    font-size: 16px;
                    line-height: 24px;

                    text-align: center;

                }

            }

        }

    }

}
