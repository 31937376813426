@use "../utils/variables";

.handicap_review_details {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background-color: variables.$white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  margin: 0.5rem;
  padding: 0.5rem 1rem;
}

.handicap_review_modal_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.handicap_review_modal_title_item {
  font-size: x-large;
  color: variables.$white;
  margin-right: 1rem;
}

.vertical_divider {
  border-left:1px solid #38546d; 
  border-right:1px solid #16222c; 
  height:80px;
  right:249px;
  top:10px; 
}

.bubble{
  border-radius: 0.7rem;
  padding: 0.1rem;
  min-width: 3.2rem;
  max-height: 29px;
  width: fit-content;
  font-size: 12px;
}

.percent_bubble {
  color: rgb(94, 140, 170);
  background-color: rgb(233, 235, 245);
}

.low_bubble {
  color: white;
  background-color: rgb(19, 54, 92);
}

.high_bubble {
  color: white;
  background-color: rgb(56, 137, 203);
}

@mixin applied_colors {
  color: rgb(29,186,112);
  background-color: rgb(235,254,243);
}

@mixin denied_colors {
  color: rgb(229, 84, 58);
  background-color: rgb(254, 214, 199);
}

@mixin info_colors {
  color: rgb(72,165,221);
  background-color: rgb(236,248,255);
}

@mixin pending_colors {
  color: rgb(233,160,83);
  background-color: rgb(254,240,199);
}

@mixin zero_colors {
  color: rgb(233,160,83);
  background-color: rgb(209, 250, 223);
}

@mixin recommended_adjustment_symbol {
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.7rem;
  padding: 0.3rem !important;
}

@mixin review-status-panel {
  border-radius: 1.5rem;
  padding: 0 0.6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recommended_adjustment_symbol-pending {
  @include pending_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-applied {
  @include info_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-denied {
  @include denied_colors;
  @include recommended_adjustment_symbol;
}

.recommended_adjustment_symbol-zero{
  @include zero_colors;
  @include recommended_adjustment_symbol;
}

.handicap_review_status_panel-applied {
  @include review-status-panel;
  @include applied_colors;
}

.handicap_review_status_panel-denied {
  @include review-status-panel;
  @include denied_colors;
}

.status_point {
  font-size: 3rem;
  padding: none;
  padding-bottom: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0.75rem;
}

.details_panel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0.2rem;
}

.deny_button {
  color: variables.$light_green;
  background-color: variables.$white;
  border: solid variables.$light_green !important;
}

.text_percent {
  font-weight: 700;
}

.text_value {
  color: rgb(0, 40, 81);
  font-size: 2rem;
  font-weight: 700;
}

.text_rounds {
  color: rgb(0, 40, 81);
  font-weight: 700;
}

.text_title {
  color: black;
  font-size: 18px;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
}

.padding_small {
  padding: 0.1rem 0 !important;
  margin: 0.1rem 0 !important;
}

.text_subtitle{
  color: gray;
  font-size: x-large;
}

.chart_cards {
  padding: 0.8rem 0;
}

.text_info {
  color: gray;
  font-size: large;
  line-height: 13px;
}

@mixin bullet {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.2rem;
}

.bullet_low {
  @include bullet;
  background-color: rgb(19, 54, 92);;
}

.bullet_high {
  @include bullet;
  background-color: rgb(56, 137, 203);
}

.text_field {
  color: rgb(100, 100, 100);
  font-size: 1.3rem;
}

.text_note {
  font-size: 14px;
  font-style: italic;
  margin-top: 0.7rem !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header_cell {
  color: variables.$white;
  background-color: variables.$dark_midnight_blue;
  font-weight: variables.$bold;
  font-size: 14px;
  height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.sub_header_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.sub_header_cell {
  background-color: rgb(247, 247, 247);
  font-size: 12px;
  width: 100%;
  height: 2.5rem;
  border: solid 1px variables.$light_gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.simple_cells_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.simple_cell {
  background-color: white;
  width: 100%;
  height: 2.5rem;
  border: solid 1px variables.$light_gray;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
