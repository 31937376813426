@use "../utils/mixins";
@use "../utils/variables";

.golfer-data-box__container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

    }

    .golfer-data-box {

        @include mixins.breakpoint() {
            margin: 0 0 16px 0;
        }

        .data-box__title {

            @include mixins.breakpoint() {

                color: variables.$black;

                font-size: 14px;
                line-height: 32px;

                text-align: center;

                background-color: variables.$light_gray;
                border: solid 1px variables.$medium_gray;

            }

        }

        .data-box__body {

            @include mixins.breakpoint() {

                padding: 16px;

                border: solid 1px variables.$medium_gray;
                border-top: none;

                display: flex;
                flex-direction: column;

            }

            span {
                
                @include mixins.breakpoint() {
                    text-align: center;
                }


                &.large {

                    @include mixins.breakpoint() {

                        font-size: 24px;
                        line-height: 24px;
                        font-weight: variables.$bold;

                        margin: 0 0 8px 0;

                    }

                }
    
                &.spaced-item {
                    @include mixins.breakpoint() {
                        margin: 16px 0 0 0;
                    }
                }

            }


        }

        &.blank {

            @include mixins.breakpoint() {

            }

            .data-box__body {

                @include mixins.breakpoint() {

                    padding: 0;
                    border: none;
                    
                }

            }

        }

    }

}