@use "../utils/variables";

.middle {
  margin: auto;
}

.light-text {
  line-height: 12px;
  font-weight: variables.$light;
  font-size: 12px;
}

.regular-text {
  line-height: 14px;
  font-weight: variables.$regular;
  font-size: 14px;

  text-align: justify;
}

.full-width { 
  width: 100%;

  margin-left: 10px;
  margin-top: 5px;
}

.full-width-no-lef-margin { 
  width: 100%;
  
  margin-top: 5px;
}

.afs-title {
  font-weight: variables.$bold;

  margin-bottom: 10px;
}

.afs-data {
  line-height: 18px;
}

.afs-info-wrapper {
  display: flex;
  flex-direction: row;

  width: 100%;
}

.no-padding {
  padding: 0 !important;
}
