@use "../utils/mixins";

.form__image-preview {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

    }

    img {

        @include mixins.breakpoint() {

            max-width: 300px;
            max-height: 150px;

            margin: 0 auto 8px;

        }

    }

    strong {

        @include mixins.breakpoint() {
            text-align: center;
        }

    }

}