@use "../utils/mixins";
@use "../utils/variables";

.page__sub__head {

    @include mixins.breakpoint() {

        margin: 0 0 16px 0;

    }

    .golfer__data {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            align-items: center;
        
        }

        li {

            @include mixins.breakpoint() {

                padding: 0 16px 0 0;
                margin: 0 16px 0 0;
                border-right: solid 1px variables.$medium_gray;

            }

            &:last-child {
                @include mixins.breakpoint() {
                    padding: 0;
                    margin: 0;
                    border: none;
                }
            }

            a {

                @include mixins.breakpoint() {
                    color: variables.$blue;
                    display: inline-block;
                }

            }

        }

    }

}