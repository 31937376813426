@use "../utils/mixins";
@use "../utils/variables";

.hbh-container {

    @include mixins.breakpoint() {
		width: 50%;
		padding:8px;
    }

	&.disabled{
		pointer-events: none;
		.hbh_cell{
			input {
				background-color: variables.$light_gray;
			}

		}
	}

    &.score-results{
        margin: 0!important;
    }

    .hbh-pre_head {

        @include mixins.breakpoint() {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .hbh-pre_cell {

            @include mixins.breakpoint() {

                height: 36px;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;

            }

            &:first-child {
                @include mixins.breakpoint() {
                    text-align: left;
                }
            }
            &:last-child {
                @include mixins.breakpoint() {
                    text-align: right;
                }
            }

            .hbh-title {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-weight: variables.$bold;

                    font-size: 12px;

                    text-transform: uppercase;

                    font-feature-settings: "tnum";

                    margin: 0 0 5px 0;

                    display: block;

                }

            }

            .hbh-clear {

                @include mixins.breakpoint() {

                    color: variables.$cerulean_blue;

                    font-size: 14px;

                    display: block;

                    margin: 0 0 10px 0;

                    cursor: pointer;

                }

            }

        }

    }

    .hbh {

        .hbh_row {

            @include mixins.breakpoint() {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
				background-color: variables.$light_gray;
				border: 1px solid variables.$new_gray;
				border-top:0;
            }

            &.hole {

                @include mixins.breakpoint() {
                    color: variables.$white;
                    background-color: variables.$dark_midnight_blue;
					border: 1px solid variables.$dark_midnight_blue;
                }
				.hbh_cell{ border:none}

			}

            &.adjusted-score, &.stroke{
                .hbh_cell{
                    padding-top: 5px;
                }
            }

            &.score {

                @include mixins.breakpoint() {
                    background-color: variables.$white;
                }

            }

            .hbh_cell {

                @include mixins.breakpoint() {

                    flex: 1;

                    text-align: center;

                    font-size: 12px;
                    line-height: 36px;

                    font-feature-settings: "tnum";
                
                    border-left: solid 1px variables.$new_gray;

                }

				&.gray {
					background-color: variables.$light_gray;
				}

				input {
					border: none;
					padding: 0;
					margin: 0;
					font-size:12px;
					font-weight: bold;
					text-align: center;
					height: 36px;
				}

				input:focus {
					-webkit-box-shadow: inset 0 0 0 2px variables.$dark_midnight_blue;
					-moz-box-shadow: inset 0 0 0 2px variables.$dark_midnight_blue;
					box-shadow: inset 0 0 0 2px variables.$dark_midnight_blue;
				}

                &:first-child {
                    @include mixins.breakpoint() {
                        border-left: none;
                    }
                }

                &.label {

                    @include mixins.breakpoint() {

                        flex: none;

                        width: 85px;

                        text-align: left;
						
						margin: 0;

                        padding: 0 0 0 20px;

                        &.adjusted-score, &.stroke{
                            padding: 10px 0px 10px 20px;
                            line-height: 1;
                        }

                    }

                }

            }

        }

    }

}
