@use "../utils/mixins";
@use "../utils/variables";

.content__container {

    h4 {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 16px;
            line-height: 24px;
            font-weight: variables.$bold;

            margin: 0 0 16px 0;

        }

    }

    .content {

        @include mixins.breakpoint() {

        }

        &.scrollable {

            @include mixins.breakpoint() {
            
                max-height: 100px;

                overflow-x: hidden;
                overflow-y: auto;

            }

        }

    }

    p {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 14px;
            line-height: 1.5;

        }

    }

}