@use "mixins";
@use "variables";

h2 {

    @include mixins.breakpoint() {

        color: variables.$dark_midnight_blue;

        font-size: 22px;
        font-weight: variables.$medium;

        margin: 0 0 32px 0;

    }

}