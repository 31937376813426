.no-margin-top {
	margin-top: 0!important;
}

.table-auto .data__table {
	table-layout: auto;
}

.table-auto .data__table thead tr th {
	width: auto;
}
