@use "../utils/mixins";
@use "../utils/variables";

input[type="text"],
input[type="password"],
input[type="mail"],
input[type="number"],
//  DATE PICKER INPUT

.DayPickerInput input {

    @include mixins.breakpoint() {

        width: 100%;

        background-color: variables.$white;
        border: solid 1px variables.$medium_gray;

        font-size: 16px;
        line-height: 24px;

        padding: 7px 8px;

        display: block;

        height: 40px;

    }

	&.small {
		width:30px;
		height:30px;
		text-align: center;
		padding: 5px;
		margin: 0 10px;
	}
  &.large {
    width:650px;
		height:150px;
		text-align: left;
		padding: 5px;
		margin: 0 10px;
    display: inline-block;
	}

	&.is-error {
		border: solid 1px variables.$amaranth_red;
	}

    &:hover {
        @include mixins.breakpoint() {
            border-color: variables.$dark_gray;
        }
    }

    &:focus {

        @include mixins.breakpoint() {

            border: solid 1px variables.$black;

            outline: none;

        }

    }

    &.search {

        @include mixins.breakpoint() {
            padding-right: 30px;
        }

    }

    //  PLACEHOLDEr
    @include mixins.breakpoint() {

        @include mixins.placeholder() {
            opacity: 0.5;
        }

    }

    &:disabled {

        @include mixins.breakpoint() {

            background-color: rgba(variables.$light_gray,0.5);

        }
		&:hover,&:focus {
			border: solid 1px variables.$medium_gray;
		}

    }

}

//  PASSWORD INPUT
input[type="password"] {

    @include mixins.breakpoint() {

        font-size: 32px;
        line-height: 24px;

        max-height: 48px;

    }

}

//  INPUT AUTOFILL RESET
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: variables.$dark_midnight_blue;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

//  INPUT DATE
.DayPickerInput {

    @include mixins.breakpoint() {

        position: relative;

    }

    &::after {

        @include mixins.breakpoint() {

            content: 'calendar_today';
            display: block;

            font-family: 'Material Icons Outlined';

            font-size: 16px;

            position: absolute;
            top: 12px;
            right: 12px;

        }

    }

}


//  SUB INPUT
.sub-input__info {

    @include mixins.breakpoint() {

        color: variables.$dark_gray;

        font-size: 14px;
        line-height: 24px;

    }

}
