@use "../utils/mixins";
@use "../utils/variables";

label,
.label {

    @include mixins.breakpoint() {

        font-size: 16px;
        line-height: 16px;
        font-weight: variables.$medium;

        margin: 0 0 8px 0;

        display: block;

        white-space: nowrap;

    }
	&.disabled {
		opacity: 0.5;

		pointer-events: none;
	}
	&.absolute-centered{
		position: absolute;
		right: -5px;
		top: 14px;
	}
    span {

        @include mixins.breakpoint() {

            color: variables.$cardinal;

        }

    }
  &.is-error {
		border: solid 1px variables.$amaranth_red;
	}

  &.length-error {
		color: variables.$amaranth_red;
	}

  &.blue {
		color: variables.$blue;
	}

}

.data-label {

    @include mixins.breakpoint() {

        color: variables.$black;

        font-size: 16px;
        line-height: 16px;
        font-weight: variables.$bold;

        margin: 0;

        display: block;

        white-space: nowrap;

    }

}
