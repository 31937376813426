@use "../utils/mixins";
@use "../utils/variables";

.badges__container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

        margin: 24px 0 0 0;

    }

    .default-badge__container {

        @include mixins.breakpoint() {
        }

    }

    .badge-list {

        @include mixins.breakpoint() {
        }

        .col {
            @include mixins.breakpoint() {
                margin: 0 0 24px 0;
            }
            &:nth-last-child(-n+6) {
                @include mixins.breakpoint() {
                    margin: 0;
                }
            } 
        }

    }

}

.badge__label {

    @include mixins.breakpoint() {

        margin: 16px 0 0 0;

        color: variables.$dark_gray;

    }

}

.badge {

    @include mixins.breakpoint() {

        width: 90px;
        height: 90px;

        border-radius: 50%;

        font-size: 14px;
        line-height: 24px;
        font-weight: variables.$bold;

        color: variables.$white;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        @include mixins.user-select(none);

    }

    &.is-active {

        @include mixins.breakpoint() {

            position: relative;
			border: 5px solid white;
        }

		&:before{
			content:'';
			width: 110px;
			height: 110px;
			position:absolute;
			z-index: 0;
			left:-10px;
			top: -10px;
			background-image: url(../img/border.svg);
			background-size: 100px 100px;
		}

        &:after {

            @include mixins.breakpoint() {
				position:relative;
				z-index:8;
                content: 'done';

                font-family: 'Material Icons Outlined';

                font-size: 32px;

            }

        }

    }

    &.color-00365F {
        @include mixins.breakpoint() {
            background-color: #00365F;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #00365F;
            }
        }
    }

    &.color-10644B {
        @include mixins.breakpoint() {
            background-color: #10644B;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #10644B;
            }
        }
    }

    &.color-5E7331 {
        @include mixins.breakpoint() {
            background-color: #5E7331;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #5E7331;
            }
        }
    }

    &.color-2592A8 {
        @include mixins.breakpoint() {
            background-color: #2592A8;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #2592A8;
            }
        }
    }

    &.color-363893 {
        @include mixins.breakpoint() {
            background-color: #363893;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #363893;
            }
        }
    }

    &.color-023971 {
        @include mixins.breakpoint() {
            background-color: #023971;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #023971;
            }
        }
    }

    &.color-0C63B9 {
        @include mixins.breakpoint() {
            background-color: #0C63B9;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #0C63B9;
            }
        }
    }

    &.color-625143 {
        @include mixins.breakpoint() {
            background-color: #625143;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #625143;
            }
        }
    }

    &.color-948354 {
        @include mixins.breakpoint() {
            background-color: #948354;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #948354;
            }
        }
    }

    &.color-000000 {
        @include mixins.breakpoint() {
            background-color: #000000;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #000000;
            }
        }
    }

    &.color-6F6F6F {
        @include mixins.breakpoint() {
            background-color: #6F6F6F;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #6F6F6F;
            }
        }
    }

    &.color-C45200 {
        @include mixins.breakpoint() {
            background-color: #C45200;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #C45200;
            }
        }
    }


    &.color-970E16 {
        @include mixins.breakpoint() {
            background-color: #970E16;
        }
        &.is-active {
            @include mixins.breakpoint() {
                box-shadow: 0 0 0 5px #970E16;
            }
        }
    }


}
