@use "../utils/mixins";
@use "../utils/variables";

.page__tabs {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

        position: relative;

    }

    .page__tab {

        @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;
            background-color: variables.$white;

            font-size: 16px;
            line-height: 40px;
            font-weight: variables.$bold;

            display: block;
            padding: 0 16px;

            text-decoration: none;

            opacity: 0.25;

            border: solid 1px variables.$dark_gray;
            border-bottom: none;

        }

        &.is-active {
			position: relative;
            @include mixins.breakpoint() {

                opacity: 1;
               	&:after {
					content: '';
					width: 100%;
					height: 4px;
					position: absolute;
					background-color: #fff;
					bottom: -2px;
					left: 0;
				}

            }

        }

    }

    .join__page__tab {

      @include mixins.breakpoint() {

          color: variables.$dark_midnight_blue;
          background-color: variables.$white;

          font-size: 16px;
          line-height: 40px;
          font-weight: variables.$bold;

          display: block;
          padding: 0 16px;
          margin: 4px 4px;

          text-decoration: none;

          opacity: 0.25;

          border: solid 1px variables.$dark_gray;

      }

      &.disabled{
        color: variables.$dark_gray;
        cursor: default;
        &:focus,
        &:hover,
        &:visited,
        &:link,
        &:active {
          text-decoration: none;
        }
      }

      &.is-active {
    position: relative;
          @include mixins.breakpoint() {

              opacity: 1;
               &:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        background-color: #fff;
        left: 0;
      }

          }

      }

  }

    //  SPECIAL ADDITIONS

    //  GOLFER BADGE
    .golfer__badge {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            align-items: center;

            position: absolute;

            bottom: -28px;
            right: 16px;

        }

        .eff {

            @include mixins.breakpoint() {

                color: variables.$dark_gray;

                font-size: 14px;
                line-height: 48px;

                margin: 0 16px 0 0;

            }

        }

        .circle {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: column;
                justify-content: center;

                width: 90px;
                height: 90px;

                background-color: variables.$white;
                border-radius: 50%;
                border: dashed 2px variables.$medium_gray;
                margin: 0 0 20px 10px;

            }

            .index {

                @include mixins.breakpoint() {

                    color: variables.$dark_gray;

                    font-size: 16px;
                    line-height: 18px;

                    text-align: center;

                }

            }

            .score {

                @include mixins.breakpoint() {

                    color: variables.$cardinal;

                    font-size: 24px;
                    line-height: 24px;
                    font-weight: variables.$bold;

                    text-align: center;

                }

            }

			.date {
				font-size: 12px;
				color: gray;
				text-align: center;
				font-weight: variables.$bold;
			}

			.gray {
				@include mixins.breakpoint() {
					color: variables.$dark_gray;
				}
			}

        }
		.info {
			margin-right: 8px;
			cursor: pointer;
		}

    }

}

.page__tab-panel {

    @include mixins.breakpoint() {

        padding: 16px;
        background-color: variables.$white;

        border: solid 1px variables.$dark_gray;

    }

}
