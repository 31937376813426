@use "../utils/mixins";
@use "../utils/variables";

.form__title {

    @include mixins.breakpoint() {

        color: variables.$dark_midnight_blue;

        font-size: 24px;
        line-height: 1;

        font-weight: variables.$medium;

    }

}

.form_box{
	background: variables.$light_gray;
	padding: 24px 32px;
	width: 100%;
	margin: auto;
}

.form__description {

    @include mixins.breakpoint() {

        color: variables.$dark_midnight_blue;

        font-size: 16px;
        line-height: 1.5;
        font-weight: variables.$medium;

    }

}

.form__separator {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        font-size: 24px;
        line-height: 40px;

        margin-bottom: 32px;

        text-transform: uppercase;

        position: relative;

        opacity: 0.25;

        margin: auto 0 0 0;

    }

    &::before {

        @include mixins.breakpoint() {

            content: '';

            height: 2px;
            width: 100%;

            display: block;

            background-color: variables.$dark_gray;

            position: absolute;

            top: 50%;

            @include mixins.transform(translate3d(0, -1px, 0));

        }

    }

    &.or {

        &::after {

            @include mixins.breakpoint() {

                content: 'Or';

                display: block;

                height: 100%;
                padding: 0 8px;

                color: variables.$black;
                background-color: variables.$white;

                z-index: 9;
                position: relative;

            }

        }

        &.small {

            &::before {

                @include mixins.breakpoint() {
                    display: none;
                }

            }

            &::after {

                @include mixins.breakpoint() {

                    color: variables.$black;

                    font-size: 18px;

                    padding: 0;

                }

            }

        }

    }

    &.arrow-right {

        .material-icons-outlined {
            @include mixins.breakpoint() {
                height: 40px;
                line-height: 40px;
            }
        }

        &::after {
            @include mixins.breakpoint() {
                display: none;
            }
        }

        &::before {

            @include mixins.breakpoint() {
                display: none;
            }

        }

    }

}
