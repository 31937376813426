@use "../utils/mixins";
@use "../utils/variables";

.picky  {

    .picky__input  {

        @include mixins.breakpoint() {

            padding: 0 8px;

            height: 40px;

            position: relative;

            border: solid 1px variables.$medium_gray;

        }

        &:hover {
            @include mixins.breakpoint() {
                border-color: variables.$dark_gray;
            }
        }
        &:focus {
            @include mixins.breakpoint() {
                border-color: variables.$black;
            }
        }

		&.picky__input--disabled {
			border-color: #cccccc;
			background-color: hsl(0,0%,95%);
		}

		&::after {

            @include mixins.breakpoint() {

                border: none;

                content: 'expand_more';
                font-family: 'Material Icons Outlined';

                pointer-events: none;

                color: variables.$black;

                font-size: 18px;
                line-height: 24px;

                text-align: center;

                width: 24px;
                height: 24px;

                display: block;

                margin: 0;

                position: absolute;

                top: 8px;
                right: 8px;

            }

        }


        span {

            @include mixins.breakpoint() {

                font-family: 'National',sans-serif;

                font-size: 16px;
                color: variables.$black;

            }

        }

    }

    .picky__dropdown {

        @include mixins.breakpoint() {
            width: auto;
            min-width: 100%;
            overflow-y: auto !important;

            margin: 8px 0 0 0;
            padding: 4px 0;

            border-radius: 2px;
            @include mixins.box-shadow(0 0 0 1px rgba(0,0,0,0.10));

        }

        .option {

            @include mixins.breakpoint() {

                height: 32px;
                line-height: 16px;

                display: flex;
                flex-direction: row;
                align-items: center;

                border: none;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                padding: 8px 0;
                border-right: solid 8px variables.$white;
                border-left: solid 8px variables.$white;

            }

            &:hover {
                @include mixins.breakpoint() {
                    background-color: variables.$light_gray;
                    border-right: solid 8px variables.$light_gray;
                    border-left: solid 8px variables.$light_gray;
                }
            }

            input[type="checkbox"] {

                @include mixins.breakpoint() {

                    width: 16px;
                    height: 16px;

                    margin: 0 8px 0 0;

                    flex-shrink: 0;

                }

            }

        }

    }

}


.width__100 {
  .picky__dropdown {
    width: 100%;
  }
}
