@use "../utils/mixins";
@use "../utils/variables";

.react-select-container {

	//&.with-scroll {
	//	.react-select__menu{
	//		height: 200px;
	//		overflow-y: scroll;
	//	}
	//}
	
	&.width-400 {
		.react-select__menu{
			width: 400px;
		}
	}
	
	&.overflow-y-cls{
		.react-select__menu-list {
			overflow-y: inherit !important;
		}
	}


	&.small {
		width:120px;
		margin-left: 10px;
	}
    .react-select__control {

        @include mixins.breakpoint() {

            height: 40px;

            border-radius: 0;

            border-color: variables.$medium_gray;

        }

        &:hover {
            @include mixins.breakpoint() {
                border-color: variables.$dark_gray;
            }
        }
        &:focus {
            @include mixins.breakpoint() {
                border-color: variables.$black;
            }
        }

        &.react-select__control--is-focused {

            @include mixins.breakpoint() {

                height: 40px;

                border: solid 1px variables.$black;
                @include mixins.box-shadow(none);

            }

        }

        .react-select__value-container {

            @include mixins.breakpoint() {

                height: 100%;
                max-height: 100%;

            }

            .react-select__placeholder {

                @include mixins.breakpoint() {
                    color: variables.$black;
                }

            }

            .react-select__input {

                @include mixins.breakpoint() {

                    position: absolute;

                    transform-origin: center center;

                    top: 0;

                }

            }

        }

        .react-select__indicators {

            .react-select__indicator-separator {

                @include mixins.breakpoint() {

                    display: none;

                }

            }

            .react-select__dropdown-indicator {

                &::after {

                    @include mixins.breakpoint() {

                        border: none;

                        content: 'expand_more';
                        font-family: 'Material Icons Outlined';

                        pointer-events: none;

                        font-size: 18px;
                        line-height: 24px;

                        text-align: center;

                        color: variables.$black;

                        width: 24px;
                        height: 24px;

                        display: block;

                        margin: 0;

                        position: absolute;

                        top: 8px;
                        right: 8px;

                    }

                }

                svg {

                    @include mixins.breakpoint() {
                        display: none;
                    }

                }

            }

        }

    }

	&.async {
		.react-select__dropdown-indicator {
			display: none!important;
		}
		.loadingIndicator {
			right: 0;
			margin-right: 0;
			padding-right: 0;
		}
	}

    .react-select__menu {

        @include mixins.breakpoint() {

            z-index: 99;
            border-radius: 2px;

            @include mixins.box-shadow(0 0 0 1px rgba(0,0,0,0.10));

        }

        .react-select__menu-list {

            .react-select__option {

                @include mixins.breakpoint() {

                    cursor: pointer;

                    color: variables.$black;
                    background-color: variables.$white;
					white-space: pre-wrap;

                }

                &.react-select__option--is-focused {
                    @include mixins.breakpoint() {
                        background-color: variables.$light_gray;
                    }
                }

            }

        }

    }

    &.is-error {
        .react-select__control {
            @include mixins.breakpoint() {
                border-color: variables.$cardinal;
            }
        }
    }

}

// selecting courses
.react-select-container {

	//&.with-scroll {
	//	.react-gray-select__menu{
	//		height: 200px;
	//		overflow-y: scroll;
	//	}
	//}

	&.small {
		width:120px;
		margin-left: 10px;
	}
    .react-gray-select__control {

        @include mixins.breakpoint() {

            height: 40px;

            border-radius: 0;

            border-color: variables.$medium_gray;

        }

        &:hover {
            @include mixins.breakpoint() {
                border-color: variables.$dark_gray;
            }
        }
        &:focus {
            @include mixins.breakpoint() {
                border-color: variables.$black;
            }
        }

        &.react-gray-select__control--is-focused {

            @include mixins.breakpoint() {

                height: 40px;

                border: solid 1px variables.$black;
                @include mixins.box-shadow(none);

            }

        }

        .react-gray-select__value-container {

            @include mixins.breakpoint() {

                height: 100%;
                max-height: 100%;

            }

            .react-gray-select__placeholder {

                @include mixins.breakpoint() {
                    // color: $dark_gray;
										opacity: 0.5;
                }

            }

            .react-gray-select__input {

                @include mixins.breakpoint() {

                    position: absolute;

                    transform-origin: center center;

                    top: 0;

                }

            }

        }

        .react-gray-select__indicators {

            .react-gray-select__indicator-separator {

                @include mixins.breakpoint() {

                    display: none;

                }

            }

            .react-gray-select__dropdown-indicator {

                &::after {

                    @include mixins.breakpoint() {

                        border: none;

                        content: 'expand_more';
                        font-family: 'Material Icons Outlined';

                        pointer-events: none;

                        font-size: 18px;
                        line-height: 24px;

                        text-align: center;

                        color: variables.$black;

                        width: 24px;
                        height: 24px;

                        display: block;

                        margin: 0;

                        position: absolute;

                        top: 8px;
                        right: 8px;

                    }

                }

                svg {

                    @include mixins.breakpoint() {
                        display: none;
                    }

                }

            }

        }

    }

	&.async {
		.react-gray-select__dropdown-indicator {
			display: none!important;
		}
		.loadingIndicator {
			right: 0;
			margin-right: 0;
			padding-right: 0;
		}
	}

    .react-gray-select__menu {

        @include mixins.breakpoint() {

            z-index: 99;
            border-radius: 2px;

            @include mixins.box-shadow(0 0 0 1px rgba(0,0,0,0.10));

        }

        .react-gray-select__menu-list {

            .react-gray-select__option {

                @include mixins.breakpoint() {

                    cursor: pointer;

                    color: variables.$black;
                    background-color: variables.$white;

                }

                &.react-gray-select__option--is-focused {
                    @include mixins.breakpoint() {
                        background-color: variables.$light_gray;
                    }
                }

            }

        }

    }

    &.is-error {
        .react-gray-select__control {
            @include mixins.breakpoint() {
                border-color: variables.$cardinal;
            }
        }
    }

}
