@use "mixins";
@use "variables";

h2 {

	.is-error {
		color: variables.$cardinal;
	}
    &.page__title {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 32px;
            line-height: 48px;
            font-weight: variables.$regular;
			text-transform: capitalize;

            margin: 0 0 16px 0;

        }

    }

}

h4 {

    &.section_title {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 16px;
            line-height: 40px;
            font-weight: variables.$bold;

        }

    }

}

strong {

    @include mixins.breakpoint() {
        font-weight: variables.$bold;
    }
	&.medium {
		font-weight: variables.$medium;
	}

}

p {

    @include mixins.breakpoint() {
        font-size: 16px;
        line-height: 24px;
    }
    &:last-child {

        @include mixins.breakpoint() {
            margin: 0;
        }

    }

		&.link {
			@include mixins.breakpoint() {
				font-size: 14px;
				line-height: 20px;
				text-align: left;
			}
		}

    &.align-center {
        @include mixins.breakpoint() {
            text-align: center;
        }
    }

    &.large {
        @include mixins.breakpoint() {

            font-size: 18px;
            line-height: 24px;

            margin: 0 0 24px;
        }
    }
	&.medium {
		@include mixins.breakpoint() {

			font-size: 24px!important;
			line-height: 24px;

			margin: 0 0 24px;
		}
	}

    i {

        @include mixins.breakpoint() {

            font-style: italic;

        }

    }

    &.gray {

        @include mixins.breakpoint() {
            color: variables.$dark_gray;
        }

    }

		&.pn_blue {
				@include mixins.breakpoint() {
						color: variables.$bg_blue;
				}
		}

		&.pn_red {
				@include mixins.breakpoint() {
						color: variables.$amaranth_red;
				}
		}

		&.pn_small {
				@include mixins.breakpoint() {
						font-weight: variables.$medium;
				}
		}

		&.pn_bold {
				@include mixins.breakpoint() {
						font-size: 1.1rem;
				}
		}
}

a {

    &.mailto_lnk {

        @include mixins.breakpoint() {

            color: variables.$black;
            font-size: 16px;
            line-height: 24px;
            font-weight: variables.$bold;

            @include mixins.transition(all 300ms ease-in-out 0ms);

        }

        &:hover {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

            }

        }

    }

}

.label24 {
    @include mixins.breakpoint() {
        line-height: 24px;
    }
}

h2 {
    &.section__title {
        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 18px;
            line-height: 24px;
            font-weight: variables.$medium;

            margin: 0;

        }
        &.large {
            @include mixins.breakpoint() {
                font-size: 24px;
                line-height: 32px;
                margin: 0 0 16px;
            }
        }
    }
}

.revision_details {
	h3 {
		font-size: 22px;
	}
	p {
		font-size: 18px;
	}
}
