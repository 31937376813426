@use "../utils/mixins";
@use "../utils/variables";

section {

    &.default {

        @include mixins.breakpoint() {

            padding: 16px;

            background-color: variables.$white;
            border: solid 1px variables.$light_gray;

            display: flex;
            flex-direction: column;

        }

    }

}