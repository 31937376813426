@use "../utils/mixins";

//  LOGO
.usga__logo {

    @include mixins.breakpoint() {

        width: 110px;
        height: 30px;

        display: block;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: contain;
		font-size:0px;

        background-image: url('../img/usga_logo.svg');

        margin-bottom: 8px;

    }

    &.push-right {

        @include mixins.breakpoint() {
            margin-left: auto;
        }

    }

}
