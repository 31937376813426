@use "../utils/mixins";
@use "../utils/variables";

.react-datepicker-wrapper {

    .react-datepicker__input-container {

        @include mixins.breakpoint() {
            width: 100%;
        }

        input {

            @include mixins.breakpoint() {

                position: relative;
                padding-right: 48px;

            }

        }

        &::after {

            @include mixins.breakpoint() {

                content: 'calendar_today';
                display: block;

                font-family: 'Material Icons Outlined';

                font-size: 16px;

                position: absolute;
                top: 12px;
                right: 12px;

            }

        }

    }

}

.react-datepicker-popper {

    .react-datepicker {

        @include mixins.breakpoint() {

            font-family: 'National',sans-serif;

            border-radius: 0;
            border: none;

            @include mixins.box-shadow(0 5px 25px 0 rgba(0, 0, 0, 0.25));

        }

        .react-datepicker__triangle {

            &::before {

                @include mixins.breakpoint() {
                    border: none;
                }

            }

        }

        .react-datepicker__navigation {

            @include mixins.breakpoint() {

                width: 24px;
                height: 24px;
				top: 10px!important;
                border: none;
                text-indent: 0;

            }

            &.react-datepicker__navigation--previous {

                &::before {

                    @include mixins.breakpoint() {

                        display: block;

                        color: variables.$black;
						left: 10px;
                        font-size: 18px;
                        line-height: 24px;

                        font-family: 'Material Icons Outlined';
                        content: 'chevron_left';

                    }

                }
				span {display:none;}

            }

            &.react-datepicker__navigation--next {

                &::before {

                    @include mixins.breakpoint() {

                        display: block;

                        color: variables.$black;
						right: 10px;
                        font-size: 18px;
                        line-height: 24px;

                        font-family: 'Material Icons Outlined';
                        content: 'chevron_right';

                    }

                }
				span {display:none;}

            }

        }

        .react-datepicker__month-container {

            .react-datepicker__header {

                @include mixins.breakpoint() {

                    padding: 10px 0 0 0;

                    border-bottom: solid 1px variables.$light_gray;

                    border-radius: 0;

                }

                .react-datepicker__current-month {

                    @include mixins.breakpoint() {

                        font-size: 16px;
                        line-height: 32px;
                        font-weight: variables.$bold;

                    }

                    &.react-datepicker__current-month--hasYearDropdown {
                        @include mixins.breakpoint() {
                            display: none;
                        }
                    }

                    &.react-datepicker__current-month--hasMonthDropdown {
                        @include mixins.breakpoint() {
                            display: none;
                        }
                    }

                }

                .react-datepicker__header__dropdown {

                    .react-datepicker__month-dropdown-container {
                        @include mixins.breakpoint() {
                            margin: 0 8px 0 0;
                        }
                    }

                    .react-datepicker__month-dropdown-container,
                    .react-datepicker__year-dropdown-container {

                        @include mixins.breakpoint() {
                            position: relative;
                        }

                        select {

                            @include mixins.breakpoint() {

                                -webkit-appearance: none;
                                appearance: none;

                                border: none;

                                font-family: 'National',sans-serif;

                                font-size: 14px;
                                line-height: 16px;

                                padding: 4px 24px 4px 8px;

                                background-color: variables.$white;

                            }

                        }

                        &::after {

                            @include mixins.breakpoint() {

                                display: block;

                                color: variables.$black;

                                font-size: 18px;
                                line-height: 24px;

                                font-family: 'Material Icons Outlined';
                                content: 'expand_more';

                                position: absolute;

                                right: 4px;
                                top: 0;

                                pointer-events: none;

                            }

                        }

                    }

                }

                .react-datepicker__day-names {

                    .react-datepicker__day-name {

                        @include mixins.breakpoint() {

                            font-size: 14px;
                            line-height: 24px;

                            width: 24px;
                            height: 24px;

                            margin: 4px;

                        }

                    }

                }

            }

            .react-datepicker__month {

                @include mixins.breakpoint() {

                    margin: 0;
                    padding: 8px;

                }

                .react-datepicker__week {

                    .react-datepicker__day {

                        @include mixins.breakpoint() {

                            font-size: 14px;
                            line-height: 24px;

                            width: 24px;
                            height: 24px;

                            margin: 4px;

                        }

                        &.react-datepicker__day--weekend {
                            @include mixins.breakpoint() {
                                opacity: 1;
								background-color: rgba(0,0,0,0.05)
                            }
                        }

                        &.react-datepicker__day--disabled {
                            @include mixins.breakpoint() {
                                color: variables.$dark_gray;
                            }
                        }

                        &:hover {
                            @include mixins.breakpoint() {
                                opacity: 1;
                            }
                        }

                    }

                }

            }

        }

    }

}

.react-datepicker__day--selected {
	background-color: #1d5d90 !important;
}
