@use "../utils/mixins";
@use "../utils/variables";

.status-state {

    @include mixins.breakpoint() {

        font-size: 16px;
        line-height: 100%;
        font-weight: variables.$medium;

        text-align: center;

    }

    &.active {
        @include mixins.breakpoint() {
            color: variables.$light_green;
        }
    }

    &.inactive {
        @include mixins.breakpoint() {
            color: variables.$cardinal;
        }
    }

    &.info {
        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            align-items: center;

        }
        .material-icons {
            @include mixins.breakpoint() {

                font-size: 14px;

                margin: 0 8px 0 0;
            }
        }
    }

}