@use "../utils/mixins";
@use "../utils/variables";

header {

    @include mixins.breakpoint() {

        background-color: variables.$white;

        flex: 0 0;

    }

    .header__container {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 16px 0;

        }

        .logo__container {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;
				margin-left: 8px;

            }

            .usga__logo {

                @include mixins.breakpoint() {

                    margin: 0 8px 0 0;

                }

            }

            span {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 16px;
                    line-height: 15px;
                    font-weight: variables.$bold;

                    text-transform: uppercase;

                }

            }

        }

        .search__container {

            @include mixins.breakpoint() {

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-self: center;
				margin: auto;

			}

            //  SEARCH UPDATE

            //  REACT SELECT
            .react-select-container {

                @include mixins.breakpoint() {

                    flex: none;
                    width: 160px;

                }

                .react-select__control {
                    &.react-select__control--is-focused {
                        @include mixins.breakpoint() {
                            border-color: variables.$medium_gray;
                            border-right: none;
                        }
                    }
                }


                &:hover,
                &:focus {
                    .react-select__control {
                        @include mixins.breakpoint() {
                            border-color: variables.$medium_gray;
                            border-right: none;
                        }
                        &.react-select__control--is-focused {
                            @include mixins.breakpoint() {
                                border-color: variables.$medium_gray;
                                border-right: none;
                            }
                        }
                    }
                }

            }

            input[type="text"].search {

                @include mixins.breakpoint() {

                    flex: none;
                    width: 300px;

                    border-left: none;

                    margin: 0 0 0 -1px;

                    position: relative;
                    z-index: 5;

                }

                &:hover,
                &:focus {

                    @include mixins.breakpoint() {
                        border-color: variables.$medium_gray;
                    }

                }

            }

			.checkbox__container {
				@include mixins.breakpoint() {

					margin-left: 2%

				}
			}

        }

        .profile__container {

            @include mixins.breakpoint() {

                flex: none;

                width: 120px;

                margin: 0 0 0 32px;

            }

        }

    }

}


.main__alert {
	@include mixins.breakpoint() {
		cursor: pointer;
		width: 100%;
		padding: 8px 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: National;
		font-weight: bold;
		color: #fff;
		font-size: 16px;
    position: relative;
	}

  &.red {
    background: #CC0000;
  }

  &.orange {
    background: #FF9900;
  }

	.icon {
		@include mixins.breakpoint() {
			background: url("../img/alert-icon.svg") no-repeat center;
			width: 40px;
			height: 30px;
		}
	}
	span {
		@include mixins.breakpoint() {
			padding-top: 4px;
			margin-left: 16px;
		}
	}
}
.hide_alert {
	@include mixins.breakpoint() {
		position: absolute;
		cursor: pointer;
		right: 32px;
		top: 16px;
		border: none;
		width: 16px;
		height: 16px;
		background: url("../img/alert-close.svg") no-repeat center;
	}
}

