@use "../utils/mixins";
@use "../utils/variables";

.home__modal-triggers {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    .home__modal-trigger {

        @include mixins.breakpoint() {

            flex: 0 0 0;

            margin: 0 16px 0 0;

            display: flex;
            flex-direction: row;
            align-items: center;

            height: 64px;
            padding: 0 16px;

            font-size: 24px;
            line-height: 64px;
            font-family: 'National',sans-serif;

            border: solid 1px variables.$black;
            background-color: variables.$white;

            border-radius: 8px;

            cursor: pointer;

        }

        &:hover {

            @include mixins.breakpoint() {

                background-color: variables.$light_gray;

            }

        }

        .material-icons-outlined {

            @include mixins.breakpoint() {

                color: variables.$blue;

                font-size: 32px;

                margin: 0 8px 0 0;

            }

        }

    }

}