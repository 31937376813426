@use "../utils/mixins";
@use "../utils/variables";

.form-section__title {

    @include mixins.breakpoint() {

        color: variables.$dark_midnight_blue;

        font-size: 24px;
        line-height: 24px;
        font-weight: variables.$medium;

        margin: 0 0 8px 0;

    }

}

.form-section__description {

    @include mixins.breakpoint() {

        color: variables.$dark_gray;

        font-size: 16px;
        line-height: 24px;

    }

}