@use "../utils/mixins";
@use "../utils/variables";

.upload__item {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    .upload__zone {

        @include mixins.breakpoint() {

            flex: none;
            width: 350px;

        }

    }

    .upload__details {

        @include mixins.breakpoint() {

            flex-grow: 1;

            margin: 0 16px;

        }

        .pn__details {

            @include mixins.breakpoint() {

                flex-grow: 1;
                margin: 0 10px;
            }
          }

        .upload__output {

            .upload__output__title {

                @include mixins.breakpoint() {

                    font-size: 16px;
                    line-height: 1;
                    font-weight: variables.$bold;

                    margin: 0 0 16px 0;

                }

            }

            .upload_item {
                @include mixins.breakpoint() {
                    margin: 0 0 16px 0p;
                }
                &:last-child {
                    @include mixins.breakpoint() {
                        margin: 0;
                    }
                }
                
            }

            figure {

                @include mixins.breakpoint() {
                    width: 82px;
                    height: 82px;

                    margin: 0 0 8px 0;
                }

                &:last-child {
                    @include mixins.breakpoint() {
                        margin: 0;
                    }
                }

                &.large {
                    @include mixins.breakpoint() {
                        width: 150px;
                        height: 62px;
                    }
                }

                img {

                    @include mixins.breakpoint() {

                        display: block;

                        width: 100%;
                        height: auto;

                        object-fit: contain;

                    }

                }

            }

        }

    }

    .upload__controls {

        @include mixins.breakpoint() {

            flex: none;
            width: 80px;

        }

        &.list {

            @include mixins.breakpoint() {

                width: auto;
                max-height: 40px;

                display: flex;
                flex-direction: row;
                align-items: center;

            }

            .btn {

                &:not(:last-child) {
                    @include mixins.breakpoint() {
                        margin: 0 16px 0 0;
                    }
                }

            }

        }

    }

}

.upload__title {

    @include mixins.breakpoint() {

        font-size: 14px;
        line-height: 1;
        font-weight: variables.$bold;

        margin: 0 0 16px 0;

    }

}

.upload__requirements {

    @include mixins.breakpoint() {

        color: variables.$dark_gray;

        margin: 0 0 16px 0;

    }

    li {

        @include mixins.breakpoint() {

            font-size: 16px;
            line-height: 24px;

        }

    }

}

.upload__label,
.image__name {

    @include mixins.breakpoint() {
        color: variables.$black;
        font-size: 14px;
        line-height: 20px;
        font-weight: variables.$bold;
    }

    &:not(:first-of-type) {

        @include mixins.breakpoint() {
            margin: 16px 0 0 0;
        }

    }

}

.image__name {
    @include mixins.breakpoint() {
        margin: 0 0 16px 0;
    }
}

.upload__url {

    @include mixins.breakpoint() {

        color: variables.$dark_midnight_blue;
        font-size: 14px;
        line-height: 20px;
        display: inline-block;

        word-break: break-all;
    }
    
}

.upload__info {

    @include mixins.breakpoint() {

        color: variables.$black;

        font-size: 14px;
        line-height: 20px;
        word-break: break-all;

    }

}
