@use "../utils/mixins";
@use "../utils/variables";

.material-icons-outlined {

    @include mixins.breakpoint() {

        @include mixins.user-select(none);

    }

}

//  CUSTOM ICONS

//  PIN
.pin {

    @include mixins.breakpoint() {

        width: 40px;
        height: 40px;
    
        border-radius: 50% 50% 50% 0;
    
        @include mixins.transform(perspective(40px) rotateX(0deg) rotateZ(-45deg));
    
        transform-origin: center center;
    
        @include mixins.box-shadow(-1px 1px 4px rgba(variables.$black,0.5));    

        align-self: flex-end;
        margin: 0 0 0 auto;

    }

    &.pull-up {

        @include mixins.breakpoint() {

            position: relative;
            top: -10px;

        }

    }

    &.pull-up-more {

        @include mixins.breakpoint() {

            position: relative;
            top: -20px;

        }

    }

    &.small {

        @include mixins.breakpoint() {

            width: 30px;
            height: 30px;

        }

    }

    &.purple {
        @include mixins.breakpoint() {
            background-color: variables.$purple;
        }
    }

    &.green {
        @include mixins.breakpoint() {
            background-color: variables.$light_green;
        }
    }

    &.blue {
        @include mixins.breakpoint() {
            background-color: variables.$light_blue;
        }
    }

}  

//  LABELED PIN
.labeled-pin {

    @include mixins.breakpoint() {

        font-size: 16px;
        line-height: 40px;

        white-space: nowrap;

    }

    &.small {
        @include mixins.breakpoint() {
            line-height: 21px;
        }
    }

    &::before {

        @include mixins.breakpoint() {

            content: '';
            display: inline-block;

            vertical-align: center;

            width: 20px;
            height: 20px;
        
            border-radius: 50% 50% 50% 0;
        
            @include mixins.transform(perspective(40px) rotateX(0deg) rotateZ(-45deg));
        
            transform-origin: center center;
        
            @include mixins.box-shadow(-1px 1px 4px rgba(variables.$black,0.5));    
    
            margin: 0 8px -2px 0;

        }

    }


    &.margin-left {
        @include mixins.breakpoint() {
            margin-left: 8px;
        }
        &::before {
            @include mixins.breakpoint() {
                margin: 0 0 -2px 0;
            }
        }
    }

    &.purple {
        &::before {
            @include mixins.breakpoint() {
                background-color: variables.$purple;
            }
        }
    }

    &.green {
        &::before {
            @include mixins.breakpoint() {
                background-color: variables.$light_green;
            }
        }
    }

    &.blue {
        &::before {
            @include mixins.breakpoint() {
                background-color: variables.$light_blue;
            }
        }
    }

}

.fa-flag-image-text {
  white-space: nowrap;
  margin: auto;
}

.fa-flag{
  @include mixins.breakpoint() {

      line-height: 40px;

      margin: auto;

  }
  
  &.small {
      @include mixins.breakpoint() {
          font-size: 22px;
      }
  }
  &.big{
    @include mixins.breakpoint() {
        font-size: 24px;
    }
  }
  &.blue {

      @include mixins.breakpoint() {
          color: variables.$light_blue;
      }

  }
  &.line-height-18{
    line-height: 18px;
  }
  &.absolute {
    position: absolute;
  }
  &.margin-left {
      @include mixins.breakpoint() {
          margin-left: 10px;
      }
      &::before {
          @include mixins.breakpoint() {
              margin: 0;
          }
      }
  }
  &.margin-right {
      @include mixins.breakpoint() {
          margin-right: 8px;
      }
      &::before {
          @include mixins.breakpoint() {
              margin: 0 0 -2px 0;
          }
      }
  }
}

//  MATERIAL ICONS
.material-icons-outlined,
.material-icons {

    &.yellow{
      @include mixins.breakpoint() {
        color: #FF9900;
      }
    }
    
    &.green{
      @include mixins.breakpoint() {
        color: green;
      }
    }

    &.red {

        @include mixins.breakpoint() {
            color: variables.$cardinal;
        }

    }

    &.blue {

        @include mixins.breakpoint() {
            color: variables.$blue;
        }

    }

    &.white {
        @include mixins.breakpoint() {
            color: variables.$white;
        }
    }

    &.small {
        @include mixins.breakpoint() {
            font-size: 16px;
        }
    }

    &.v-mid {
        @include mixins.breakpoint() {
            vertical-align: middle;
        }
    }

    &.m-left {
        @include mixins.breakpoint() {
            margin: 0 0 0 8px;
        }
    }

}
