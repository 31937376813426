@use "../utils/mixins";
@use "../utils/variables";

// REGULAR CHECKBOX

input[type="checkbox"] {

    @include mixins.breakpoint() {

        display: none;

    }

    &.small {
        & + label {
            @include mixins.breakpoint() {
                line-height: 24px;
            }
        }
    }

    & + label {

        @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;

            font-size: 16px;
            line-height: 40px;

            margin: 0;

            cursor: pointer;

            display: flex;
            flex-direction: row;

            justify-content: flex-start;
            align-items: center;

            @include mixins.user-select(none);

        }

        &::before {

            @include mixins.breakpoint() {

                content: '';
                display: block;

                width: 16px;
                height: 16px;

                background-color: variables.$white;
                border: solid 1px variables.$dark_gray;
                border-radius: 2px;

                margin: 0 8px 0 0;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;

                background-size: 12px 12px;

                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+);

                will-change: background-color;

                @include mixins.transition(all 300ms ease-in-out 0ms);

            }

        }

        &:empty {

            @include mixins.breakpoint() {

                width: 18px;
                height: 18px;

            }

            &::before {

                @include mixins.breakpoint() {

                    margin: 0;

                }

            }

        }

    }

	&:disabled {
		& + label {

			pointer-events: none;

			color: variables.$new_gray;

			&::before {

				@include mixins.breakpoint() {
					background-image: none;
					background-color: variables.$new_gray!important;

				}

			}

		}
	}

    &:checked {

        & + label {

            &::before {

                @include mixins.breakpoint() {

					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+)!important;
                    background-color: variables.$dark_midnight_blue;
                    border-color: variables.$dark_midnight_blue;

                }

            }

        }

    }


}

//  PICKY
.option {

    input[type="checkbox"] {

        @include mixins.breakpoint() {
            display: inline-block;
        }

    }

}

// html editor
.fr-checkbox-line {

    input[type="checkbox"] {
        display: inline-block;
    }

    label{
        display: inline-block;
    }

}

.center-checkbox {
  flex-direction: row!important;
  align-items: end;
}
