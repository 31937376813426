@use "../utils/mixins";
@use "../utils/variables";

.inline__tabs {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    li {

        &:not(:first-child) {

            @include mixins.breakpoint() {
                margin: 0 0 0 -1px;
            }

        }

        a {

            @include mixins.breakpoint() {

                color: variables.$medium_gray;

                font-size: 14px;
                line-height: 38px;
                font-weight: variables.$bold;

                min-width: 100px;
                display: block;

                text-align: center;

                text-decoration: none;

                border: solid 1px variables.$light_gray;

            }

            &.is-active {

                @include mixins.breakpoint() {

                    color: variables.$white;
                    background-color: variables.$dark_midnight_blue;

                }

            }

        }

    }

}