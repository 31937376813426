@use "../utils/mixins";

.alerts_list {

    @include mixins.breakpoint() {

        position:fixed;
		display:flex;
		flex-direction: column;
		pointer-events: none;
		top: 0;
		right: 0;
		padding: 16px;
		z-index:999;
    }

    li {

        @include mixins.breakpoint() {
            margin: 0 0 16px 0;
			border: 1px solid;
			border-radius:3px;
			height: auto;
			width: 380px;
			padding: 18px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			list-style: none;
			animation:fade_in 0.4s ease;
			transition: 0.4s ease;
			pointer-events: initial;
        }

		@keyframes fade_in {
			0% {
				opacity: 0;
				transform: translateY(-20px);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&:before{
			content: '';
			width: 32px;
			margin-right:10px;
			height: 24px;
			margin-top:-4px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}
		&.info{
			background: rgb(205, 232, 243);
			color: rgb(67,121,157);
			border-color: rgb(67,121,157);
			&:before{
				background-image: url('../img/outline-info-24px.svg');
			}
		}
		&.warning{
			background: rgb(248,243,215);
			color: rgb(140,111,64);
			border-color: rgb(140,111,64);
			&:before{
				background-image: url('../img/outline-report_problem-24px.svg');
			}
		}
		&.error{
			background: rgb(234,200,197);
			color: rgb(166,46,47);
			border-color: rgb(166,46,47);
			&:before{
				background-image: url('../img/outline-not_interested-24px.svg');
			}
		}
		&.success{
			background: rgb(222,241,215);
			color: rgb(89,108,82);
			border-color: rgb(89,108,82);
			&:before{
				background-image: url('../img/outline-done-24px.svg');
			}
		}
		.alert-label{
			font-size:16px;
			font-weight: bold;
			margin-right: 8px;
			text-transform: capitalize;
		}
		.alert-message{
			width:100%;
			font-size:16px;
		}
		&:after{
			content: '';
			opacity: 0.8;
			width: 24px;
			cursor: pointer;
			height: 24px;
			margin-top:-4px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			background-image: url('../img/outline-close-24px.svg');
		}

        &:last-child {
            @include mixins.breakpoint() {
                margin: 0;
            }
        }

    }

}

