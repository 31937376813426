@use "../utils/mixins";
@use "../utils/variables";

.react-tooltip__trigger {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;
        justify-content: center;

    }

}

.__react_component_tooltip {

    @include mixins.breakpoint() {

        border-radius: 0;

    }

    &.small {

        @include mixins.breakpoint() {

            width: 160px;

        }

    }

    &.type-light {

        @include mixins.breakpoint() {

            background-color: variables.$light_gray;
            opacity: 1;

        }


        &.place-top {

            &::after {

                @include mixins.breakpoint() {

                    content: '';
                    display: block;

                    width: 0;
                    height: 0;

                    border-style: solid;
                    border-width: 8px 8px 0 8px;
                    border-color: variables.$light_gray transparent transparent transparent;

                }

            }

        }

    }

	&.type-white {

		@include mixins.breakpoint() {

			background-color: variables.$white;
			opacity: 1;
			width: 520px!important;

		}


		&.place-top {

			&::after {

				@include mixins.breakpoint() {

					content: '';
					display: block;

					width: 0;
					height: 0;

					border-style: solid;
					border-width: 8px 8px 0 8px;
					border-color: variables.$white transparent transparent transparent;

				}

			}

		}

	}

    &.default-image__tooltip {
        @include mixins.breakpoint() {
            white-space: normal;
            font-weight: variables.$regular;
            border: 1px solid variables.$medium_gray;
            height: 30px;
            width: 480px!important;
            padding-top: 0px;
            padding-bottom: 45px;
            text-align: center;
        }
      }

    &.table-head__tooltip {
        @include mixins.breakpoint() {
            white-space: normal;
            font-weight: variables.$regular;
            width: 480px;
        }
        strong {
			@include mixins.breakpoint() {
				font-weight: variables.$bold;
			}
		}

      &.extra_strong {
        @include mixins.breakpoint() {
          font-weight: variables.$extra_bold;
        }
    }

    &.auto-width-tooltip {
      width: auto!important;
      max-width: 480px;
    }
  }

    &.auto-size-opaque_tooltip {
      @include mixins.breakpoint() {
        width: auto;
        max-width: 480px;
        opacity: 0.95 !important;
        transition: 0s;
        white-space: normal;
      }
    }
}
