@use "../utils/mixins";
@use "../utils/variables";

.auth {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        
        width: 100vw;
        height: 100vh;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        background-size: cover;

        background-image: url('../img/auth_bg.jpg');

    }

    .auth__container {

        @include mixins.breakpoint() {

            flex: none;

            width: 420px;

            background-color: variables.$white;

            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

        }

        .auth__head {

            @include mixins.breakpoint() {

                font-size: 24px;
                line-height: 48px;

                color: variables.$white;
                background-color: variables.$dark_midnight_blue;

                text-align: center;

                margin: 0 0 40px 0;

            }

        }

        .auth__body {

            @include mixins.breakpoint() {

                padding: 0 40px 40px 40px;

            }

        }

    }

}
