@use "../utils/mixins";
@use "../utils/variables";

.simple__page {

    @include mixins.breakpoint() {

        padding: 100px 0;

        flex: 0;

    }

    p {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 16px;
            line-height: 24px;

            margin: 0 0 24px 0;

        }

        &:last-child {

            @include mixins.breakpoint() {

                margin: 0;

            }

        }

        &.text-align-center {

            @include mixins.breakpoint() {

                text-align: center;

            }

        }

    }

    strong {

        @include mixins.breakpoint() {

            font-weight: variables.$bold;

        }

    }

    a {

        @include mixins.breakpoint() {

            color: variables.$blue;
            border-bottom: solid 1px variables.$blue;


            text-decoration: none;

        }

        &:hover {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;
                border-color: variables.$dark_midnight_blue;

            }

        }

    }

	button {

		border: 0;
		background: none;
		padding: 0;
		cursor: pointer;
		color: variables.$blue;
		border-bottom: solid 1px variables.$blue;

		&:hover {

			@include mixins.breakpoint() {

				color: variables.$dark_midnight_blue;
				border-color: variables.$dark_midnight_blue;

			}

		}
	}

}
