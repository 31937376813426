@use "../utils/mixins";
@use "../utils/variables";

textarea {

	&:disabled{
		@include mixins.breakpoint() {

			background-color: rgba(variables.$light_gray,0.5);

		}
		&:hover,&:focus {
			border: solid 1px variables.$medium_gray;
		}
	}

	@include mixins.breakpoint() {

		resize: none;
		width: 100%;

		font-family: 'National', sans-serif;

		min-height: 80px;

		background-color: variables.$white;
		border: solid 1px variables.$medium_gray;

		font-size: 16px;
		line-height: 24px;

		padding: 7px 8px;

		display: block;

		height: 40px;

	}

	&.large {
		width:625px;
		height:150px;
		text-align: left;
		padding: 5px;
		margin-top: -1.5%;
	}

  &.medium {
    width:625px;
		height:60;
		text-align: left;
		padding: 5px;
		margin-top: -1.5%;
  }
	
	&.large-alert {
		height:150px;
		text-align: left;
		padding: 5px;
	}

	&.is-error {
		border: solid 1px variables.$amaranth_red;
		color: variables.$amaranth_red;
	}

	&:hover {
		@include mixins.breakpoint() {
			border-color: variables.$dark_gray;
		}
	}

	&:focus {

		@include mixins.breakpoint() {

			border: solid 1px variables.$black;

			outline: none;

		}

	}

}
