@use "../utils/mixins";
@use "../utils/variables";

.panel {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

        border: solid 1px variables.$medium_gray;

    }

    &:not(:only-of-type) {

        @include mixins.breakpoint() {
            margin: 0 0 16px;
        }

        &:last-child {
            @include mixins.breakpoint() {
                margin: 0;
            }
        }

    }

    .panel__head {

        @include mixins.breakpoint() {

            flex: none;

            height: 46px;

            font-size: 16px;
            line-height: 46px;
            font-weight: variables.$bold;

            padding: 0 16px;

            border-bottom: solid 1px variables.$medium_gray;

            color: variables.$black;
            background-color: variables.$light_gray;

            display: flex;
            flex-direction: row;
            align-items: center;
			white-space: nowrap;

        }

        .panel__end {

            @include mixins.breakpoint() {
                margin: 0 0 0 auto;
            }

			.pill {
				padding: 5px 10px;
				background: #fff;
        &.sub-component {
          display: inline;
        }
				&.green {
					color: variables.$light_green;
				}
				&.blue {
					color: variables.$another_blue;
				}
				&.white {
					color: variables.$dark_gray;
				}
			}

        }

    }

    .panel__body {

        @include mixins.breakpoint() {

            padding: 16px;

        }

    }

}
