.data-set__container.left {
  .data-label {
    text-align: left !important;
    width: 200px;
  }
  .data-value {
    width: 800px;
  }
  .data-set {
    span {
      // flex: none !important; Fix for overflowing text in Salutation
    }
  }
}


