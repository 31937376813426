@use "../utils/mixins";
@use "../utils/variables";

//  PULL DATA
.pull-data__golfer-name,
.pull-data__golfer-handicap-index {
    @include mixins.breakpoint() {
        line-height: 20px;
    }
    .cardinal {
        @include mixins.breakpoint() {
            color: variables.$cardinal;
        }
    }
}

//  IMAGE PREVIEW
.image-preview__url {
    @include mixins.breakpoint() {

        color: variables.$dark_gray;
        word-break: break-all;
        
    }
}

//  PRE INPUT
.pre-input__container {
    @include mixins.breakpoint() {
        display: flex;
        flex-direction: row;
    }
}
.pre-input {
    @include mixins.breakpoint() {

        line-height: 40px;
        margin: 0 8px 0 0;

    }
}