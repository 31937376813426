@use "../utils/mixins";
@use "../utils/variables";

.dropdown {

    @include mixins.breakpoint() {

        display: inline-block;

        position: relative;

    }

    .dropdown__trigger  {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            align-items: center;

            @include mixins.user-select(none);

        }

        .material-icons-outlined {

        }

    }

    .dropdown__content  {

        @include mixins.breakpoint() {

            position: absolute;
            right: -16px;

            display: none;

            background-color: variables.$white;

            padding: 8px 0;

            border-radius: 4px;

            min-width: 160px;

            @include mixins.transform(translate3d(0,16px,0));
            @include mixins.box-shadow(0 5px 25px 0 rgba(variables.$black,0.25));

        }

        &::before {

            @include mixins.breakpoint() {

                content: '';
                display: block;

                width: 0;
                height: 0;

                border-style: solid;
                border-width: 0 8px 8px 8px;
                border-color: transparent transparent variables.$white transparent;

                position: absolute;
                top: -8px;
                right: 16px;

            }

        }

        ul {

            li {

                .dropdown__option {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                        font-size: 16px;
                        line-height: 48px;

                        text-decoration: none;

                        display: block;

                        padding: 0 16px;

                    }

                    &:hover {

                        @include mixins.breakpoint() {

                            background-color: variables.$glitter;

                        }

                    }

                }

            }

        }

    }

    &.dropdown--active {

        .dropdown__content {

            @include mixins.breakpoint() {

                display: block;

            }

        }

    }

}