@use "../utils/mixins";
@use "../utils/variables";

.vertical-tabs__container {

    .vertical-tabs {

        @include mixins.breakpoint() {

            flex: none;
            width: 180px;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;

        }

        li {

            @include mixins.breakpoint() {

                margin: 0 0 16px 0;

            }

            &:last-child {

                @include mixins.breakpoint() {

                    margin: 0;

                }

            }

            a {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    height: 48px;

                    font-size: 16px;
                    line-height: 16px;
                    font-weight: variables.$bold;

                    text-decoration: none;
                    text-align: center;

                    @include mixins.box-shadow(inset 0 0 0 1px variables.$light_gray);

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                }

                &.is-active {

                    @include mixins.breakpoint() {

                        color: variables.$white;
                        background-color: variables.$dark_midnight_blue;
                        @include mixins.box-shadow(inset 0 0 0 1px variables.$dark_midnight_blue);

                        border-top-right-radius: 10px;
                        border-bottom-left-radius: 10px;

                    }

                }

            }

        }

    }

    .vertical-tabs__panel {

        @include mixins.breakpoint() {
            justify-content: flex-start;
        }

    }

}