@use "../utils/mixins";
@use "../utils/variables";

input[type="text"],
input[type="password"],
input[type="mail"],
input[type="number"] {

    &.is-error {

        @include mixins.breakpoint() {

            color: variables.$cardinal;
            border-color: variables.$cardinal;

            @include mixins.animation(error 1s 1 forwards);

        }
		&:disabled{
			@include mixins.animation(errorDisabled 1s 1 forwards);
		}

    }

    &.is-ok {

        @include mixins.breakpoint() {

            border-color: variables.$light_green;

        }

    }

}

//  VALidATION MESSAGES
.validation__message {

    @include mixins.breakpoint() {

        font-size: 14px;
        line-height: 16px;

        display: block;

        padding: 4px 0;

    }

    &.is-error {

        @include mixins.breakpoint() {

            color: variables.$cardinal;

        }

    }

}
