@use "../utils/mixins";

.address__card {

    .address__head {

        @include mixins.breakpoint() {
            
            margin: 0 0 32px 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

        }

    }

    .address__body {

        @include mixins.breakpoint() {
            
            margin: 0 0 32px 0;

        }

        .address {

            @include mixins.breakpoint() {
            
                font-size: 18px;
                line-height: 1.5;

                margin: 0 0 32px 0;
    
            }

        }

    }

    .address__maps {

        @include mixins.breakpoint() {

            width: 100%;
            height: 200px;

        }

    }

}