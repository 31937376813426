@use "../utils/mixins";

.f-row {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

        margin: 0 0 32px 0;

    }

    &:last-child {

        @include mixins.breakpoint() {
            margin: 0;            
        }

    }

    .f-col {

        @include mixins.breakpoint() {

            width: 100%;
        
        }

        &.justify-center {

            @include mixins.breakpoint() {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

        }

        &.auto {

            @include mixins.breakpoint() {
                flex: 1;
            }

            &.text-align-right {
                @include mixins.breakpoint() {
                    text-align: right;
                }
            }

        }

    }

}

.f-paragraph {

    @include mixins.breakpoint() {

        font-size: 16px;
        line-height: 24px;

    }

    &:only-of-type {

        @include mixins.breakpoint() {

            margin: 0;

        }

    }

    &:not(:only-of-type) {

        @include mixins.breakpoint() {

            margin: 0 0 24px 0;

        }

    }

}