@use "mixins";

.container__loader{
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.image__loader {

		@include mixins.breakpoint() {

			width: 120px;
			height: 40px;

			display: inline-block;

			background-repeat: no-repeat;
			background-attachment: scroll;
			background-position: center center;
			background-size: initial;

			background-image: url('../img/loader.svg');
		}

	}
}
