.lds-ring {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  margin-top: auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 19px 0px 0px 19;
  border: 2px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}

.lds-ring.white div{
  border: 2px solid white !important;
  border-color: white transparent transparent transparent !important;
}

.mr-5{
  margin-right: 5px;
}

.aged-out-spinner-position {
  position: absolute;
  padding-top: 6px;
  margin-right: 45px;
}

.lds-ring.test {
  width: unset;
  height: unset;
  margin-bottom: unset;
  margin-top: unset;
}

.lds-ring.test div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  margin: 23px 0px 0px 254px;
  border: 2px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
