/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "National Book";
  src: url("/src/styles/fonts/National-Book.eot");
  src: url("/src/styles/fonts/National-Book.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Book.woff2") format("woff2"), url("/src/styles/fonts/National-Book.woff") format("woff"), url("/src/styles/fonts/National-Book.ttf") format("truetype"), url("/src/styles/fonts/National-Book.svg#National-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Light.eot");
  src: url("/src/styles/fonts/National-Light.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Light.woff2") format("woff2"), url("/src/styles/fonts/National-Light.woff") format("woff"), url("/src/styles/fonts/National-Light.ttf") format("truetype"), url("/src/styles/fonts/National-Light.svg#National-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "National Regular";
  src: url("/src/styles/fonts/National-RegularItalic.eot");
  src: url("/src/styles/fonts/National-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-RegularItalic.woff2") format("woff2"), url("/src/styles/fonts/National-RegularItalic.woff") format("woff"), url("/src/styles/fonts/National-RegularItalic.ttf") format("truetype"), url("/src/styles/fonts/National-RegularItalic.svg#National-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "National Book";
  src: url("/src/styles/fonts/National-BookItalic.eot");
  src: url("/src/styles/fonts/National-BookItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-BookItalic.woff2") format("woff2"), url("/src/styles/fonts/National-BookItalic.woff") format("woff"), url("/src/styles/fonts/National-BookItalic.ttf") format("truetype"), url("/src/styles/fonts/National-BookItalic.svg#National-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-BoldItalic.eot");
  src: url("/src/styles/fonts/National-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-BoldItalic.woff2") format("woff2"), url("/src/styles/fonts/National-BoldItalic.woff") format("woff"), url("/src/styles/fonts/National-BoldItalic.ttf") format("truetype"), url("/src/styles/fonts/National-BoldItalic.svg#National-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-SemiboldItalic.eot");
  src: url("/src/styles/fonts/National-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-SemiboldItalic.woff2") format("woff2"), url("/src/styles/fonts/National-SemiboldItalic.woff") format("woff"), url("/src/styles/fonts/National-SemiboldItalic.ttf") format("truetype"), url("/src/styles/fonts/National-SemiboldItalic.svg#National-SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-ThinItalic.eot");
  src: url("/src/styles/fonts/National-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-ThinItalic.woff2") format("woff2"), url("/src/styles/fonts/National-ThinItalic.woff") format("woff"), url("/src/styles/fonts/National-ThinItalic.ttf") format("truetype"), url("/src/styles/fonts/National-ThinItalic.svg#National-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Black.eot");
  src: url("/src/styles/fonts/National-Black.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Black.woff2") format("woff2"), url("/src/styles/fonts/National-Black.woff") format("woff"), url("/src/styles/fonts/National-Black.ttf") format("truetype"), url("/src/styles/fonts/National-Black.svg#National-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-BlackItalic.eot");
  src: url("/src/styles/fonts/National-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-BlackItalic.woff2") format("woff2"), url("/src/styles/fonts/National-BlackItalic.woff") format("woff"), url("/src/styles/fonts/National-BlackItalic.ttf") format("truetype"), url("/src/styles/fonts/National-BlackItalic.svg#National-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Bold.eot");
  src: url("/src/styles/fonts/National-Bold.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Bold.woff2") format("woff2"), url("/src/styles/fonts/National-Bold.woff") format("woff"), url("/src/styles/fonts/National-Bold.ttf") format("truetype"), url("/src/styles/fonts/National-Bold.svg#National-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-MediumItalic.eot");
  src: url("/src/styles/fonts/National-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-MediumItalic.woff2") format("woff2"), url("/src/styles/fonts/National-MediumItalic.woff") format("woff"), url("/src/styles/fonts/National-MediumItalic.ttf") format("truetype"), url("/src/styles/fonts/National-MediumItalic.svg#National-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Regular.eot");
  src: url("/src/styles/fonts/National-Regular.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Regular.woff2") format("woff2"), url("/src/styles/fonts/National-Regular.woff") format("woff"), url("/src/styles/fonts/National-Regular.ttf") format("truetype"), url("/src/styles/fonts/National-Regular.svg#National-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-LightItalic.eot");
  src: url("/src/styles/fonts/National-LightItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-LightItalic.woff2") format("woff2"), url("/src/styles/fonts/National-LightItalic.woff") format("woff"), url("/src/styles/fonts/National-LightItalic.ttf") format("truetype"), url("/src/styles/fonts/National-LightItalic.svg#National-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Medium.eot");
  src: url("/src/styles/fonts/National-Medium.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Medium.woff2") format("woff2"), url("/src/styles/fonts/National-Medium.woff") format("woff"), url("/src/styles/fonts/National-Medium.ttf") format("truetype"), url("/src/styles/fonts/National-Medium.svg#National-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Semibold.eot");
  src: url("/src/styles/fonts/National-Semibold.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Semibold.woff2") format("woff2"), url("/src/styles/fonts/National-Semibold.woff") format("woff"), url("/src/styles/fonts/National-Semibold.ttf") format("truetype"), url("/src/styles/fonts/National-Semibold.svg#National-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-ExtraboldItalic.eot");
  src: url("/src/styles/fonts/National-ExtraboldItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-ExtraboldItalic.woff2") format("woff2"), url("/src/styles/fonts/National-ExtraboldItalic.woff") format("woff"), url("/src/styles/fonts/National-ExtraboldItalic.ttf") format("truetype"), url("/src/styles/fonts/National-ExtraboldItalic.svg#National-ExtraboldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Thin.eot");
  src: url("/src/styles/fonts/National-Thin.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Thin.woff2") format("woff2"), url("/src/styles/fonts/National-Thin.woff") format("woff"), url("/src/styles/fonts/National-Thin.ttf") format("truetype"), url("/src/styles/fonts/National-Thin.svg#National-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Extrabold.eot");
  src: url("/src/styles/fonts/National-Extrabold.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Extrabold.woff2") format("woff2"), url("/src/styles/fonts/National-Extrabold.woff") format("woff"), url("/src/styles/fonts/National-Extrabold.ttf") format("truetype"), url("/src/styles/fonts/National-Extrabold.svg#National-Extrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}
@media screen {
  h2 {
    color: #00365f;
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 32px 0;
  }
}

body > *,
input {
  font-family: "National", sans-serif;
  font-feature-settings: "tnum";
}

html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #EFF0F2;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen {
  html.prevent-scroll {
    overflow: hidden;
  }
}

@media screen {
  main {
    padding: 16px 0;
  }
}

@media screen {
  .separator {
    width: 100%;
    height: 0;
    margin: 16px 0;
  }
}
@media screen {
  .separator.dark {
    border: solid 1px #cccccc;
  }
}
@media screen {
  .separator.light {
    border: solid 1px #efefef;
  }
}

span.gray {
  color: #999 !important;
  font-weight: 400;
}
span.bold {
  font-weight: 700;
}
span.italic {
  font-style: italic;
}
span.underline {
  text-decoration: underline;
}
span.red {
  color: #da1a31;
}

.rdw-editor-main {
  height: 25vh;
  border: 1px solid #f1f1f1;
  overflow: auto;
  padding: 0px 10px;
  box-sizing: border-box;
}

@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-o-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@-moz-keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@-o-keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@-webkit-keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@-moz-keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@-o-keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@media screen {
  .row {
    display: flex;
    flex-direction: row;
    margin: 0 -8px 16px -8px;
  }
}
.row.with-padding {
  padding: 0 8px;
}
.row.with-padding-vertical {
  padding: 16px 0;
}
.row.with-padding-both {
  padding: 16px 8px;
}
.row.align-right {
  justify-content: flex-end;
  align-items: flex-end;
}
.row.height-28-width-160 {
  height: 28px;
  width: 160px;
}
.row.jc-sa {
  justify-content: space-around;
}
@media screen {
  .row.centered-items {
    justify-content: center;
  }
}
@media screen {
  .row.margin-top-16 {
    margin-top: 16px;
  }
}
@media screen {
  .row.margin-bottom-32 {
    margin-bottom: 32px;
  }
}
@media screen {
  .row.fr {
    flex-wrap: wrap;
  }
}
@media screen {
  .row.top-m {
    margin-top: 16px !important;
  }
}
@media screen {
  .row:last-child {
    margin-bottom: 0;
  }
}
.row.relative {
  position: relative;
}
@media screen {
  .row .col {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 0;
  }
}
@media screen {
  .row .col.auto-size {
    flex-basis: auto;
  }
}
@media screen {
  .row .col.text-right {
    text-align: right;
    justify-content: center;
  }
}
@media screen {
  .row .col.fx-wd {
    flex: unset;
    width: 25%;
  }
}
@media screen {
  .row .col.jc-fs {
    justify-content: flex-start;
  }
}
@media screen {
  .row .col.jc-fe {
    justify-content: flex-end;
  }
}
@media screen {
  .row .col.jc-c {
    justify-content: center;
  }
}
@media screen {
  .row .col.ac-c {
    align-content: center;
  }
}
@media screen {
  .row .col.ai-c {
    align-items: center;
  }
}
.row .col.ai-fe {
  align-items: flex-end;
}
@media screen {
  .row .col.centered {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen {
  .row .col.auto {
    flex: 0 0 0;
  }
}
@media screen {
  .row .col.white-space {
    white-space: nowrap;
  }
}
@media screen {
  .row .col.is-full {
    flex: none;
    width: 100%;
  }
}
@media screen {
  .row .col.push-right {
    margin-left: auto;
  }
  .row .col.push-right.left {
    margin-right: 2.5%;
  }
}
@media screen {
  .row .col.push-top {
    margin-top: auto;
  }
}
@media screen {
  .row .col.push-left {
    margin-right: auto;
  }
}
@media screen {
  .row .col.is-1-of-2 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-1-of-3 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-1-of-4 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-1-of-5 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-1-of-6 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .row .col.is-1-of-7 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .row .col.is-1-of-8 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .row .col.is-1-of-9 {
    flex: none;
    width: 11.1111111111%;
  }
}
@media screen {
  .row .col.is-1-of-10 {
    flex: none;
    width: 10%;
  }
}
@media screen {
  .row .col.is-1-of-11 {
    flex: none;
    width: 9.0909090909%;
  }
}
@media screen {
  .row .col.is-1-of-12 {
    flex: none;
    width: 8.3333333333%;
  }
}
@media screen {
  .row .col.is-1-of-13 {
    flex: none;
    width: 7.6923076923%;
  }
}
@media screen {
  .row .col.is-1-of-14 {
    flex: none;
    width: 7.1428571429%;
  }
}
@media screen {
  .row .col.is-1-of-15 {
    flex: none;
    width: 6.6666666667%;
  }
}
@media screen {
  .row .col.is-1-of-16 {
    flex: none;
    width: 6.25%;
  }
}
@media screen {
  .row .col.is-2-of-3 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-2-of-4 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-2-of-5 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-2-of-6 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-2-of-7 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .row .col.is-2-of-8 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-2-of-9 {
    flex: none;
    width: 22.2222222222%;
  }
}
@media screen {
  .row .col.is-2-of-10 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-2-of-11 {
    flex: none;
    width: 18.1818181818%;
  }
}
@media screen {
  .row .col.is-2-of-12 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .row .col.is-2-of-13 {
    flex: none;
    width: 15.3846153846%;
  }
}
@media screen {
  .row .col.is-2-of-14 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .row .col.is-2-of-15 {
    flex: none;
    width: 13.3333333333%;
  }
}
@media screen {
  .row .col.is-2-of-16 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .row .col.is-3-of-4 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-3-of-5 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-3-of-6 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-3-of-7 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .row .col.is-3-of-8 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .row .col.is-3-of-9 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-3-of-10 {
    flex: none;
    width: 30%;
  }
}
@media screen {
  .row .col.is-3-of-11 {
    flex: none;
    width: 27.2727272727%;
  }
}
@media screen {
  .row .col.is-3-of-12 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-3-of-13 {
    flex: none;
    width: 23.0769230769%;
  }
}
@media screen {
  .row .col.is-3-of-14 {
    flex: none;
    width: 21.4285714286%;
  }
}
@media screen {
  .row .col.is-3-of-15 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-3-of-16 {
    flex: none;
    width: 18.75%;
  }
}
@media screen {
  .row .col.is-4-of-5 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-4-of-6 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-4-of-7 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .row .col.is-4-of-8 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-4-of-9 {
    flex: none;
    width: 44.4444444444%;
  }
}
@media screen {
  .row .col.is-4-of-10 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-4-of-11 {
    flex: none;
    width: 36.3636363636%;
  }
}
@media screen {
  .row .col.is-4-of-12 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-4-of-13 {
    flex: none;
    width: 30.7692307692%;
  }
}
@media screen {
  .row .col.is-4-of-14 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .row .col.is-4-of-15 {
    flex: none;
    width: 26.6666666667%;
  }
}
@media screen {
  .row .col.is-4-of-16 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-5-of-6 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .row .col.is-5-of-7 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .row .col.is-5-of-8 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .row .col.is-5-of-9 {
    flex: none;
    width: 55.5555555556%;
  }
}
@media screen {
  .row .col.is-5-of-10 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-5-of-11 {
    flex: none;
    width: 45.4545454545%;
  }
}
@media screen {
  .row .col.is-5-of-12 {
    flex: none;
    width: 41.6666666667%;
  }
}
@media screen {
  .row .col.is-5-of-13 {
    flex: none;
    width: 38.4615384615%;
  }
}
@media screen {
  .row .col.is-5-of-14 {
    flex: none;
    width: 35.7142857143%;
  }
}
@media screen {
  .row .col.is-5-of-15 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-5-of-16 {
    flex: none;
    width: 31.25%;
  }
}
@media screen {
  .row .col.is-6-of-7 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .row .col.is-6-of-8 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-6-of-9 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-6-of-10 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-6-of-11 {
    flex: none;
    width: 54.5454545455%;
  }
}
@media screen {
  .row .col.is-6-of-12 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-6-of-13 {
    flex: none;
    width: 46.1538461538%;
  }
}
@media screen {
  .row .col.is-6-of-14 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .row .col.is-6-of-15 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-6-of-16 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .row .col.is-7-of-8 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .row .col.is-7-of-9 {
    flex: none;
    width: 77.7777777778%;
  }
}
@media screen {
  .row .col.is-7-of-10 {
    flex: none;
    width: 70%;
  }
}
@media screen {
  .row .col.is-7-of-11 {
    flex: none;
    width: 63.6363636364%;
  }
}
@media screen {
  .row .col.is-7-of-12 {
    flex: none;
    width: 58.3333333333%;
  }
}
@media screen {
  .row .col.is-7-of-13 {
    flex: none;
    width: 53.8461538462%;
  }
}
@media screen {
  .row .col.is-7-of-14 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-7-of-15 {
    flex: none;
    width: 46.6666666667%;
  }
}
@media screen {
  .row .col.is-7-of-16 {
    flex: none;
    width: 43.75%;
  }
}
@media screen {
  .row .col.is-8-of-9 {
    flex: none;
    width: 88.8888888889%;
  }
}
@media screen {
  .row .col.is-8-of-10 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-8-of-11 {
    flex: none;
    width: 72.7272727273%;
  }
}
@media screen {
  .row .col.is-8-of-12 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-8-of-13 {
    flex: none;
    width: 61.5384615385%;
  }
}
@media screen {
  .row .col.is-8-of-14 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .row .col.is-8-of-15 {
    flex: none;
    width: 53.3333333333%;
  }
}
@media screen {
  .row .col.is-8-of-16 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-9-of-10 {
    flex: none;
    width: 90%;
  }
}
@media screen {
  .row .col.is-9-of-11 {
    flex: none;
    width: 81.8181818182%;
  }
}
@media screen {
  .row .col.is-9-of-12 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-9-of-13 {
    flex: none;
    width: 69.2307692308%;
  }
}
@media screen {
  .row .col.is-9-of-14 {
    flex: none;
    width: 64.2857142857%;
  }
}
@media screen {
  .row .col.is-9-of-15 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-9-of-16 {
    flex: none;
    width: 56.25%;
  }
}
@media screen {
  .row .col.is-10-of-11 {
    flex: none;
    width: 90.9090909091%;
  }
}
@media screen {
  .row .col.is-10-of-12 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .row .col.is-10-of-13 {
    flex: none;
    width: 76.9230769231%;
  }
}
@media screen {
  .row .col.is-10-of-14 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .row .col.is-10-of-15 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-10-of-16 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .row .col.is-11-of-12 {
    flex: none;
    width: 91.6666666667%;
  }
}
@media screen {
  .row .col.is-11-of-13 {
    flex: none;
    width: 84.6153846154%;
  }
}
@media screen {
  .row .col.is-11-of-14 {
    flex: none;
    width: 78.5714285714%;
  }
}
@media screen {
  .row .col.is-11-of-15 {
    flex: none;
    width: 73.3333333333%;
  }
}
@media screen {
  .row .col.is-11-of-16 {
    flex: none;
    width: 68.75%;
  }
}
@media screen {
  .row .col.is-12-of-13 {
    flex: none;
    width: 92.3076923077%;
  }
}
@media screen {
  .row .col.is-12-of-14 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .row .col.is-12-of-15 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-12-of-16 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-13-of-14 {
    flex: none;
    width: 92.8571428571%;
  }
}
@media screen {
  .row .col.is-13-of-15 {
    flex: none;
    width: 86.6666666667%;
  }
}
@media screen {
  .row .col.is-13-of-16 {
    flex: none;
    width: 81.25%;
  }
}
@media screen {
  .row .col.is-14-of-15 {
    flex: none;
    width: 93.3333333333%;
  }
}
@media screen {
  .row .col.is-14-of-16 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .row .col.is-15-of-16 {
    flex: none;
    width: 93.75%;
  }
}

@media screen {
  .columns {
    display: flex;
    flex-direction: column;
  }
}

h2 .is-error {
  color: #C9243F;
}
@media screen {
  h2.page__title {
    color: #000;
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 16px 0;
  }
}

@media screen {
  h4.section_title {
    color: #000;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
  }
}

@media screen {
  strong {
    font-weight: 700;
  }
}
strong.medium {
  font-weight: 500;
}

@media screen {
  p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen {
  p:last-child {
    margin: 0;
  }
}
@media screen {
  p.link {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
}
@media screen {
  p.align-center {
    text-align: center;
  }
}
@media screen {
  p.large {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;
  }
}
@media screen {
  p.medium {
    font-size: 24px !important;
    line-height: 24px;
    margin: 0 0 24px;
  }
}
@media screen {
  p i {
    font-style: italic;
  }
}
@media screen {
  p.gray {
    color: #999;
  }
}
@media screen {
  p.pn_blue {
    color: #0089ce;
  }
}
@media screen {
  p.pn_red {
    color: #da1a31;
  }
}
@media screen {
  p.pn_small {
    font-weight: 500;
  }
}
@media screen {
  p.pn_bold {
    font-size: 1.1rem;
  }
}

@media screen {
  a.mailto_lnk {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  a.mailto_lnk:hover {
    color: #00365f;
  }
}

@media screen {
  .label24 {
    line-height: 24px;
  }
}

@media screen {
  h2.section__title {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }
}
@media screen {
  h2.section__title.large {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 16px;
  }
}

.revision_details h3 {
  font-size: 22px;
}
.revision_details p {
  font-size: 18px;
}

.container__loader {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
@media screen {
  .container__loader .image__loader {
    width: 120px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/loader.svg");
  }
}

@media screen {
  .auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
    background-image: url("../img/auth_bg.jpg");
  }
}
@media screen {
  .auth .auth__container {
    flex: none;
    width: 420px;
    background-color: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
@media screen {
  .auth .auth__container .auth__head {
    font-size: 24px;
    line-height: 48px;
    color: #fff;
    background-color: #00365f;
    text-align: center;
    margin: 0 0 40px 0;
  }
}
@media screen {
  .auth .auth__container .auth__body {
    padding: 0 40px 40px 40px;
  }
}

@media screen {
  section.default {
    padding: 16px;
    background-color: #fff;
    border: solid 1px #efefef;
    display: flex;
    flex-direction: column;
  }
}

@media screen {
  .container {
    width: 1300px;
    margin: 0 auto;
  }
}
@media screen {
  .container.narrow {
    width: 840px;
    margin: 0 auto;
  }
}
@media screen {
  .container.middle-narrow {
    width: 550px;
    margin: 0;
  }
}
@media screen {
  .container.narrower {
    width: 440px;
    margin: 0 auto;
  }
}

@media screen {
  header {
    background-color: #fff;
    flex: 0 0;
  }
}
@media screen {
  header .header__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
  }
}
@media screen {
  header .header__container .logo__container {
    display: flex;
    flex-direction: row;
    margin-left: 8px;
  }
}
@media screen {
  header .header__container .logo__container .usga__logo {
    margin: 0 8px 0 0;
  }
}
@media screen {
  header .header__container .logo__container span {
    color: #00365f;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
@media screen {
  header .header__container .search__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin: auto;
  }
}
@media screen {
  header .header__container .search__container .react-select-container {
    flex: none;
    width: 160px;
  }
}
@media screen {
  header .header__container .search__container .react-select-container .react-select__control.react-select__control--is-focused {
    border-color: #cccccc;
    border-right: none;
  }
}
@media screen {
  header .header__container .search__container .react-select-container:hover .react-select__control, header .header__container .search__container .react-select-container:focus .react-select__control {
    border-color: #cccccc;
    border-right: none;
  }
}
@media screen {
  header .header__container .search__container .react-select-container:hover .react-select__control.react-select__control--is-focused, header .header__container .search__container .react-select-container:focus .react-select__control.react-select__control--is-focused {
    border-color: #cccccc;
    border-right: none;
  }
}
@media screen {
  header .header__container .search__container input[type=text].search {
    flex: none;
    width: 300px;
    border-left: none;
    margin: 0 0 0 -1px;
    position: relative;
    z-index: 5;
  }
}
@media screen {
  header .header__container .search__container input[type=text].search:hover, header .header__container .search__container input[type=text].search:focus {
    border-color: #cccccc;
  }
}
@media screen {
  header .header__container .search__container .checkbox__container {
    margin-left: 2%;
  }
}
@media screen {
  header .header__container .profile__container {
    flex: none;
    width: 120px;
    margin: 0 0 0 32px;
  }
}

@media screen {
  .main__alert {
    cursor: pointer;
    width: 100%;
    padding: 8px 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: National;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    position: relative;
  }
}
.main__alert.red {
  background: #CC0000;
}
.main__alert.orange {
  background: #FF9900;
}
@media screen {
  .main__alert .icon {
    background: url("../img/alert-icon.svg") no-repeat center;
    width: 40px;
    height: 30px;
  }
}
@media screen {
  .main__alert span {
    padding-top: 4px;
    margin-left: 16px;
  }
}

@media screen {
  .hide_alert {
    position: absolute;
    cursor: pointer;
    right: 32px;
    top: 16px;
    border: none;
    width: 16px;
    height: 16px;
    background: url("../img/alert-close.svg") no-repeat center;
  }
}

@media screen {
  nav {
    background-color: #00365f;
    flex: 0 0;
  }
}
@media screen {
  nav .navigation__container {
    padding: 8px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
nav .navigation__container .push__right {
  margin-left: auto;
}
nav .navigation__container li .set__bulb__icon {
  color: yellow;
  font-size: 21px;
  position: absolute;
  line-height: 37px;
  margin-left: -2px;
}
nav .navigation__container li .empty__space::before {
  content: "..";
  opacity: 0;
}
@media screen {
  nav .navigation__container li .nav__link {
    font: inherit;
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    border: 0;
    background: none;
    display: block;
    padding: 0 16px;
    text-decoration: none;
  }
}
@media screen {
  nav .navigation__container li .nav__link:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}
@media screen {
  nav .navigation__container li .nav__link.is-active {
    color: #00365f;
    background-color: #fff;
  }
}

@media screen {
  .breadcrumbs__nav {
    background-color: #fff;
    flex: 0 0;
  }
}
@media screen {
  .breadcrumbs__nav .breadcrumb {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .breadcrumbs__nav .breadcrumb .breadcrumb-item {
    margin: 0 8px 0 0;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: capitalize;
  }
}
.breadcrumbs__nav .breadcrumb .breadcrumb-item.revision {
  text-transform: initial;
}
@media screen {
  .breadcrumbs__nav .breadcrumb .breadcrumb-item:not(:last-child)::after {
    content: ">";
    display: block;
    font-size: 16px;
    line-height: 32px;
    margin: 0 0 0 8px;
  }
}
@media screen {
  .breadcrumbs__nav .breadcrumb .breadcrumb-item a {
    color: #00365f;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .breadcrumbs__nav .breadcrumb .breadcrumb-item a:hover {
    color: #00365f;
  }
}
@media screen {
  .simple__page {
    padding: 100px 0;
    flex: 0;
  }
}
@media screen {
  .simple__page p {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px 0;
  }
}
@media screen {
  .simple__page p:last-child {
    margin: 0;
  }
}
@media screen {
  .simple__page p.text-align-center {
    text-align: center;
  }
}
@media screen {
  .simple__page strong {
    font-weight: 700;
  }
}
@media screen {
  .simple__page a {
    color: #1D87FF;
    border-bottom: solid 1px #1D87FF;
    text-decoration: none;
  }
}
@media screen {
  .simple__page a:hover {
    color: #00365f;
    border-color: #00365f;
  }
}
.simple__page button {
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  color: #1D87FF;
  border-bottom: solid 1px #1D87FF;
}
@media screen {
  .simple__page button:hover {
    color: #00365f;
    border-color: #00365f;
  }
}

@media screen {
  footer {
    margin: auto 0 0 0;
    border-top: solid 48px #00365f;
  }
}
@media screen {
  footer .footer__container {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  footer .footer__container .footer__navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px 0;
  }
}
@media screen {
  footer .footer__container .footer__navigation .footer__navigation__link {
    margin: 0 16px;
    font-size: 16px;
    line-height: 24px;
    display: block;
    color: #00365f;
    text-decoration: none;
  }
}
@media screen {
  footer .footer__container .footer__navigation .footer__navigation__link:hover {
    color: #00365f;
  }
}
@media screen {
  footer .footer__container .footer__copyright {
    border-top: solid 1px #CECECE;
    padding: 16px 0;
  }
}
@media screen {
  footer .footer__container .footer__copyright p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

@media screen {
  .page__head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen {
  .page__head .page__actions {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
  }
}
@media screen {
  .page__head .page__actions li {
    margin: 0 0 0 16px;
  }
}

@media screen {
  .page__sub__head {
    margin: 0 0 16px 0;
  }
}
@media screen {
  .page__sub__head .golfer__data {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .page__sub__head .golfer__data li {
    padding: 0 16px 0 0;
    margin: 0 16px 0 0;
    border-right: solid 1px #cccccc;
  }
}
@media screen {
  .page__sub__head .golfer__data li:last-child {
    padding: 0;
    margin: 0;
    border: none;
  }
}
@media screen {
  .page__sub__head .golfer__data li a {
    color: #1D87FF;
    display: inline-block;
  }
}

@media screen {
  .page__tabs {
    display: flex;
    flex-direction: row;
    position: relative;
  }
}
@media screen {
  .page__tabs .page__tab {
    color: #00365f;
    background-color: #fff;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    display: block;
    padding: 0 16px;
    text-decoration: none;
    opacity: 0.25;
    border: solid 1px #999;
    border-bottom: none;
  }
}
.page__tabs .page__tab.is-active {
  position: relative;
}
@media screen {
  .page__tabs .page__tab.is-active {
    opacity: 1;
  }
  .page__tabs .page__tab.is-active:after {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    background-color: #fff;
    bottom: -2px;
    left: 0;
  }
}
@media screen {
  .page__tabs .join__page__tab {
    color: #00365f;
    background-color: #fff;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    display: block;
    padding: 0 16px;
    margin: 4px 4px;
    text-decoration: none;
    opacity: 0.25;
    border: solid 1px #999;
  }
}
.page__tabs .join__page__tab.disabled {
  color: #999;
  cursor: default;
}
.page__tabs .join__page__tab.disabled:focus, .page__tabs .join__page__tab.disabled:hover, .page__tabs .join__page__tab.disabled:visited, .page__tabs .join__page__tab.disabled:link, .page__tabs .join__page__tab.disabled:active {
  text-decoration: none;
}
.page__tabs .join__page__tab.is-active {
  position: relative;
}
@media screen {
  .page__tabs .join__page__tab.is-active {
    opacity: 1;
  }
  .page__tabs .join__page__tab.is-active:after {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    background-color: #fff;
    left: 0;
  }
}
@media screen {
  .page__tabs .golfer__badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: -28px;
    right: 16px;
  }
}
@media screen {
  .page__tabs .golfer__badge .eff {
    color: #999;
    font-size: 14px;
    line-height: 48px;
    margin: 0 16px 0 0;
  }
}
@media screen {
  .page__tabs .golfer__badge .circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
    border: dashed 2px #cccccc;
    margin: 0 0 20px 10px;
  }
}
@media screen {
  .page__tabs .golfer__badge .circle .index {
    color: #999;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
}
@media screen {
  .page__tabs .golfer__badge .circle .score {
    color: #C9243F;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
  }
}
.page__tabs .golfer__badge .circle .date {
  font-size: 12px;
  color: gray;
  text-align: center;
  font-weight: 700;
}
@media screen {
  .page__tabs .golfer__badge .circle .gray {
    color: #999;
  }
}
.page__tabs .golfer__badge .info {
  margin-right: 8px;
  cursor: pointer;
}

@media screen {
  .page__tab-panel {
    padding: 16px;
    background-color: #fff;
    border: solid 1px #999;
  }
}

@media screen {
  .assoc-g__body .assoc-g__column {
    height: 540px;
    border: solid 1px #cccccc;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .assoc-g__body .assoc-g__column .assoc-g__column__head {
    flex: none;
    height: 46px;
    font-size: 16px;
    line-height: 46px;
    font-weight: 700;
    padding: 0 16px;
    border-bottom: solid 1px #cccccc;
    color: #000;
    background-color: #efefef;
  }
}
@media screen {
  .assoc-g__body .assoc-g__column .assoc-g__column__body {
    flex: 0 0 0;
  }
}
@media screen {
  .assoc-g__body .assoc-g__transition-controls {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .assoc-g__body .assoc-g__transition-controls .btn {
    margin: 0 0 16px 0;
  }
}
@media screen {
  .assoc-g__body .assoc-g__transition-controls .btn:last-child {
    margin: 0;
  }
}
@media screen {
  .assoc-g__body .assoc-g__transition-controls .btn .material-icons-outlined {
    margin: 0;
  }
}

@media screen {
  .assoc-g__controls {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen {
  .assoc-g__controls li {
    margin: 0 16px 0 0;
  }
}
@media screen {
  .assoc-g__controls li:last-child {
    margin: 0;
  }
}
@media screen {
  .assoc-g__controls li button {
    color: #00365f;
    border: 0 !important;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-decoration: none;
  }
}
@media screen {
  .assoc-g__controls li button:hover {
    color: #000;
  }
}

.data-set__container.left .data-label {
  text-align: left !important;
  width: 200px;
}
.data-set__container.left .data-value {
  width: 800px;
}
@media screen {
  .usga__logo {
    width: 110px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
    font-size: 0px;
    background-image: url("../img/usga_logo.svg");
    margin-bottom: 8px;
  }
}
@media screen {
  .usga__logo.push-right {
    margin-left: auto;
  }
}

@media screen {
  .hbh-container {
    width: 50%;
    padding: 8px;
  }
}
.hbh-container.disabled {
  pointer-events: none;
}
.hbh-container.disabled .hbh_cell input {
  background-color: #efefef;
}
.hbh-container.score-results {
  margin: 0 !important;
}
@media screen {
  .hbh-container .hbh-pre_head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen {
  .hbh-container .hbh-pre_head .hbh-pre_cell {
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media screen {
  .hbh-container .hbh-pre_head .hbh-pre_cell:first-child {
    text-align: left;
  }
}
@media screen {
  .hbh-container .hbh-pre_head .hbh-pre_cell:last-child {
    text-align: right;
  }
}
@media screen {
  .hbh-container .hbh-pre_head .hbh-pre_cell .hbh-title {
    color: #00365f;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    font-feature-settings: "tnum";
    margin: 0 0 5px 0;
    display: block;
  }
}
@media screen {
  .hbh-container .hbh-pre_head .hbh-pre_cell .hbh-clear {
    color: #0C496F;
    font-size: 14px;
    display: block;
    margin: 0 0 10px 0;
    cursor: pointer;
  }
}
@media screen {
  .hbh-container .hbh .hbh_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #efefef;
    border: 1px solid #acacac;
    border-top: 0;
  }
}
@media screen {
  .hbh-container .hbh .hbh_row.hole {
    color: #fff;
    background-color: #00365f;
    border: 1px solid #00365f;
  }
}
.hbh-container .hbh .hbh_row.hole .hbh_cell {
  border: none;
}
.hbh-container .hbh .hbh_row.adjusted-score .hbh_cell, .hbh-container .hbh .hbh_row.stroke .hbh_cell {
  padding-top: 5px;
}
@media screen {
  .hbh-container .hbh .hbh_row.score {
    background-color: #fff;
  }
}
@media screen {
  .hbh-container .hbh .hbh_row .hbh_cell {
    flex: 1;
    text-align: center;
    font-size: 12px;
    line-height: 36px;
    font-feature-settings: "tnum";
    border-left: solid 1px #acacac;
  }
}
.hbh-container .hbh .hbh_row .hbh_cell.gray {
  background-color: #efefef;
}
.hbh-container .hbh .hbh_row .hbh_cell input {
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  height: 36px;
}
.hbh-container .hbh .hbh_row .hbh_cell input:focus {
  -webkit-box-shadow: inset 0 0 0 2px #00365f;
  -moz-box-shadow: inset 0 0 0 2px #00365f;
  box-shadow: inset 0 0 0 2px #00365f;
}
@media screen {
  .hbh-container .hbh .hbh_row .hbh_cell:first-child {
    border-left: none;
  }
}
@media screen {
  .hbh-container .hbh .hbh_row .hbh_cell.label {
    flex: none;
    width: 85px;
    text-align: left;
    margin: 0;
    padding: 0 0 0 20px;
  }
  .hbh-container .hbh .hbh_row .hbh_cell.label.adjusted-score, .hbh-container .hbh .hbh_row .hbh_cell.label.stroke {
    padding: 10px 0px 10px 20px;
    line-height: 1;
  }
}

@media screen {
  .alerts_list {
    position: fixed;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    top: 0;
    right: 0;
    padding: 16px;
    z-index: 999;
  }
}
@media screen {
  .alerts_list li {
    margin: 0 0 16px 0;
    border: 1px solid;
    border-radius: 3px;
    height: auto;
    width: 380px;
    padding: 18px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    list-style: none;
    animation: fade_in 0.4s ease;
    transition: 0.4s ease;
    pointer-events: initial;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.alerts_list li:before {
  content: "";
  width: 32px;
  margin-right: 10px;
  height: 24px;
  margin-top: -4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.alerts_list li.info {
  background: rgb(205, 232, 243);
  color: rgb(67, 121, 157);
  border-color: rgb(67, 121, 157);
}
.alerts_list li.info:before {
  background-image: url("../img/outline-info-24px.svg");
}
.alerts_list li.warning {
  background: rgb(248, 243, 215);
  color: rgb(140, 111, 64);
  border-color: rgb(140, 111, 64);
}
.alerts_list li.warning:before {
  background-image: url("../img/outline-report_problem-24px.svg");
}
.alerts_list li.error {
  background: rgb(234, 200, 197);
  color: rgb(166, 46, 47);
  border-color: rgb(166, 46, 47);
}
.alerts_list li.error:before {
  background-image: url("../img/outline-not_interested-24px.svg");
}
.alerts_list li.success {
  background: rgb(222, 241, 215);
  color: rgb(89, 108, 82);
  border-color: rgb(89, 108, 82);
}
.alerts_list li.success:before {
  background-image: url("../img/outline-done-24px.svg");
}
.alerts_list li .alert-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
  text-transform: capitalize;
}
.alerts_list li .alert-message {
  width: 100%;
  font-size: 16px;
}
.alerts_list li:after {
  content: "";
  opacity: 0.8;
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-top: -4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url("../img/outline-close-24px.svg");
}
@media screen {
  .alerts_list li:last-child {
    margin: 0;
  }
}

@media screen {
  .dropdown {
    display: inline-block;
    position: relative;
  }
}
@media screen {
  .dropdown .dropdown__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  .dropdown .dropdown__content {
    position: absolute;
    right: -16px;
    display: none;
    background-color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    min-width: 160px;
    -webkit-transform: translate3d(0, 16px, 0);
    -moz-transform: translate3d(0, 16px, 0);
    -ms-transform: translate3d(0, 16px, 0);
    transform: translate3d(0, 16px, 0);
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  }
}
@media screen {
  .dropdown .dropdown__content::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -8px;
    right: 16px;
  }
}
@media screen {
  .dropdown .dropdown__content ul li .dropdown__option {
    color: #00365f;
    font-size: 16px;
    line-height: 48px;
    text-decoration: none;
    display: block;
    padding: 0 16px;
  }
}
@media screen {
  .dropdown .dropdown__content ul li .dropdown__option:hover {
    background-color: #E5EBEF;
  }
}
@media screen {
  .dropdown.dropdown--active .dropdown__content {
    display: block;
  }
}

@media screen {
  .material-icons-outlined {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@media screen {
  .pin {
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 50% 0;
    -webkit-transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    -moz-transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    -ms-transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    transform-origin: center center;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    align-self: flex-end;
    margin: 0 0 0 auto;
  }
}
@media screen {
  .pin.pull-up {
    position: relative;
    top: -10px;
  }
}
@media screen {
  .pin.pull-up-more {
    position: relative;
    top: -20px;
  }
}
@media screen {
  .pin.small {
    width: 30px;
    height: 30px;
  }
}
@media screen {
  .pin.purple {
    background-color: #4A124C;
  }
}
@media screen {
  .pin.green {
    background-color: #415e18;
  }
}
@media screen {
  .pin.blue {
    background-color: #158EE0;
  }
}

@media screen {
  .labeled-pin {
    font-size: 16px;
    line-height: 40px;
    white-space: nowrap;
  }
}
@media screen {
  .labeled-pin.small {
    line-height: 21px;
  }
}
@media screen {
  .labeled-pin::before {
    content: "";
    display: inline-block;
    vertical-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50% 50% 50% 0;
    -webkit-transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    -moz-transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    -ms-transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    transform: perspective(40px) rotateX(0deg) rotateZ(-45deg);
    transform-origin: center center;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
    margin: 0 8px -2px 0;
  }
}
@media screen {
  .labeled-pin.margin-left {
    margin-left: 8px;
  }
}
@media screen {
  .labeled-pin.margin-left::before {
    margin: 0 0 -2px 0;
  }
}
@media screen {
  .labeled-pin.purple::before {
    background-color: #4A124C;
  }
}
@media screen {
  .labeled-pin.green::before {
    background-color: #415e18;
  }
}
@media screen {
  .labeled-pin.blue::before {
    background-color: #158EE0;
  }
}

.fa-flag-image-text {
  white-space: nowrap;
  margin: auto;
}

@media screen {
  .fa-flag {
    line-height: 40px;
    margin: auto;
  }
}
@media screen {
  .fa-flag.small {
    font-size: 22px;
  }
}
@media screen {
  .fa-flag.big {
    font-size: 24px;
  }
}
@media screen {
  .fa-flag.blue {
    color: #158EE0;
  }
}
.fa-flag.line-height-18 {
  line-height: 18px;
}
.fa-flag.absolute {
  position: absolute;
}
@media screen {
  .fa-flag.margin-left {
    margin-left: 10px;
  }
}
@media screen {
  .fa-flag.margin-left::before {
    margin: 0;
  }
}
@media screen {
  .fa-flag.margin-right {
    margin-right: 8px;
  }
}
@media screen {
  .fa-flag.margin-right::before {
    margin: 0 0 -2px 0;
  }
}

@media screen {
  .material-icons-outlined.yellow,
  .material-icons.yellow {
    color: #FF9900;
  }
}
@media screen {
  .material-icons-outlined.green,
  .material-icons.green {
    color: green;
  }
}
@media screen {
  .material-icons-outlined.red,
  .material-icons.red {
    color: #C9243F;
  }
}
@media screen {
  .material-icons-outlined.blue,
  .material-icons.blue {
    color: #1D87FF;
  }
}
@media screen {
  .material-icons-outlined.white,
  .material-icons.white {
    color: #fff;
  }
}
@media screen {
  .material-icons-outlined.small,
  .material-icons.small {
    font-size: 16px;
  }
}
@media screen {
  .material-icons-outlined.v-mid,
  .material-icons.v-mid {
    vertical-align: middle;
  }
}
@media screen {
  .material-icons-outlined.m-left,
  .material-icons.m-left {
    margin: 0 0 0 8px;
  }
}

@media screen {
  .modal .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-animation: fade_in 600ms both;
    -moz-animation: fade_in 600ms both;
    -o-animation: fade_in 600ms both;
    animation: fade_in 600ms both;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}
.modal .modal__overlay.white {
  background-color: white;
}
@media screen {
  .modal .modal__content {
    width: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    border-top: solid 64px transparent;
    border-bottom: solid 64px transparent;
  }
  .modal .modal__content.not-found {
    width: 490px;
  }
}
@media screen {
  .modal .modal__content .modal__container {
    pointer-events: initial;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }
}
.modal .modal__content .modal__container.hidden {
  display: none;
}
@media screen {
  .modal .modal__content .modal__container.narrow {
    width: 720px;
  }
}
@media screen {
  .modal .modal__content .modal__container.very-narrow {
    width: 520px;
  }
}
@media screen {
  .modal .modal__content .modal__container.semi-extra-narrow {
    width: 880px;
  }
}
@media screen {
  .modal .modal__content .modal__container.semi-narrow {
    width: 620px;
  }
}
@media screen {
  .modal .modal__content .modal__container.wide {
    width: 960px;
  }
}
@media screen {
  .modal .modal__content .modal__container.extra-wide {
    width: 1100px;
  }
}
@media screen {
  .modal .modal__content .modal__container.ultra-wide {
    width: 1300px;
  }
}
.modal .modal__content .modal__container.not-found {
  position: absolute;
  top: -330px;
}
@media screen {
  .modal .modal__content .modal__container .modal__head {
    height: 64px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #0089ce;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .modal .modal__content .modal__container .modal__head.auto__height {
    height: auto !important;
  }
}
.modal .modal__content .modal__container .modal__head.alert {
  background-color: #CC0000;
}
.modal .modal__content .modal__container .modal__head.alert .modal__icon {
  background: url("../img/alert-icon.svg") no-repeat center;
  width: 30px;
  height: 30px;
}
.modal .modal__content .modal__container .modal__head.white {
  background-color: white;
}
.modal .modal__content .modal__container .modal__head.white .modal__icon {
  width: 80px;
  height: 80px;
  background-color: #C9243F;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal .modal__content .modal__container .modal__head.white .modal__icon .exclamation {
  font-size: 45px;
  color: white;
  font-weight: bold;
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__title {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin: 0;
  }
}
.modal .modal__content .modal__container .modal__head .modal__title.long {
  padding: 16px 0px;
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__icon {
    margin: 0 16px 0 0;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__icon .material-icons-outlined {
    color: #fff;
    font-size: 32px;
    line-height: 32px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__close {
    width: 32px;
    height: 32px;
    margin: 0 0 0 auto;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__close:hover {
    color: #0089ce;
    background-color: #fff;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__close .material-icons-outlined {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head.red {
    background-color: #C9243F;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head.red .modal__close:hover {
    color: #C9243F;
    background-color: #fff;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body {
    padding: 48px;
    background-color: #fff;
  }
}
.modal .modal__content .modal__container .modal__body span.label {
  white-space: inherit !important;
}
.modal .modal__content .modal__container .modal__body.alert {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen {
  .modal .modal__content .modal__container .modal__body .row:last-child {
    margin-top: 48px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body .row:last-child:only-of-type {
    margin-top: 0;
  }
}
.modal .modal__content .modal__container .modal__body .bottom-margin {
  margin-bottom: 20px;
}
.modal .modal__content .modal__container .modal__body.center {
  color: #999;
  padding: 20px 48px 48px 48px;
}
.modal .modal__content .modal__container .modal__body.center h2 {
  color: #999;
  font-size: 30px;
  text-align: center;
}
.modal .modal__content .modal__container .modal__body.center .modal__button {
  margin: 20px 0 20px 0;
}
.modal .modal__content .modal__container .modal__body.center .modal__link {
  text-align: center;
  color: blue;
  font-size: 14px;
}
.modal .modal__content .modal__container.confirmation span {
  color: #da1a31;
}
.modal .modal__content .modal__container.success .modal__head {
  background-color: #fff;
}
.modal .modal__content .modal__container.success .modal__head .modal__close {
  color: #acacac;
}
.modal .modal__content .modal__container.success .modal__head .modal__close :hover {
  color: #0089ce;
}
.modal .modal__content .modal__container.success .success-icon {
  position: absolute;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: 70px;
  display: flex;
  border-radius: 50%;
  background-color: #0089ce;
  align-items: center;
  justify-content: center;
  top: -30px;
}
.modal .modal__content .modal__container.success .success-icon i {
  font-size: 60px;
  color: #fff;
}
.modal .modal__content .modal__container.success .success-icon i.warning {
  font-size: 50px;
}
.modal .modal__content .modal__container.success .modal__body {
  padding-top: 0;
  text-align: center;
}
.modal .modal__content .modal__container.success .modal__body .modal__title {
  font-size: 48px;
  color: #acacac;
}
.modal .modal__content .modal__container.success .modal__body .row {
  flex-direction: column;
  width: 60%;
  margin: auto;
  margin-top: 30px !important;
}
.modal .modal__content .modal__container.success .modal__body .actions {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
  margin-top: 30px !important;
}
.modal .modal__content .modal__container.success .modal__body .actions .action {
  margin-bottom: 8px;
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal__body {
    position: relative;
    padding-top: 80px;
  }
}
@media screen {
  .modal .modal__content .modal__container.type-b p.medium {
    font-size: 18px;
  }
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal__close {
    background-color: transparent;
    border: none;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal__title {
    font-size: 32px;
    text-align: center;
  }
}
.modal .modal__content .modal__container.type-b .brick_red .modal__title, .modal .modal__content .modal__container.type-b .brick_red .modal-head__icon .material-icons-outlined {
  color: #BE2A34 !important;
}
.modal .modal__content .modal__container.type-b .brick_red .btn.bg_brick_red {
  background-color: #BE2A34 !important;
  color: #fff !important;
}
.modal .modal__content .modal__container.type-b .brick_red.modal__body p {
  font-size: 18px !important;
  text-align: left !important;
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal-head__icon .material-icons-outlined {
    width: 128px;
    height: 128px;
    display: block;
    font-size: 128px;
    line-height: 128px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    color: #0089ce;
    background-color: #fff;
  }
}

@media screen {
  .custom__modal .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-animation: fade_in 600ms both;
    -moz-animation: fade_in 600ms both;
    -o-animation: fade_in 600ms both;
    animation: fade_in 600ms both;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .custom__modal .modal__content {
    width: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    border-top: solid 64px transparent;
    border-bottom: solid 64px transparent;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container {
    pointer-events: initial;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }
}
.custom__modal .modal__content .modal__container.very-narrow {
  width: 520px;
}
.custom__modal .modal__content .modal__container .modal__head.red {
  background-color: #CC0000;
}
.custom__modal .modal__content .modal__container .modal__head.orange {
  background-color: #FF9900;
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__head {
    height: 64px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .custom__modal .modal__content .modal__container .modal__head .modal__icon {
    margin: 0 16px 0 0;
    background: url("../img/alert-icon.svg") no-repeat center;
    width: 30px;
    height: 30px;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__head .modal__title {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin: 0;
  }
  .custom__modal .modal__content .modal__container .modal__head .modal__title.long {
    padding: 16px 0px;
  }
}
.custom__modal .modal__content .modal__container .modal__body {
  padding: 48px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom__modal .modal__content .modal__container .modal__body p {
  color: #333333;
  text-align: justify;
  font-size: 16px;
  line-height: 1.2;
}

@media screen {
  .home__modal-triggers {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .home__modal-triggers .home__modal-trigger {
    flex: 0 0 0;
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    font-size: 24px;
    line-height: 64px;
    font-family: "National", sans-serif;
    border: solid 1px #000;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
  }
}
@media screen {
  .home__modal-triggers .home__modal-trigger:hover {
    background-color: #efefef;
  }
}
@media screen {
  .home__modal-triggers .home__modal-trigger .material-icons-outlined {
    color: #1D87FF;
    font-size: 32px;
    margin: 0 8px 0 0;
  }
}

.ReactTable {
  position: relative;
}
.ReactTable .loader {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

@media screen {
  .data__table {
    width: 100%;
    border: solid 1px #efefef;
  }
}
@media screen {
  .data__table.margin-top {
    margin: 16px 0 0 0;
  }
}
@media screen {
  .data__table thead tr th,
  .data__table thead tr td,
  .data__table tbody tr th,
  .data__table tbody tr td {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
    word-wrap: break-word;
  }
}
@media screen {
  .data__table thead,
  .data__table .data__table-head {
    color: #fff;
    background-color: #00365f;
    font-weight: 700;
    display: table-header-group;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  .data__table thead tr,
  .data__table thead .data__table-row,
  .data__table .data__table-head tr,
  .data__table .data__table-head .data__table-row {
    display: table-row;
  }
}
@media screen {
  .data__table thead tr th,
  .data__table thead tr .data__table-cell,
  .data__table thead .data__table-row th,
  .data__table thead .data__table-row .data__table-cell,
  .data__table .data__table-head tr th,
  .data__table .data__table-head tr .data__table-cell,
  .data__table .data__table-head .data__table-row th,
  .data__table .data__table-head .data__table-row .data__table-cell {
    display: table-cell;
  }
}
@media screen {
  .data__table > tbody > tr {
    border-bottom: solid 1px #efefef;
  }
}
@media screen {
  .data__table > tbody > tr:nth-child(odd) {
    background-color: rgba(239, 239, 239, 0.5);
  }
}
@media screen {
  .data__table > tbody > tr:hover {
    background-color: rgb(237.725, 237.725, 237.725);
  }
}
@media screen {
  .data__table > tbody > tr td {
    font-weight: 500;
    border-right: solid 1px #efefef;
  }
}
@media screen {
  .data__table > tbody > tr td:only-of-type {
    text-align: center;
  }
}
@media screen {
  .data__table > tbody > tr td:last-child {
    border-right: none;
  }
}
@media screen {
  .data__table > tbody > tr td a {
    color: #00365f;
  }
}
@media screen {
  .data__table > tbody > tr td button {
    border: 0 !important;
    cursor: pointer;
    background: none;
    text-decoration: underline;
    font: inherit;
    padding: 0;
    color: #00365f;
  }
}
@media screen {
  .data__table > tbody.scrollable {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-2 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-3 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-4 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-5 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-6 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-7 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-8 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-9 {
    flex: none;
    width: 11.1111111111%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-10 {
    flex: none;
    width: 10%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-11 {
    flex: none;
    width: 9.0909090909%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-12 {
    flex: none;
    width: 8.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-13 {
    flex: none;
    width: 7.6923076923%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-14 {
    flex: none;
    width: 7.1428571429%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-15 {
    flex: none;
    width: 6.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-1-of-16 {
    flex: none;
    width: 6.25%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-3 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-4 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-5 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-6 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-7 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-8 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-9 {
    flex: none;
    width: 22.2222222222%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-10 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-11 {
    flex: none;
    width: 18.1818181818%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-12 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-13 {
    flex: none;
    width: 15.3846153846%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-14 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-15 {
    flex: none;
    width: 13.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-2-of-16 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-4 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-5 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-6 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-7 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-8 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-9 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-10 {
    flex: none;
    width: 30%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-11 {
    flex: none;
    width: 27.2727272727%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-12 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-13 {
    flex: none;
    width: 23.0769230769%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-14 {
    flex: none;
    width: 21.4285714286%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-15 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .data__table thead tr th.is-3-of-16 {
    flex: none;
    width: 18.75%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-5 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-6 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-7 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-8 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-9 {
    flex: none;
    width: 44.4444444444%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-10 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-11 {
    flex: none;
    width: 36.3636363636%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-12 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-13 {
    flex: none;
    width: 30.7692307692%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-14 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-15 {
    flex: none;
    width: 26.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-4-of-16 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-6 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-7 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-8 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-9 {
    flex: none;
    width: 55.5555555556%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-10 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-11 {
    flex: none;
    width: 45.4545454545%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-12 {
    flex: none;
    width: 41.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-13 {
    flex: none;
    width: 38.4615384615%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-14 {
    flex: none;
    width: 35.7142857143%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-15 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-5-of-16 {
    flex: none;
    width: 31.25%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-7 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-8 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-9 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-10 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-11 {
    flex: none;
    width: 54.5454545455%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-12 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-13 {
    flex: none;
    width: 46.1538461538%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-14 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-15 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .data__table thead tr th.is-6-of-16 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-8 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-9 {
    flex: none;
    width: 77.7777777778%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-10 {
    flex: none;
    width: 70%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-11 {
    flex: none;
    width: 63.6363636364%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-12 {
    flex: none;
    width: 58.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-13 {
    flex: none;
    width: 53.8461538462%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-14 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-15 {
    flex: none;
    width: 46.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-7-of-16 {
    flex: none;
    width: 43.75%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-9 {
    flex: none;
    width: 88.8888888889%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-10 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-11 {
    flex: none;
    width: 72.7272727273%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-12 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-13 {
    flex: none;
    width: 61.5384615385%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-14 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-15 {
    flex: none;
    width: 53.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-8-of-16 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-10 {
    flex: none;
    width: 90%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-11 {
    flex: none;
    width: 81.8181818182%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-12 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-13 {
    flex: none;
    width: 69.2307692308%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-14 {
    flex: none;
    width: 64.2857142857%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-15 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .data__table thead tr th.is-9-of-16 {
    flex: none;
    width: 56.25%;
  }
}
@media screen {
  .data__table thead tr th.is-10-of-11 {
    flex: none;
    width: 90.9090909091%;
  }
}
@media screen {
  .data__table thead tr th.is-10-of-12 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-10-of-13 {
    flex: none;
    width: 76.9230769231%;
  }
}
@media screen {
  .data__table thead tr th.is-10-of-14 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .data__table thead tr th.is-10-of-15 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-10-of-16 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .data__table thead tr th.is-11-of-12 {
    flex: none;
    width: 91.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-11-of-13 {
    flex: none;
    width: 84.6153846154%;
  }
}
@media screen {
  .data__table thead tr th.is-11-of-14 {
    flex: none;
    width: 78.5714285714%;
  }
}
@media screen {
  .data__table thead tr th.is-11-of-15 {
    flex: none;
    width: 73.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-11-of-16 {
    flex: none;
    width: 68.75%;
  }
}
@media screen {
  .data__table thead tr th.is-12-of-13 {
    flex: none;
    width: 92.3076923077%;
  }
}
@media screen {
  .data__table thead tr th.is-12-of-14 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .data__table thead tr th.is-12-of-15 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .data__table thead tr th.is-12-of-16 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .data__table thead tr th.is-13-of-14 {
    flex: none;
    width: 92.8571428571%;
  }
}
@media screen {
  .data__table thead tr th.is-13-of-15 {
    flex: none;
    width: 86.6666666667%;
  }
}
@media screen {
  .data__table thead tr th.is-13-of-16 {
    flex: none;
    width: 81.25%;
  }
}
@media screen {
  .data__table thead tr th.is-14-of-15 {
    flex: none;
    width: 93.3333333333%;
  }
}
@media screen {
  .data__table thead tr th.is-14-of-16 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .data__table thead tr th.is-15-of-16 {
    flex: none;
    width: 93.75%;
  }
}
@media screen {
  .data__table thead tr th.table-checkbox-cell {
    text-align: center;
  }
}
@media screen {
  .data__table thead tr th.table-id-cell {
    text-align: center;
  }
}
.data__table .__react_component_tooltip {
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  overflow: initial;
  border-radius: 3px;
}
.data__table .__react_component_tooltip.place-top:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
}
.data__table .__react_component_tooltip.place-top:after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
}

@media screen {
  colgroup col.col__checkbox {
    width: 18px;
  }
}
@media screen {
  colgroup col.col__id {
    width: 40px;
    text-align: center;
  }
}

@media screen {
  .data__table-footer {
    padding: 16px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen {
  .data__table-footer .table__navigation {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination {
    display: flex;
    flex-direction: row;
    margin: 0 16px 0 0;
    border: solid 1px #efefef;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li {
    border-right: solid 1px #efefef;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li:last-child {
    border-right: none;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li button {
    color: #000;
    width: 38px;
    height: 38px;
    border: 0 !important;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li button.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li button.is-active {
    color: #fff;
    background-color: #00365f;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li button.is-active:hover {
    color: #fff;
    background-color: #00365f;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__pagination li button:hover {
    background-color: #efefef;
  }
}
@media screen {
  .data__table-footer .table__navigation .table__page-select-container {
    width: 80px;
  }
}
@media screen {
  .data__table-footer .table__info {
    text-align: right;
    color: #000;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
  }
}

@media screen {
  .filters {
    padding: 16px;
    margin: 0 0 16px 0;
    background-color: #efefef;
  }
}
@media screen {
  .filters label {
    margin-bottom: 12px;
  }
}
@media screen {
  .filters .or {
    font-size: 18px;
  }
}
@media screen {
  .filters .or:after {
    background: #efefef !important;
  }
}
@media screen {
  .filters .top {
    margin-top: 28px;
  }
}

@media screen {
  .pre__table {
    margin: 0 0 16px 0;
  }
}

@media screen {
  .association__status {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 0;
  }
}
@media screen {
  .association__status li {
    border-right: solid 1px #cccccc;
    white-space: nowrap;
    padding: 0 8px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen {
  .association__status li:last-child {
    border: none;
  }
}
@media screen {
  .association__status li strong {
    font-weight: 700;
  }
}

@media screen {
  .data__table thead tr th.rt-resizable-header {
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  .data__table thead tr th.rt-resizable-header .rt-resizable-header-content,
  .data__table thead tr th.rt-resizable-header .material-icons-outlined {
    display: inline-block;
    vertical-align: middle;
  }
}
@media screen {
  .data__table thead tr th.rt-resizable-header .material-icons-outlined {
    width: 18px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    position: absolute;
    right: 8px;
  }
}

@media screen {
  .data__table thead tr th {
    white-space: nowrap;
    width: 100%;
  }
}
@media screen {
  .data__table thead tr th.table-checkbox-cell {
    width: 38px;
  }
}
@media screen {
  .data__table thead tr th.table-checkbox-cell label {
    width: 18px;
  }
}

.data__table.normal-overflow thead th.primary {
  width: 60px;
  text-align: center;
}
.data__table.normal-overflow tbody tr td {
  overflow: initial !important;
}
.data__table.normal-overflow tbody tr td.primary {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen {
  .data__table {
    table-layout: fixed;
  }
}
@media screen {
  .data__table.not-fixed {
    table-layout: auto;
  }
}
@media screen {
  .data__table colgroup col.col_checkbox {
    width: 40px;
  }
}
@media screen {
  .data__table colgroup col.col_centered_checkbox {
    width: 90px;
  }
}
@media screen {
  .data__table colgroup col.col_id {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_id.clubs {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_id.golfers {
    width: 90px;
  }
}
@media screen {
  .data__table colgroup col.col_assoc-name, .data__table colgroup col.col_club-name, .data__table colgroup col.col_golfer-name, .data__table colgroup col.col_course-name, .data__table colgroup col.col_transaction-name, .data__table colgroup col.col_user-name {
    width: 220px;
  }
}
@media screen {
  .data__table colgroup col.col_membership-code {
    width: 175px;
  }
}
@media screen {
  .data__table colgroup col.col_is_locked {
    width: 115px;
  }
}
@media screen {
  .data__table colgroup col.col_bill_rate {
    width: 120px;
  }
}
@media screen {
  .data__table colgroup col.col_buttons {
    width: 140px;
  }
}
@media screen {
  .data__table colgroup col.col_golfer-name-short {
    width: 140px;
  }
}
@media screen {
  .data__table colgroup col.col_status {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_active {
    width: 82px;
  }
}
@media screen {
  .data__table colgroup col.col_join_pages_actions {
    width: 230px;
  }
}
@media screen {
  .data__table colgroup col.col_club_join_pages_action {
    width: 115px;
  }
}
@media screen {
  .data__table colgroup col.col_extended-status {
    width: 76px;
  }
}
@media screen {
  .data__table colgroup col.col_handicap-index {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_city {
    width: 140px;
  }
}
@media screen {
  .data__table colgroup col.col_addons_enabled {
    width: 120px;
  }
}
@media screen {
  .data__table colgroup col.col_state {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_country {
    width: 150px;
  }
}
@media screen {
  .data__table colgroup col.col_phone-number {
    width: 120px;
  }
}
@media screen {
  .data__table colgroup col.col_requirements {
    width: 180px;
  }
}
@media screen {
  .data__table colgroup col.col_eligibility {
    width: 200px;
  }
}
.data__table colgroup col.col_email-address {
  width: 250px;
}
.data__table colgroup col.col_description {
  width: 300px;
}
@media screen {
  .data__table colgroup col.col_date {
    width: 110px;
  }
}
@media screen {
  .data__table colgroup col.col_fee_date {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_fee {
    width: 75px;
  }
}
@media screen {
  .data__table colgroup col.col_fee_amount {
    width: 90px;
  }
}
@media screen {
  .data__table colgroup col.col_age {
    width: 50px;
  }
}
@media screen {
  .data__table colgroup col.col_gender_eligibility {
    width: 105px;
  }
}
@media screen {
  .data__table colgroup col.col_club_fee_date {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_edited_date {
    width: 155px;
  }
}
@media screen {
  .data__table colgroup col.col_membership_name {
    width: 150px;
  }
}
@media screen {
  .data__table colgroup col.col_ojr {
    width: 50px;
  }
}
@media screen {
  .data__table colgroup col.col_fee_name {
    width: 155px;
  }
}
@media screen {
  .data__table colgroup col.col_date_edited {
    width: 180px;
  }
}
@media screen {
  .data__table colgroup col.col_membership_code {
    width: 150px;
  }
}
@media screen {
  .data__table colgroup col.col_date_posted {
    width: 260px;
  }
}
@media screen {
  .data__table colgroup col.col_date_and_time {
    width: 180px;
  }
}
@media screen {
  .data__table colgroup col.col_date_time {
    width: 165px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_date {
    width: 97px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_range_date {
    width: 138px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_description {
    width: 91px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_type {
    width: 65px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_status {
    width: 65px;
  }
}
@media screen {
  .data__table colgroup col.col_club_discount {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_auto_apply {
    width: 65px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_applied_to {
    width: 106px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_times_used {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_discount_amount {
    width: 70px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_id {
    width: 170px;
  }
}
@media screen {
  .data__table colgroup col.col_offer_actions {
    width: 153px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_date {
    width: 97px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_code {
    width: 120px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_type {
    width: 50px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_discount_amount {
    width: 75px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_club_discount {
    width: 78px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_status {
    width: 60px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_applied_to {
    width: 106px;
  }
}
@media screen {
  .data__table colgroup col.col_archived_offer_usage {
    width: 80px;
  }
}
@media screen {
  .data__table colgroup col.col_change_code, .data__table colgroup col.col_method, .data__table colgroup col.col_username {
    width: 160px;
  }
}
@media screen {
  .data__table colgroup col.col_fee_description {
    width: 150px;
  }
}
@media screen {
  .data__table colgroup col.col_method-small {
    width: 120px;
  }
}
@media screen {
  .data__table colgroup col.col_method-extra-small {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_type {
    width: 110px;
  }
}
@media screen {
  .data__table colgroup col.col_player-name {
    width: 240px;
  }
}
@media screen {
  .data__table colgroup col.col_hi, .data__table colgroup col.col_holes {
    width: 60px;
  }
}
@media screen {
  .data__table colgroup col.col_addons_status {
    width: 70px;
  }
}
.data__table colgroup col.col_hidden {
  display: none;
}
@media screen {
  .data__table colgroup col.col_ghin {
    width: 140px;
  }
}
@media screen {
  .data__table colgroup col.col_date_applied {
    width: 96px;
  }
}
@media screen {
  .data__table colgroup col.col_local-id {
    width: 140px;
  }
}
@media screen {
  .data__table colgroup col.col_gender {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_details {
    width: 170px;
  }
}
@media screen {
  .data__table colgroup col.col_slope {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_score, .data__table colgroup col.col_diff, .data__table colgroup col.col_pcc, .data__table colgroup col.col_esr {
    width: 80px;
  }
}
.data__table colgroup col.col_ghin_number {
  width: 90px;
}
@media screen {
  .data__table colgroup col.col_used {
    width: 10px;
  }
}
@media screen {
  .data__table colgroup col.col_other_adj {
    width: 120px;
  }
}
@media screen {
  .data__table colgroup col.col_note {
    width: 300px;
  }
}
@media screen {
  .data__table colgroup col.col_id_assoc {
    width: 90px;
  }
}
@media screen {
  .data__table colgroup col.col_min {
    width: 80px;
  }
}
@media screen {
  .data__table colgroup col.col_actions_centered {
    width: 100px;
  }
}
@media screen {
  .data__table colgroup col.col_actions_centered_w130 {
    width: 130px;
  }
}
@media screen {
  .data__table colgroup col.col_pico {
    width: 50px;
  }
}
@media screen {
  .data__table colgroup col.col_tee {
    width: 160px;
  }
}
@media screen {
  .data__table colgroup col.col_edit {
    width: 45px;
  }
}
@media screen {
  .data__table colgroup col.col_delete {
    width: 65px;
  }
}
@media screen {
  .data__table colgroup col.col_remove {
    width: 68px;
  }
}
@media screen {
  .data__table colgroup col.col_nickname {
    width: 200px;
  }
}
@media screen {
  .data__table colgroup col.col_url {
    width: 280px;
  }
}
.data__table tbody tr td .text-holder, .data__table tbody tr span .text-holder {
  white-space: nowrap;
  overflow: hidden;
}
.data__table tbody tr td .text-holder.col_wrap, .data__table tbody tr span .text-holder.col_wrap {
  white-space: normal;
}
.data__table tbody tr td .text-holder.default-tee, .data__table tbody tr span .text-holder.default-tee {
  overflow: visible;
}
@media screen {
  .data__table tbody tr td .material-icons-outlined, .data__table tbody tr span .material-icons-outlined {
    font-size: 18px;
    line-height: 18px;
  }
}
@media screen {
  .data__table tbody tr td .material-icons-outlined.blue, .data__table tbody tr span .material-icons-outlined.blue {
    color: #1D87FF;
    cursor: pointer;
  }
}
@media screen {
  .data__table tbody tr td .material-icons-outlined.blue:hover, .data__table tbody tr span .material-icons-outlined.blue:hover {
    color: rgb(0, 109.2831858407, 233);
  }
}
@media screen {
  .data__table tbody tr td .material-icons-outlined.td-align-right, .data__table tbody tr span .material-icons-outlined.td-align-right {
    position: absolute;
    right: 10px;
  }
}
@media screen {
  .data__table tbody tr td .material-icons-outlined.td-align-center, .data__table tbody tr span .material-icons-outlined.td-align-center {
    position: relative;
    top: 4px;
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
.data__table tbody tr td td.col_edit-delete, .data__table tbody tr td span.col_edit-delete, .data__table tbody tr span td.col_edit-delete, .data__table tbody tr span span.col_edit-delete {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 20px;
  height: 20px;
  align-items: center;
}
.data__table tbody tr td td.col_edit-delete-challenges, .data__table tbody tr td span.col_edit-delete-challenges, .data__table tbody tr span td.col_edit-delete-challenges, .data__table tbody tr span span.col_edit-delete-challenges {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 33px;
  height: 33px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media screen {
  .data__table tbody tr td.col_edit-delete, .data__table tbody tr span.col_edit-delete {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 0 !important;
  }
}
.data__table tbody tr td.col_long-text, .data__table tbody tr span.col_long-text {
  white-space: initial;
  overflow: initial;
}
@media screen {
  .data__table tbody tr td.yellow_highlight, .data__table tbody tr span.yellow_highlight {
    background-color: rgb(255, 255, 214);
  }
}
@media screen {
  .data__table thead tr th.table-align-center-no-padding,
  .data__table thead tr td.table-align-center-no-padding,
  .data__table tbody tr th.table-align-center-no-padding,
  .data__table tbody tr td.table-align-center-no-padding {
    padding: 0px;
    text-align: center;
  }
}
@media screen {
  .data__table thead tr th.table-align-center,
  .data__table thead tr td.table-align-center,
  .data__table tbody tr th.table-align-center,
  .data__table tbody tr td.table-align-center {
    text-align: center;
  }
}
@media screen {
  .data__table thead tr th.table-align-right,
  .data__table thead tr td.table-align-right,
  .data__table tbody tr th.table-align-right,
  .data__table tbody tr td.table-align-right {
    text-align: right;
  }
}
.data__table thead tr th span,
.data__table thead tr td span,
.data__table tbody tr th span,
.data__table tbody tr td span {
  display: inline-flex;
}

@media screen {
  .data-table__loader {
    width: 120px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/loader.svg");
  }
}

.table__legend h5 {
  margin-top: 10px;
}
@media screen {
  .table__legend {
    display: flex;
    flex-direction: row;
    padding: 0 8px;
    border: solid 1px #efefef;
  }
}
@media screen {
  .table__legend li {
    white-space: nowrap;
    color: #999;
    font-size: 14px;
    line-height: 38px;
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media screen {
  .table__legend li .material-icons-outlined {
    margin: 0 8px 0 0;
  }
}
@media screen {
  .table__legend li .legend-item {
    display: inline-block;
    width: 32px;
    height: 16px;
    border: solid 1px #efefef;
    margin: 0 16px 0 0;
  }
}
@media screen {
  .table__legend li .legend-item.yellow_highlight {
    background-color: rgb(255, 255, 214);
  }
}
@media screen {
  .table__legend li:last-child {
    margin: 0;
  }
}
.table__legend li span {
  font-weight: bold;
  margin: 0 3px;
}

.table__legend__checkboxes {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
}
.table__legend__checkboxes .vertical_line {
  border-left: 1px solid #333333;
  font-size: 16px;
  height: 24px;
}

.checkboxes_title {
  font-weight: 600;
}

.info-box-yellow {
  background-color: #FFFFCC;
  opacity: 34;
  border: 15px solid black;
  border-width: 1px;
  padding: 15px;
}

.color-red {
  color: #CC0000;
}

.font-weight-600 {
  font-weight: 600;
}

@media screen {
  .vertical-tabs__container .vertical-tabs {
    flex: none;
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media screen {
  .vertical-tabs__container .vertical-tabs li {
    margin: 0 0 16px 0;
  }
}
@media screen {
  .vertical-tabs__container .vertical-tabs li:last-child {
    margin: 0;
  }
}
@media screen {
  .vertical-tabs__container .vertical-tabs li a {
    color: #00365f;
    height: 48px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    -webkit-box-shadow: inset 0 0 0 1px #efefef;
    -moz-box-shadow: inset 0 0 0 1px #efefef;
    -ms-box-shadow: inset 0 0 0 1px #efefef;
    box-shadow: inset 0 0 0 1px #efefef;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .vertical-tabs__container .vertical-tabs li a.is-active {
    color: #fff;
    background-color: #00365f;
    -webkit-box-shadow: inset 0 0 0 1px #00365f;
    -moz-box-shadow: inset 0 0 0 1px #00365f;
    -ms-box-shadow: inset 0 0 0 1px #00365f;
    box-shadow: inset 0 0 0 1px #00365f;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media screen {
  .vertical-tabs__container .vertical-tabs__panel {
    justify-content: flex-start;
  }
}

@media screen {
  .panel {
    display: flex;
    flex-direction: column;
    border: solid 1px #cccccc;
  }
}
@media screen {
  .panel:not(:only-of-type) {
    margin: 0 0 16px;
  }
}
@media screen {
  .panel:not(:only-of-type):last-child {
    margin: 0;
  }
}
@media screen {
  .panel .panel__head {
    flex: none;
    height: 46px;
    font-size: 16px;
    line-height: 46px;
    font-weight: 700;
    padding: 0 16px;
    border-bottom: solid 1px #cccccc;
    color: #000;
    background-color: #efefef;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }
}
@media screen {
  .panel .panel__head .panel__end {
    margin: 0 0 0 auto;
  }
}
.panel .panel__head .panel__end .pill {
  padding: 5px 10px;
  background: #fff;
}
.panel .panel__head .panel__end .pill.sub-component {
  display: inline;
}
.panel .panel__head .panel__end .pill.green {
  color: #415e18;
}
.panel .panel__head .panel__end .pill.blue {
  color: #4c728f;
}
.panel .panel__head .panel__end .pill.white {
  color: #999;
}
@media screen {
  .panel .panel__body {
    padding: 16px;
  }
}

@media screen {
  .data-set__container {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .data-set__container .data-set {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
  }
  .data-set__container .data-set.second {
    flex: none;
  }
}
@media screen {
  .data-set__container .data-set span {
    text-align: left;
    padding: 0 0 0 8px;
    word-break: break-all;
  }
}
@media screen {
  .data-set__container .data-set span.data-label {
    text-align: right;
    padding: 0 8px 0 0;
  }
}
.data-set__container .data-set span.data-label button {
  margin-top: -10px;
  margin-left: 10px;
}
.data-set__container .data-set span.red-value {
  color: #da1a31;
}

@media screen {
  .address__card .address__head {
    margin: 0 0 32px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media screen {
  .address__card .address__body {
    margin: 0 0 32px 0;
  }
}
@media screen {
  .address__card .address__body .address {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 32px 0;
  }
}
@media screen {
  .address__card .address__maps {
    width: 100%;
    height: 200px;
  }
}

@media screen {
  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
    background-color: #efefef;
    background-image: linear-gradient(to right, #ccc 70%, transparent 30%), linear-gradient(to left, #ccc 70%, transparent 30%), linear-gradient(to bottom, #ccc 70%, transparent 30%), linear-gradient(to top, #ccc 70%, transparent 30%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
    cursor: pointer;
    padding: 32px;
  }
}
@media screen {
  .dropzone .material-icons-outlined {
    color: #999;
    font-size: 72px;
    margin: 0 auto;
  }
}
@media screen {
  .dropzone p {
    color: #999;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
@media screen {
  .dropzone .btn {
    margin: auto 0 0 0;
  }
}

@media screen {
  .upload__item {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .upload__item .upload__zone {
    flex: none;
    width: 350px;
  }
}
@media screen {
  .upload__item .upload__details {
    flex-grow: 1;
    margin: 0 16px;
  }
}
@media screen {
  .upload__item .upload__details .pn__details {
    flex-grow: 1;
    margin: 0 10px;
  }
}
@media screen {
  .upload__item .upload__details .upload__output .upload__output__title {
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .upload__item .upload__details .upload__output .upload_item {
    margin: 0 0 16px 0p;
  }
}
@media screen {
  .upload__item .upload__details .upload__output .upload_item:last-child {
    margin: 0;
  }
}
@media screen {
  .upload__item .upload__details .upload__output figure {
    width: 82px;
    height: 82px;
    margin: 0 0 8px 0;
  }
}
@media screen {
  .upload__item .upload__details .upload__output figure:last-child {
    margin: 0;
  }
}
@media screen {
  .upload__item .upload__details .upload__output figure.large {
    width: 150px;
    height: 62px;
  }
}
@media screen {
  .upload__item .upload__details .upload__output figure img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
@media screen {
  .upload__item .upload__controls {
    flex: none;
    width: 80px;
  }
}
@media screen {
  .upload__item .upload__controls.list {
    width: auto;
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .upload__item .upload__controls.list .btn:not(:last-child) {
    margin: 0 16px 0 0;
  }
}

@media screen {
  .upload__title {
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}

@media screen {
  .upload__requirements {
    color: #999;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .upload__requirements li {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen {
  .upload__label,
  .image__name {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media screen {
  .upload__label:not(:first-of-type),
  .image__name:not(:first-of-type) {
    margin: 16px 0 0 0;
  }
}

@media screen {
  .image__name {
    margin: 0 0 16px 0;
  }
}

@media screen {
  .upload__url {
    color: #00365f;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    word-break: break-all;
  }
}

@media screen {
  .upload__info {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    word-break: break-all;
  }
}

@media screen {
  .content__container h4 {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .content__container .content.scrollable {
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media screen {
  .content__container p {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
  }
}

@media screen {
  .inline__tabs {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .inline__tabs li:not(:first-child) {
    margin: 0 0 0 -1px;
  }
}
@media screen {
  .inline__tabs li a {
    color: #cccccc;
    font-size: 14px;
    line-height: 38px;
    font-weight: 700;
    min-width: 100px;
    display: block;
    text-align: center;
    text-decoration: none;
    border: solid 1px #efefef;
  }
}
@media screen {
  .inline__tabs li a.is-active {
    color: #fff;
    background-color: #00365f;
  }
}

@media screen {
  .react-tooltip__trigger {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen {
  .__react_component_tooltip {
    border-radius: 0;
  }
}
@media screen {
  .__react_component_tooltip.small {
    width: 160px;
  }
}
@media screen {
  .__react_component_tooltip.type-light {
    background-color: #efefef;
    opacity: 1;
  }
}
@media screen {
  .__react_component_tooltip.type-light.place-top::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #efefef transparent transparent transparent;
  }
}
@media screen {
  .__react_component_tooltip.type-white {
    background-color: #fff;
    opacity: 1;
    width: 520px !important;
  }
}
@media screen {
  .__react_component_tooltip.type-white.place-top::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #fff transparent transparent transparent;
  }
}
@media screen {
  .__react_component_tooltip.default-image__tooltip {
    white-space: normal;
    font-weight: 400;
    border: 1px solid #cccccc;
    height: 30px;
    width: 480px !important;
    padding-top: 0px;
    padding-bottom: 45px;
    text-align: center;
  }
}
@media screen {
  .__react_component_tooltip.table-head__tooltip {
    white-space: normal;
    font-weight: 400;
    width: 480px;
  }
}
@media screen {
  .__react_component_tooltip.table-head__tooltip strong {
    font-weight: 700;
  }
}
@media screen {
  .__react_component_tooltip.table-head__tooltip.extra_strong {
    font-weight: 800;
  }
}
.__react_component_tooltip.table-head__tooltip.auto-width-tooltip {
  width: auto !important;
  max-width: 480px;
}
@media screen {
  .__react_component_tooltip.auto-size-opaque_tooltip {
    width: auto;
    max-width: 480px;
    opacity: 0.95 !important;
    transition: 0s;
    white-space: normal;
  }
}

@media screen {
  .badges__container {
    display: flex;
    flex-direction: row;
    margin: 24px 0 0 0;
  }
}
@media screen {
  .badges__container .badge-list .col {
    margin: 0 0 24px 0;
  }
}
@media screen {
  .badges__container .badge-list .col:nth-last-child(-n+6) {
    margin: 0;
  }
}

@media screen {
  .badge__label {
    margin: 16px 0 0 0;
    color: #999;
  }
}

@media screen {
  .badge {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  .badge.is-active {
    position: relative;
    border: 5px solid white;
  }
}
.badge.is-active:before {
  content: "";
  width: 110px;
  height: 110px;
  position: absolute;
  z-index: 0;
  left: -10px;
  top: -10px;
  background-image: url(../img/border.svg);
  background-size: 100px 100px;
}
@media screen {
  .badge.is-active:after {
    position: relative;
    z-index: 8;
    content: "done";
    font-family: "Material Icons Outlined";
    font-size: 32px;
  }
}
@media screen {
  .badge.color-00365F {
    background-color: #00365F;
  }
}
@media screen {
  .badge.color-00365F.is-active {
    box-shadow: 0 0 0 5px #00365F;
  }
}
@media screen {
  .badge.color-10644B {
    background-color: #10644B;
  }
}
@media screen {
  .badge.color-10644B.is-active {
    box-shadow: 0 0 0 5px #10644B;
  }
}
@media screen {
  .badge.color-5E7331 {
    background-color: #5E7331;
  }
}
@media screen {
  .badge.color-5E7331.is-active {
    box-shadow: 0 0 0 5px #5E7331;
  }
}
@media screen {
  .badge.color-2592A8 {
    background-color: #2592A8;
  }
}
@media screen {
  .badge.color-2592A8.is-active {
    box-shadow: 0 0 0 5px #2592A8;
  }
}
@media screen {
  .badge.color-363893 {
    background-color: #363893;
  }
}
@media screen {
  .badge.color-363893.is-active {
    box-shadow: 0 0 0 5px #363893;
  }
}
@media screen {
  .badge.color-023971 {
    background-color: #023971;
  }
}
@media screen {
  .badge.color-023971.is-active {
    box-shadow: 0 0 0 5px #023971;
  }
}
@media screen {
  .badge.color-0C63B9 {
    background-color: #0C63B9;
  }
}
@media screen {
  .badge.color-0C63B9.is-active {
    box-shadow: 0 0 0 5px #0C63B9;
  }
}
@media screen {
  .badge.color-625143 {
    background-color: #625143;
  }
}
@media screen {
  .badge.color-625143.is-active {
    box-shadow: 0 0 0 5px #625143;
  }
}
@media screen {
  .badge.color-948354 {
    background-color: #948354;
  }
}
@media screen {
  .badge.color-948354.is-active {
    box-shadow: 0 0 0 5px #948354;
  }
}
@media screen {
  .badge.color-000000 {
    background-color: #000000;
  }
}
@media screen {
  .badge.color-000000.is-active {
    box-shadow: 0 0 0 5px #000000;
  }
}
@media screen {
  .badge.color-6F6F6F {
    background-color: #6F6F6F;
  }
}
@media screen {
  .badge.color-6F6F6F.is-active {
    box-shadow: 0 0 0 5px #6F6F6F;
  }
}
@media screen {
  .badge.color-C45200 {
    background-color: #C45200;
  }
}
@media screen {
  .badge.color-C45200.is-active {
    box-shadow: 0 0 0 5px #C45200;
  }
}
@media screen {
  .badge.color-970E16 {
    background-color: #970E16;
  }
}
@media screen {
  .badge.color-970E16.is-active {
    box-shadow: 0 0 0 5px #970E16;
  }
}

@media screen {
  .action__list {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .action__list li {
    margin: 0 8px 0 0;
  }
}
@media screen {
  .action__list li:last-child {
    margin: 0;
  }
}

.newsletter_html_editor .fr-wrapper * {
  max-width: 100% !important;
  width: auto !important;
}

.content_preview * {
  max-width: 100%;
}
@media screen {
  .content_preview h2 {
    color: #000;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .content_preview ul {
    list-style: inside disc;
    margin: 0 0 16px 16px;
  }
}

@media screen {
  .status-state {
    font-size: 16px;
    line-height: 100%;
    font-weight: 500;
    text-align: center;
  }
}
@media screen {
  .status-state.active {
    color: #415e18;
  }
}
@media screen {
  .status-state.inactive {
    color: #C9243F;
  }
}
@media screen {
  .status-state.info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .status-state.info .material-icons {
    font-size: 14px;
    margin: 0 8px 0 0;
  }
}

@media screen {
  .golfer-data-box__container {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box {
    margin: 0 0 16px 0;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box .data-box__title {
    color: #000;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    background-color: #efefef;
    border: solid 1px #cccccc;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box .data-box__body {
    padding: 16px;
    border: solid 1px #cccccc;
    border-top: none;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box .data-box__body span {
    text-align: center;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box .data-box__body span.large {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 8px 0;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box .data-box__body span.spaced-item {
    margin: 16px 0 0 0;
  }
}
@media screen {
  .golfer-data-box__container .golfer-data-box.blank .data-box__body {
    padding: 0;
    border: none;
  }
}

@media screen {
  .pull-data__golfer-name,
  .pull-data__golfer-handicap-index {
    line-height: 20px;
  }
}
@media screen {
  .pull-data__golfer-name .cardinal,
  .pull-data__golfer-handicap-index .cardinal {
    color: #C9243F;
  }
}

@media screen {
  .image-preview__url {
    color: #999;
    word-break: break-all;
  }
}

@media screen {
  .pre-input__container {
    display: flex;
    flex-direction: row;
  }
}

@media screen {
  .pre-input {
    line-height: 40px;
    margin: 0 8px 0 0;
  }
}

.history__chart {
  overflow-x: scroll;
  padding-bottom: 10px;
}
.history__chart .recharts-bar {
  cursor: pointer;
}

.smart_checkbox {
  position: relative;
}
.smart_checkbox .checkbox {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 2px;
  margin: 0 8px 0 0;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: 12px 12px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+);
  will-change: background-color;
  -webkit-transition: all 300ms ease-in-out 0ms;
  -moz-transition: all 300ms ease-in-out 0ms;
  -ms-transition: all 300ms ease-in-out 0ms;
  transition: all 300ms ease-in-out 0ms;
}
.smart_checkbox .checkbox.checked {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+) !important;
  background-color: #00365f;
  border-color: #00365f;
}
.smart_checkbox .select {
  width: 200px;
  position: absolute;
  left: 20px;
  top: 20px;
  background: #fff;
  padding: 5px 0;
  border-radius: 5px;
  color: #000;
  z-index: 1;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}
.smart_checkbox .select .option {
  padding: 10px;
  cursor: pointer;
}
.smart_checkbox .select .option:hover {
  background: #efefef;
}
.smart_checkbox .exit_select {
  width: 100vw;
  height: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 0;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
}

.date-range-dropdown {
  z-index: 99;
}
.date-range-dropdown .date-range-dropdown-trigger {
  width: 100%;
  background-color: #fff;
  border: solid 1px #cccccc;
  font-size: 16px;
  line-height: 24px;
  padding: 7px 8px;
  display: block;
  height: 40px;
}
.date-range-dropdown .date-range-dropdown-trigger:hover {
  border: 1px solid #999;
}
.date-range-dropdown .date-range-dropdown-trigger:focus {
  border: 1px solid #000;
}
.date-range-dropdown .date-range-dropdown-trigger:after {
  border: none;
  content: "expand_more";
  font-family: "Material Icons Outlined";
  pointer-events: none;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  height: 24px;
  display: block;
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}
.date-range-dropdown .dropdown__content {
  margin-top: -9px;
  width: 400px;
  padding: 0px;
  right: inherit;
  border-radius: 2px;
  background: white;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.date-range-dropdown .dropdown__content::before {
  visibility: hidden;
}

.dropdown_overlay {
  z-index: 98;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.middle {
  margin: auto;
}

.light-text {
  line-height: 12px;
  font-weight: 300;
  font-size: 12px;
}

.regular-text {
  line-height: 14px;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
}

.full-width {
  width: 100%;
  margin-left: 10px;
  margin-top: 5px;
}

.full-width-no-lef-margin {
  width: 100%;
  margin-top: 5px;
}

.afs-title {
  font-weight: 700;
  margin-bottom: 10px;
}

.afs-data {
  line-height: 18px;
}

.afs-info-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.prorated_fees_title {
  font-size: 18px;
  margin-bottom: 20px;
}

.add_prorated_fee_btn {
  margin-top: 14px;
  padding: 0 !important;
}

.prorated_fees .prorated_fee:last-child {
  margin-bottom: 16px;
}

.prorated_fees .prorated_fee:first-child {
  margin-top: 22px !important;
}

.prorated_fee {
  margin-top: 0 !important;
}

.horizontal_rule {
  border: 0.5px solid #cccccc;
  margin-top: 25px;
  margin-bottom: 25px;
}

.scissors-icon-container {
  background-image: url("../img/scissors.svg");
  background-size: cover;
  width: 18px;
  height: 18px;
  cursor: default !important;
}

.prorated-amount {
  margin-left: 3px;
}

.prorated-codes-dropdown {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: end;
}

.no-margin-top {
  margin-top: 0 !important;
}

.table-auto .data__table {
  table-layout: auto;
}

.table-auto .data__table thead tr th {
  width: auto;
}

.terms-and-privacy-title {
  font-weight: 700;
}

.terms-and-privacy-mt {
  margin-top: 10px;
}

.terms-and-privacy-url {
  word-break: break-all;
}

.lds-ring {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  margin-top: auto;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 19px 0px 0px 19;
  border: 2px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}

.lds-ring.white div {
  border: 2px solid white !important;
  border-color: white transparent transparent transparent !important;
}

.mr-5 {
  margin-right: 5px;
}

.aged-out-spinner-position {
  position: absolute;
  padding-top: 6px;
  margin-right: 45px;
}

.lds-ring.test {
  width: unset;
  height: unset;
  margin-bottom: unset;
  margin-top: unset;
}

.lds-ring.test div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  margin: 23px 0px 0px 254px;
  border: 2px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.handicap_review_details {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  margin: 0.5rem;
  padding: 0.5rem 1rem;
}

.handicap_review_modal_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.handicap_review_modal_title_item {
  font-size: x-large;
  color: #fff;
  margin-right: 1rem;
}

.vertical_divider {
  border-left: 1px solid #38546d;
  border-right: 1px solid #16222c;
  height: 80px;
  right: 249px;
  top: 10px;
}

.bubble {
  border-radius: 0.7rem;
  padding: 0.1rem;
  min-width: 3.2rem;
  max-height: 29px;
  width: fit-content;
  font-size: 12px;
}

.percent_bubble {
  color: rgb(94, 140, 170);
  background-color: rgb(233, 235, 245);
}

.low_bubble {
  color: white;
  background-color: rgb(19, 54, 92);
}

.high_bubble {
  color: white;
  background-color: rgb(56, 137, 203);
}

.recommended_adjustment_symbol-pending {
  color: rgb(233, 160, 83);
  background-color: rgb(254, 240, 199);
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.7rem;
  padding: 0.3rem !important;
}

.recommended_adjustment_symbol-applied {
  color: rgb(72, 165, 221);
  background-color: rgb(236, 248, 255);
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.7rem;
  padding: 0.3rem !important;
}

.recommended_adjustment_symbol-denied {
  color: rgb(229, 84, 58);
  background-color: rgb(254, 214, 199);
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.7rem;
  padding: 0.3rem !important;
}

.recommended_adjustment_symbol-zero {
  color: rgb(233, 160, 83);
  background-color: rgb(209, 250, 223);
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.7rem;
  padding: 0.3rem !important;
}

.handicap_review_status_panel-applied {
  border-radius: 1.5rem;
  padding: 0 0.6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(29, 186, 112);
  background-color: rgb(235, 254, 243);
}

.handicap_review_status_panel-denied {
  border-radius: 1.5rem;
  padding: 0 0.6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(229, 84, 58);
  background-color: rgb(254, 214, 199);
}

.status_point {
  font-size: 3rem;
  padding: none;
  padding-bottom: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0.75rem;
}

.details_panel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0.2rem;
}

.deny_button {
  color: #415e18;
  background-color: #fff;
  border: solid #415e18 !important;
}

.text_percent {
  font-weight: 700;
}

.text_value {
  color: rgb(0, 40, 81);
  font-size: 2rem;
  font-weight: 700;
}

.text_rounds {
  color: rgb(0, 40, 81);
  font-weight: 700;
}

.text_title {
  color: black;
  font-size: 18px;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
}

.padding_small {
  padding: 0.1rem 0 !important;
  margin: 0.1rem 0 !important;
}

.text_subtitle {
  color: gray;
  font-size: x-large;
}

.chart_cards {
  padding: 0.8rem 0;
}

.text_info {
  color: gray;
  font-size: large;
  line-height: 13px;
}

.bullet_low {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.2rem;
  background-color: rgb(19, 54, 92);
}

.bullet_high {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.2rem;
  background-color: rgb(56, 137, 203);
}

.text_field {
  color: rgb(100, 100, 100);
  font-size: 1.3rem;
}

.text_note {
  font-size: 14px;
  font-style: italic;
  margin-top: 0.7rem !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header_cell {
  color: #fff;
  background-color: #00365f;
  font-weight: 700;
  font-size: 14px;
  height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.sub_header_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.sub_header_cell {
  background-color: rgb(247, 247, 247);
  font-size: 12px;
  width: 100%;
  height: 2.5rem;
  border: solid 1px #efefef;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.simple_cells_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.simple_cell {
  background-color: white;
  width: 100%;
  height: 2.5rem;
  border: solid 1px #efefef;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen {
  input[type=text],
  input[type=password],
  input[type=mail],
  input[type=number],
  .DayPickerInput input {
    width: 100%;
    background-color: #fff;
    border: solid 1px #cccccc;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 8px;
    display: block;
    height: 40px;
  }
}
input[type=text].small,
input[type=password].small,
input[type=mail].small,
input[type=number].small,
.DayPickerInput input.small {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 5px;
  margin: 0 10px;
}
input[type=text].large,
input[type=password].large,
input[type=mail].large,
input[type=number].large,
.DayPickerInput input.large {
  width: 650px;
  height: 150px;
  text-align: left;
  padding: 5px;
  margin: 0 10px;
  display: inline-block;
}
input[type=text].is-error,
input[type=password].is-error,
input[type=mail].is-error,
input[type=number].is-error,
.DayPickerInput input.is-error {
  border: solid 1px #da1a31;
}
@media screen {
  input[type=text]:hover,
  input[type=password]:hover,
  input[type=mail]:hover,
  input[type=number]:hover,
  .DayPickerInput input:hover {
    border-color: #999;
  }
}
@media screen {
  input[type=text]:focus,
  input[type=password]:focus,
  input[type=mail]:focus,
  input[type=number]:focus,
  .DayPickerInput input:focus {
    border: solid 1px #000;
    outline: none;
  }
}
@media screen {
  input[type=text].search,
  input[type=password].search,
  input[type=mail].search,
  input[type=number].search,
  .DayPickerInput input.search {
    padding-right: 30px;
  }
}
@media screen {
  input[type=text]::-webkit-input-placeholder,
  input[type=password]::-webkit-input-placeholder,
  input[type=mail]::-webkit-input-placeholder,
  input[type=number]::-webkit-input-placeholder,
  .DayPickerInput input::-webkit-input-placeholder {
    opacity: 0.5;
  }
  input[type=text]:-moz-placeholder,
  input[type=password]:-moz-placeholder,
  input[type=mail]:-moz-placeholder,
  input[type=number]:-moz-placeholder,
  .DayPickerInput input:-moz-placeholder {
    opacity: 0.5;
  }
  input[type=text]::-moz-placeholder,
  input[type=password]::-moz-placeholder,
  input[type=mail]::-moz-placeholder,
  input[type=number]::-moz-placeholder,
  .DayPickerInput input::-moz-placeholder {
    opacity: 0.5;
  }
  input[type=text]:-ms-input-placeholder,
  input[type=password]:-ms-input-placeholder,
  input[type=mail]:-ms-input-placeholder,
  input[type=number]:-ms-input-placeholder,
  .DayPickerInput input:-ms-input-placeholder {
    opacity: 0.5;
  }
}
@media screen {
  input[type=text]:disabled,
  input[type=password]:disabled,
  input[type=mail]:disabled,
  input[type=number]:disabled,
  .DayPickerInput input:disabled {
    background-color: rgba(239, 239, 239, 0.5);
  }
}
input[type=text]:disabled:hover, input[type=text]:disabled:focus,
input[type=password]:disabled:hover,
input[type=password]:disabled:focus,
input[type=mail]:disabled:hover,
input[type=mail]:disabled:focus,
input[type=number]:disabled:hover,
input[type=number]:disabled:focus,
.DayPickerInput input:disabled:hover,
.DayPickerInput input:disabled:focus {
  border: solid 1px #cccccc;
}

@media screen {
  input[type=password] {
    font-size: 32px;
    line-height: 24px;
    max-height: 48px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #00365f;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

@media screen {
  .DayPickerInput {
    position: relative;
  }
}
@media screen {
  .DayPickerInput::after {
    content: "calendar_today";
    display: block;
    font-family: "Material Icons Outlined";
    font-size: 16px;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

@media screen {
  .sub-input__info {
    color: #999;
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen {
  input[type=checkbox] {
    display: none;
  }
}
@media screen {
  input[type=checkbox].small + label {
    line-height: 24px;
  }
}
@media screen {
  input[type=checkbox] + label {
    color: #00365f;
    font-size: 16px;
    line-height: 40px;
    margin: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  input[type=checkbox] + label::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: solid 1px #999;
    border-radius: 2px;
    margin: 0 8px 0 0;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 12px 12px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+);
    will-change: background-color;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  input[type=checkbox] + label:empty {
    width: 18px;
    height: 18px;
  }
}
@media screen {
  input[type=checkbox] + label:empty::before {
    margin: 0;
  }
}
input[type=checkbox]:disabled + label {
  pointer-events: none;
  color: #acacac;
}
@media screen {
  input[type=checkbox]:disabled + label::before {
    background-image: none;
    background-color: #acacac !important;
  }
}
@media screen {
  input[type=checkbox]:checked + label::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+) !important;
    background-color: #00365f;
    border-color: #00365f;
  }
}

@media screen {
  .option input[type=checkbox] {
    display: inline-block;
  }
}

.fr-checkbox-line input[type=checkbox] {
  display: inline-block;
}
.fr-checkbox-line label {
  display: inline-block;
}

.center-checkbox {
  flex-direction: row !important;
  align-items: end;
}

@media screen {
  .f-row {
    display: flex;
    flex-direction: row;
    margin: 0 0 32px 0;
  }
}
@media screen {
  .f-row:last-child {
    margin: 0;
  }
}
@media screen {
  .f-row .f-col {
    width: 100%;
  }
}
@media screen {
  .f-row .f-col.justify-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media screen {
  .f-row .f-col.auto {
    flex: 1;
  }
}
@media screen {
  .f-row .f-col.auto.text-align-right {
    text-align: right;
  }
}

@media screen {
  .f-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen {
  .f-paragraph:only-of-type {
    margin: 0;
  }
}
@media screen {
  .f-paragraph:not(:only-of-type) {
    margin: 0 0 24px 0;
  }
}

@media screen {
  .btn {
    width: auto;
    height: 40px;
    border: none;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    font-family: "National", sans-serif;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none !important;
    padding: 0 16px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
}
.btn.label {
  justify-content: flex-start;
  padding: 0;
  text-align: left;
}
.btn.label span {
  text-decoration: underline;
  color: initial;
}
@media screen {
  .btn .material-icons-outlined {
    width: 24px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0 0 0 8px;
  }
}
@media screen {
  .btn .material-icons-outlined.no-margin {
    margin: 0;
  }
}
@media screen {
  .btn.align-to-inputs {
    margin-top: 24px;
  }
}
@media screen {
  .btn.full, .btn:only-of-type {
    width: 100%;
  }
}
@media screen {
  .btn.large {
    font-size: 24px;
    line-height: 60px;
    height: 60px;
  }
}
.btn.small {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}
@media screen {
  .btn.round {
    width: 40px;
    border-radius: 50%;
    font-size: 50px;
    padding-bottom: 6px;
    color: white;
    font-weight: 700;
  }
}
.btn.fill {
  text-decoration: none !important;
}
@media screen {
  .btn.fill.blue {
    color: #fff;
    background-color: #1D87FF;
  }
}
@media screen {
  .btn.fill.blue:hover {
    background-color: #00365f;
  }
}
@media screen {
  .btn.fill.another-blue {
    color: #fff;
    background-color: #1b9bd5;
  }
}
@media screen {
  .btn.fill.another-blue:hover {
    background-color: #00365f;
  }
}
@media screen {
  .btn.fill.gray {
    color: #fff;
    background-color: #acacac;
  }
}
@media screen {
  .btn.fill.gray:hover {
    background-color: rgb(127.5, 127.5, 127.5);
  }
}
@media screen {
  .btn.fill.green {
    color: #fff;
    background-color: #415e18;
    font-size: 14px;
  }
}
@media screen {
  .btn.fill.green:hover {
    background-color: rgb(50.9533898305, 73.686440678, 18.813559322);
  }
}
@media screen {
  .btn.fill.red {
    color: #fff;
    background-color: #C9243F;
  }
}
@media screen {
  .btn.fill.red:hover {
    background-color: rgb(179.3734177215, 32.1265822785, 56.2215189873);
  }
}
@media screen {
  .btn.fill.another-red {
    color: #fff;
    background-color: #CC0000;
  }
}
@media screen {
  .btn.fill.another-red:hover {
    background-color: rgb(178.5, 0, 0);
  }
}
@media screen {
  .btn.fill.orange {
    color: #fff;
    background-color: #FF9900;
  }
}
@media screen {
  .btn.fill.orange:hover {
    background-color: rgb(229.5, 137.7, 0);
  }
}
@media screen {
  .btn.loading {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/loader.svg");
  }
}
@media screen {
  .btn.empty {
    background-color: transparent;
    color: #1D87FF;
    padding: 0;
  }
}
@media screen {
  .btn.empty:hover {
    color: rgb(3.5, 121.4601769912, 255);
  }
}
@media screen {
  .btn.outline.gray {
    -webkit-box-shadow: inset 0 0 0 2px #CECECE;
    -moz-box-shadow: inset 0 0 0 2px #CECECE;
    -ms-box-shadow: inset 0 0 0 2px #CECECE;
    box-shadow: inset 0 0 0 2px #CECECE;
    background-color: transparent;
  }
}
@media screen {
  .btn.outline.gray:hover {
    background-color: #CECECE;
  }
}
@media screen {
  .btn.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}
@media screen {
  .btn.lnk {
    width: auto;
    max-height: 20px;
    padding: 0;
    color: #999;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #999;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    background-color: transparent;
    text-decoration: none !important;
    display: inline-block;
  }
}
.btn.lnk.white {
  color: #fff;
}
@media screen {
  .btn.lnk:hover {
    color: #000;
    border-color: #000;
  }
}
.btn.lnk.clean {
  text-transform: none;
  border: none;
}
@media screen {
  .btn.lnk.footer_lnk {
    margin: 0 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: block;
    color: #00365f;
    text-decoration: none;
    border-bottom: none;
  }
}
@media screen {
  .btn.lnk.footer_lnk:hover {
    color: #00365f;
  }
}
@media screen {
  .btn.lnk.red {
    color: #C9243F;
    border-bottom: solid 1px #C9243F;
  }
}
@media screen {
  .btn.lnk.red:hover {
    color: rgb(179.3734177215, 32.1265822785, 56.2215189873);
    border-bottom: solid 1px rgb(179.3734177215, 32.1265822785, 56.2215189873);
  }
}
@media screen {
  .btn.search {
    flex: none;
    border-radius: 50%;
    color: #fff;
    background-color: #C9243F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: -5px 0 0 -25px;
    position: relative;
    z-index: 9;
  }
}
@media screen {
  .btn.search .material-icons-outlined {
    margin: 0;
  }
}
@media screen {
  .btn.addr {
    height: 64px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}
@media screen {
  .btn.addr .material-icons-outlined {
    font-size: 32px;
    margin: 0 4px 0 0;
  }
}
@media screen {
  .btn.addr .material-icons-outlined:last-of-type:not(:only-of-type) {
    margin: 0;
  }
}
@media screen {
  .btn.addr .long__string {
    flex-shrink: 1;
    text-align: left;
    margin: 0 0 0 4px;
  }
}
@media screen {
  .btn:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
@media screen {
  .btn.inline-tab {
    color: #cccccc;
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    line-height: 38px;
    font-weight: 700;
    min-width: 100px;
    display: block;
    text-align: center;
    text-decoration: none;
    border: solid 1px #efefef;
  }
}
@media screen {
  .btn.inline-tab.is-active {
    color: #fff;
    background-color: #00365f;
  }
}

a.btn.lnk {
  text-transform: initial !important;
  border: 0;
}

@media screen {
  label,
  .label {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin: 0 0 8px 0;
    display: block;
    white-space: nowrap;
  }
}
label.disabled,
.label.disabled {
  opacity: 0.5;
  pointer-events: none;
}
label.absolute-centered,
.label.absolute-centered {
  position: absolute;
  right: -5px;
  top: 14px;
}
@media screen {
  label span,
  .label span {
    color: #C9243F;
  }
}
label.is-error,
.label.is-error {
  border: solid 1px #da1a31;
}
label.length-error,
.label.length-error {
  color: #da1a31;
}
label.blue,
.label.blue {
  color: #1D87FF;
}

@media screen {
  .data-label {
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin: 0;
    display: block;
    white-space: nowrap;
  }
}

@media screen {
  .form-link {
    font-size: 16px;
    line-height: 24px;
    color: #1D87FF;
    border-bottom: solid 1px #1D87FF;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  .form-link:hover {
    color: #00365f;
    border-color: #00365f;
  }
}

@media screen {
  .form__title {
    color: #00365f;
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
  }
}

.form_box {
  background: #efefef;
  padding: 24px 32px;
  width: 100%;
  margin: auto;
}

@media screen {
  .form__description {
    color: #00365f;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
  }
}

@media screen {
  .form__separator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 32px;
    text-transform: uppercase;
    position: relative;
    opacity: 0.25;
    margin: auto 0 0 0;
  }
}
@media screen {
  .form__separator::before {
    content: "";
    height: 2px;
    width: 100%;
    display: block;
    background-color: #999;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}
@media screen {
  .form__separator.or::after {
    content: "Or";
    display: block;
    height: 100%;
    padding: 0 8px;
    color: #000;
    background-color: #fff;
    z-index: 9;
    position: relative;
  }
}
@media screen {
  .form__separator.or.small::before {
    display: none;
  }
}
@media screen {
  .form__separator.or.small::after {
    color: #000;
    font-size: 18px;
    padding: 0;
  }
}
@media screen {
  .form__separator.arrow-right .material-icons-outlined {
    height: 40px;
    line-height: 40px;
  }
}
@media screen {
  .form__separator.arrow-right::after {
    display: none;
  }
}
@media screen {
  .form__separator.arrow-right::before {
    display: none;
  }
}

.react-select-container.width-400 .react-select__menu {
  width: 400px;
}
.react-select-container.overflow-y-cls .react-select__menu-list {
  overflow-y: inherit !important;
}
.react-select-container.small {
  width: 120px;
  margin-left: 10px;
}
@media screen {
  .react-select-container .react-select__control {
    height: 40px;
    border-radius: 0;
    border-color: #cccccc;
  }
}
@media screen {
  .react-select-container .react-select__control:hover {
    border-color: #999;
  }
}
@media screen {
  .react-select-container .react-select__control:focus {
    border-color: #000;
  }
}
@media screen {
  .react-select-container .react-select__control.react-select__control--is-focused {
    height: 40px;
    border: solid 1px #000;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }
}
@media screen {
  .react-select-container .react-select__control .react-select__value-container {
    height: 100%;
    max-height: 100%;
  }
}
@media screen {
  .react-select-container .react-select__control .react-select__value-container .react-select__placeholder {
    color: #000;
  }
}
@media screen {
  .react-select-container .react-select__control .react-select__value-container .react-select__input {
    position: absolute;
    transform-origin: center center;
    top: 0;
  }
}
@media screen {
  .react-select-container .react-select__control .react-select__indicators .react-select__indicator-separator {
    display: none;
  }
}
@media screen {
  .react-select-container .react-select__control .react-select__indicators .react-select__dropdown-indicator::after {
    border: none;
    content: "expand_more";
    font-family: "Material Icons Outlined";
    pointer-events: none;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
@media screen {
  .react-select-container .react-select__control .react-select__indicators .react-select__dropdown-indicator svg {
    display: none;
  }
}
.react-select-container.async .react-select__dropdown-indicator {
  display: none !important;
}
.react-select-container.async .loadingIndicator {
  right: 0;
  margin-right: 0;
  padding-right: 0;
}
@media screen {
  .react-select-container .react-select__menu {
    z-index: 99;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
@media screen {
  .react-select-container .react-select__menu .react-select__menu-list .react-select__option {
    cursor: pointer;
    color: #000;
    background-color: #fff;
    white-space: pre-wrap;
  }
}
@media screen {
  .react-select-container .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-focused {
    background-color: #efefef;
  }
}
@media screen {
  .react-select-container.is-error .react-select__control {
    border-color: #C9243F;
  }
}

.react-select-container.small {
  width: 120px;
  margin-left: 10px;
}
@media screen {
  .react-select-container .react-gray-select__control {
    height: 40px;
    border-radius: 0;
    border-color: #cccccc;
  }
}
@media screen {
  .react-select-container .react-gray-select__control:hover {
    border-color: #999;
  }
}
@media screen {
  .react-select-container .react-gray-select__control:focus {
    border-color: #000;
  }
}
@media screen {
  .react-select-container .react-gray-select__control.react-gray-select__control--is-focused {
    height: 40px;
    border: solid 1px #000;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }
}
@media screen {
  .react-select-container .react-gray-select__control .react-gray-select__value-container {
    height: 100%;
    max-height: 100%;
  }
}
@media screen {
  .react-select-container .react-gray-select__control .react-gray-select__value-container .react-gray-select__placeholder {
    opacity: 0.5;
  }
}
@media screen {
  .react-select-container .react-gray-select__control .react-gray-select__value-container .react-gray-select__input {
    position: absolute;
    transform-origin: center center;
    top: 0;
  }
}
@media screen {
  .react-select-container .react-gray-select__control .react-gray-select__indicators .react-gray-select__indicator-separator {
    display: none;
  }
}
@media screen {
  .react-select-container .react-gray-select__control .react-gray-select__indicators .react-gray-select__dropdown-indicator::after {
    border: none;
    content: "expand_more";
    font-family: "Material Icons Outlined";
    pointer-events: none;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
@media screen {
  .react-select-container .react-gray-select__control .react-gray-select__indicators .react-gray-select__dropdown-indicator svg {
    display: none;
  }
}
.react-select-container.async .react-gray-select__dropdown-indicator {
  display: none !important;
}
.react-select-container.async .loadingIndicator {
  right: 0;
  margin-right: 0;
  padding-right: 0;
}
@media screen {
  .react-select-container .react-gray-select__menu {
    z-index: 99;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
@media screen {
  .react-select-container .react-gray-select__menu .react-gray-select__menu-list .react-gray-select__option {
    cursor: pointer;
    color: #000;
    background-color: #fff;
  }
}
@media screen {
  .react-select-container .react-gray-select__menu .react-gray-select__menu-list .react-gray-select__option.react-gray-select__option--is-focused {
    background-color: #efefef;
  }
}
@media screen {
  .react-select-container.is-error .react-gray-select__control {
    border-color: #C9243F;
  }
}

@media screen {
  .radio__container {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .radio__container input[type=radio].simple__radio {
    display: none;
  }
}
@media screen {
  .radio__container input[type=radio].simple__radio + label {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin: 0 16px 0 0;
    cursor: pointer;
  }
}
@media screen {
  .radio__container input[type=radio].simple__radio + label::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 16px 0 0;
    border-radius: 50%;
    border: solid 1px #CECECE;
    cursor: pointer;
    background-image: radial-gradient(#fff 0px, #fff 0px);
  }
}
@media screen {
  .radio__container input[type=radio].simple__radio:checked + label::before {
    background-image: radial-gradient(#415e18 8px, #fff 8px);
  }
}
.radio__container input[type=radio].simple__radio:disabled + label {
  pointer-events: none;
  opacity: 0.25;
}
.radio__container input[type=radio].simple__radio:disabled.full-opacity + label {
  opacity: 1;
}

@media screen {
  .badge-radio__container .badge-radio {
    margin: 0 0 16px 0;
  }
}
@media screen {
  .badge-radio__container .badge-radio input[type=radio] {
    display: none;
  }
}
@media screen {
  .badge-radio__container .badge-radio input[type=radio] + label {
    cursor: pointer;
  }
}
@media screen {
  .badge-radio__container .badge-radio input[type=radio] + label:after {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px auto 0;
    border-radius: 50%;
    border: solid 1px #CECECE;
    cursor: pointer;
    background-image: radial-gradient(#fff 0px, #fff 0px);
  }
}
@media screen {
  .badge-radio__container .badge-radio input[type=radio]:checked + label:after {
    background-image: radial-gradient(#415e18 8px, #fff 8px);
  }
}

@media screen {
  .custom-radio__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    -webkit-box-shadow: 0 0 0 1px #cccccc;
    -moz-box-shadow: 0 0 0 1px #cccccc;
    -ms-box-shadow: 0 0 0 1px #cccccc;
    box-shadow: 0 0 0 1px #cccccc;
  }
}
@media screen {
  .custom-radio__container input[type=radio] {
    display: none;
  }
}
@media screen {
  .custom-radio__container input[type=radio] + label {
    flex: 1 1 0;
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    margin: 0;
    border-right: solid 1px #cccccc;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
@media screen {
  .custom-radio__container input[type=radio] + label:last-child {
    border-right: none;
  }
}
@media screen {
  .custom-radio__container input[type=radio]:checked + label {
    color: #fff;
    background-color: #1D87FF;
  }
}
@media screen {
  .custom-radio__container input[type=radio]:disabled + label {
    opacity: 0.25;
    pointer-events: none;
  }
}

@media screen {
  textarea:disabled {
    background-color: rgba(239, 239, 239, 0.5);
  }
}
textarea:disabled:hover, textarea:disabled:focus {
  border: solid 1px #cccccc;
}
@media screen {
  textarea {
    resize: none;
    width: 100%;
    font-family: "National", sans-serif;
    min-height: 80px;
    background-color: #fff;
    border: solid 1px #cccccc;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 8px;
    display: block;
    height: 40px;
  }
}
textarea.large {
  width: 625px;
  height: 150px;
  text-align: left;
  padding: 5px;
  margin-top: -1.5%;
}
textarea.medium {
  width: 625px;
  height: 60;
  text-align: left;
  padding: 5px;
  margin-top: -1.5%;
}
textarea.large-alert {
  height: 150px;
  text-align: left;
  padding: 5px;
}
textarea.is-error {
  border: solid 1px #da1a31;
  color: #da1a31;
}
@media screen {
  textarea:hover {
    border-color: #999;
  }
}
@media screen {
  textarea:focus {
    border: solid 1px #000;
    outline: none;
  }
}

@media screen {
  input[type=text].is-error,
  input[type=password].is-error,
  input[type=mail].is-error,
  input[type=number].is-error {
    color: #C9243F;
    border-color: #C9243F;
    -webkit-animation: error 1s 1 forwards;
    -moz-animation: error 1s 1 forwards;
    -o-animation: error 1s 1 forwards;
    animation: error 1s 1 forwards;
  }
}
input[type=text].is-error:disabled,
input[type=password].is-error:disabled,
input[type=mail].is-error:disabled,
input[type=number].is-error:disabled {
  -webkit-animation: errorDisabled 1s 1 forwards;
  -moz-animation: errorDisabled 1s 1 forwards;
  -o-animation: errorDisabled 1s 1 forwards;
  animation: errorDisabled 1s 1 forwards;
}
@media screen {
  input[type=text].is-ok,
  input[type=password].is-ok,
  input[type=mail].is-ok,
  input[type=number].is-ok {
    border-color: #415e18;
  }
}

@media screen {
  .validation__message {
    font-size: 14px;
    line-height: 16px;
    display: block;
    padding: 4px 0;
  }
}
@media screen {
  .validation__message.is-error {
    color: #C9243F;
  }
}

@media screen {
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
  }
}
@media screen {
  .react-datepicker-wrapper .react-datepicker__input-container input {
    position: relative;
    padding-right: 48px;
  }
}
@media screen {
  .react-datepicker-wrapper .react-datepicker__input-container::after {
    content: "calendar_today";
    display: block;
    font-family: "Material Icons Outlined";
    font-size: 16px;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

@media screen {
  .react-datepicker-popper .react-datepicker {
    font-family: "National", sans-serif;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__triangle::before {
    border: none;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
    width: 24px;
    height: 24px;
    top: 10px !important;
    border: none;
    text-indent: 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous::before {
    display: block;
    color: #000;
    left: 10px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Material Icons Outlined";
    content: "chevron_left";
  }
}
.react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous span {
  display: none;
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next::before {
    display: block;
    color: #000;
    right: 10px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Material Icons Outlined";
    content: "chevron_right";
  }
}
.react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next span {
  display: none;
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
    padding: 10px 0 0 0;
    border-bottom: solid 1px #efefef;
    border-radius: 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
    display: none;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container {
    margin: 0 8px 0 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container,
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container {
    position: relative;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container select,
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container select {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    font-family: "National", sans-serif;
    font-size: 14px;
    line-height: 16px;
    padding: 4px 24px 4px 8px;
    background-color: #fff;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container::after,
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container::after {
    display: block;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-family: "Material Icons Outlined";
    content: "expand_more";
    position: absolute;
    right: 4px;
    top: 0;
    pointer-events: none;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
    font-size: 14px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    margin: 4px;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month {
    margin: 0;
    padding: 8px;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
    font-size: 14px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    margin: 4px;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--weekend {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--disabled {
    color: #999;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
    opacity: 1;
  }
}

.react-datepicker__day--selected {
  background-color: #1d5d90 !important;
}

.option-picker {
  padding: 15px;
  font-family: inherit;
}
.option-picker .first-title {
  font-weight: bold;
}
.option-picker .title {
  margin-top: 10px;
  font-weight: bold;
}
.option-picker .select-ranges-container {
  margin: 0px 0px;
}
.option-picker .select-ranges-container .line {
  margin-top: 12px;
  text-align: center;
  height: 40px;
}
.option-picker .select-ranges-container .range-labels {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 8px 0;
  display: block;
  white-space: nowrap;
}
.option-picker .group .btn-group button {
  cursor: pointer;
  float: bottom;
  border: none;
  width: 50%;
  background: none;
  font-size: 15px;
  color: #999;
  text-align: left;
  font-family: "National", sans-serif;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  font-weight: 400;
  padding: 0px;
}
.option-picker .group .btn-group button:hover {
  color: #00365f;
}
.option-picker .group .btn-group button.active {
  color: #00365f;
}
.option-picker .apply-btn {
  margin-top: 2px;
  margin-left: auto;
}
.option-picker .rm-pd {
  padding: 0px !important;
}

@media screen {
  .picky .picky__input {
    padding: 0 8px;
    height: 40px;
    position: relative;
    border: solid 1px #cccccc;
  }
}
@media screen {
  .picky .picky__input:hover {
    border-color: #999;
  }
}
@media screen {
  .picky .picky__input:focus {
    border-color: #000;
  }
}
.picky .picky__input.picky__input--disabled {
  border-color: #cccccc;
  background-color: hsl(0, 0%, 95%);
}
@media screen {
  .picky .picky__input::after {
    border: none;
    content: "expand_more";
    font-family: "Material Icons Outlined";
    pointer-events: none;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
@media screen {
  .picky .picky__input span {
    font-family: "National", sans-serif;
    font-size: 16px;
    color: #000;
  }
}
@media screen {
  .picky .picky__dropdown {
    width: auto;
    min-width: 100%;
    overflow-y: auto !important;
    margin: 8px 0 0 0;
    padding: 4px 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
@media screen {
  .picky .picky__dropdown .option {
    height: 32px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 0;
    border-right: solid 8px #fff;
    border-left: solid 8px #fff;
  }
}
@media screen {
  .picky .picky__dropdown .option:hover {
    background-color: #efefef;
    border-right: solid 8px #efefef;
    border-left: solid 8px #efefef;
  }
}
@media screen {
  .picky .picky__dropdown .option input[type=checkbox] {
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    flex-shrink: 0;
  }
}

.width__100 .picky__dropdown {
  width: 100%;
}

@media screen {
  .form__image-preview {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .form__image-preview img {
    max-width: 300px;
    max-height: 150px;
    margin: 0 auto 8px;
  }
}
@media screen {
  .form__image-preview strong {
    text-align: center;
  }
}

@media screen {
  .form-section__title {
    color: #00365f;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 8px 0;
  }
}

@media screen {
  .form-section__description {
    color: #999;
    font-size: 16px;
    line-height: 24px;
  }
}

.tokens-toggle {
  position: absolute;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}
.tokens-toggle.opened {
  background-image: url("../img/up.svg");
}
.tokens-toggle.closed {
  background-image: url("../img/down.svg");
}

.data-tokens .token {
  font-size: 13px;
  padding: 3px 10px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.capitalize {
  text-transform: capitalize;
}

.col-report-type {
  width: 60%;
}

.col-report-user, .col-export-date {
  width: 80%;
}

.th-wrap {
  width: 120px !important;
  white-space: initial !important;
}

.clubs-special-update__loader {
  display: block;
  margin: 0 auto;
}

.special-update__warning {
  color: #C9243F;
  margin-top: 15px;
}

#image-redirect-url {
  border-color: #999;
  border: solid 1px #cccccc;
  font-size: 16px;
  line-height: 24px;
  padding: 7px 8px;
  display: block;
  height: 40px;
}

.col-billing-cycle {
  width: 30%;
}

.col-report-user_name {
  width: 50%;
}

.col-billing-date {
  width: 45% !important;
}

.padding-16-y {
  padding-top: 16px;
  padding-bottom: 16px;
}

.hr-margin-24-top {
  margin-top: 24px;
}

.hr-margin-24-bottom {
  margin-bottom: 24px;
}

.hr-margin-16-bottom {
  margin-bottom: 16px;
}

.drag-drop-item_name {
  position: relative;
  top: -7px;
  left: 7px;
}

.collapse-arrow {
  color: black;
  position: relative;
  top: 7px;
}

.collapse-block {
  background: #efefef;
  padding: 15px !important;
  margin-top: -15px;
}

.message-align {
  position: absolute;
  top: 12px;
  right: 10px;
}

.icon-position {
  margin-right: 3px;
  font-size: 16px;
}

.note {
  background-color: #ffffcb;
  padding: 15px;
}

.table-left-cell {
  text-align: right !important;
  width: 37%;
  background-color: rgba(239, 239, 239, 0.5);
  border: 1px solid #d9d9d9;
}

.no-background .table-left-cell {
  background-color: #fff;
}

.table-right-cell {
  text-align: left !important;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.table-center-cell {
  text-align: center !important;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 0 !important;
}

.manual-error {
  border: 1px solid #C9243F;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.dotted_border {
  background-image: linear-gradient(to right, #ccc 70%, transparent 30%), linear-gradient(to left, #ccc 70%, transparent 30%), linear-gradient(to bottom, #ccc 70%, transparent 30%), linear-gradient(to top, #ccc 70%, transparent 30%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 10px 1.5px, 10px 1.5px, 1.5px 10px, 1.5px 10px;
}

.fit_image {
  width: fit-content;
  margin: 0 auto;
}

.image_placeholder {
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 15px auto;
  width: 450px;
}

.join_link_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.join_link_image_box {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  border: #CECECE;
  border-style: solid;
  padding: 15px;
}

.edit_join_link_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.small_table_button {
  width: 65px;
  height: 30px;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
}

.pd-l-13px {
  padding-left: 12px;
}

.pd-r-13px {
  padding-right: 12px;
}

.club-icons-legend {
  padding: 0px 4px;
}

.club-icons-legend-icon {
  padding-right: 2px;
  margin-top: -2px;
}

.float_right {
  float: right;
}

.medium-margin-top {
  margin-top: 30px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.border_box {
  line-height: 40px;
  font-family: "National", sans-serif;
  border-radius: 4px;
  padding: 0 16px;
  justify-content: center;
  border: 1px;
  border-style: solid;
  border-color: gray;
}
.border_box.centered {
  text-align: center;
}

.center_join_pages_navigation {
  width: fit-content;
  margin: 0 auto;
}

.panel__hr {
  margin-left: -16px;
  margin-right: -16px;
  border: 0;
  border-top: 1px solid #cccccc;
}

.hidden {
  display: none;
}

.fit_image_webkit {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

@media screen {
  .usga_logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #00365f;
    font-size: 13px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    background-color: #fff;
    position: relative;
    z-index: 9;
    cursor: pointer;
  }
}
@media screen and (min-width: 80em) {
  .usga_logo {
    font-size: 16px;
    line-height: 16px;
  }
}
@media screen {
  .usga_logo::before {
    content: "";
    display: block;
    width: 80px;
    height: 24px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: contain;
    background-image: url("../img/usga_logo.svg");
    margin: 0 8px 0 0;
  }
}
@media screen and (min-width: 80em) {
  .usga_logo::before {
    width: 90px;
    height: 32px;
  }
}

.join-pages-sentry-logo {
  display: block;
  height: 50px;
  width: auto;
  margin: 0 0 0 auto;
}
@media screen and (min-width: 48em) {
  .join-pages-sentry-logo {
    margin: 0 0 0 32px;
  }
}

.center-join-pages-logos {
  display: flex;
  justify-content: center;
}

.cmd-button {
  cursor: pointer;
  margin-left: 6px;
}

.black-text {
  color: #000;
}

.no-wrap {
  white-space: nowrap;
}

.width-860px {
  width: 860px;
}

.fr-box ul {
  list-style: disc;
}

.red-note {
  color: #da1a31;
  font-size: 16px;
  line-height: 1;
}

.blue_ribbon {
  display: flex;
}

.ribbon_text {
  color: #999;
  font-size: 14px;
  white-space: nowrap;
}
.ribbon_text i {
  margin-right: 6px;
}
.ribbon_text p {
  font-size: 15px;
}

.blue_ribbon.ribbon_hover .material-icons-outlined.blue:hover {
  cursor: pointer;
  color: #025b9f !important;
}

.blue_ribbon .material-icons-outlined {
  vertical-align: middle;
  color: #0089ce !important;
  font-size: 18px;
  display: flex;
  align-self: center;
}

.blue_ribbon:not(.ribbon_hover) .material-icons-outlined {
  display: flex;
  align-self: center;
}

.transaction_legend {
  margin-top: 16px;
}

.changed-column {
  color: #da1a31 !important;
}

.text_overflow .text-holder {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block !important;
}

.text_overflow span {
  display: contents !important;
}

.panel__head .red-note {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  margin-left: auto;
}

.regular_font .text-holder {
  font-size: 14px !important;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subparagraph {
  margin: 16px 0 0 8px;
}

.textarea-club-details {
  border-radius: 10px 10px 0 0;
  min-height: 150px;
  padding: 20px 20px;
}

.textarea-club-details:hover {
  border-color: #cccccc !important;
}

.pending-count-circle {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: orange;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 0 5px gray;
  z-index: 1;
}
.pending-count-circle.top {
  top: -15px;
  left: 0px;
  transform: translateX(200%);
}

.refund-hr {
  background-color: #d9d9d9;
  border: none;
  height: 1px;
  margin-bottom: 20px;
}

.refund-btn {
  border-radius: 0;
  border: 1px solid #c2c3c2;
  padding: 9px 0;
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  background-color: #f2f2f2;
}

.refund-btn.full-refund {
  margin-right: 3px;
}

.refund-btn.partial-refund {
  margin-left: 3px;
}

.refund-btn.selected {
  background-color: #b9e4f6;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.strong {
  font-weight: 700 !important;
}

.refund-fee {
  display: inline-flex;
  margin-bottom: 5px;
  line-height: 40px;
}
.refund-fee span {
  color: black;
  margin-left: 6px;
}
.refund-fee input {
  display: inline-flex;
  margin-top: 1px;
}

.inactivate-golfer {
  margin-bottom: 0;
  margin-left: auto;
  margin-top: 15px;
  line-height: inherit;
}

.refund-limit-note {
  text-align: end;
  font-style: italic;
  font-size: 13px;
  margin-top: 5px;
}

.refund-input {
  font-size: 14px !important;
}

.exit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 10px;
}

.exit-icon {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.margin-top-34 {
  margin-top: 34px !important;
}

.padding-10-y {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-image-note {
  font-size: 13px;
  margin-left: 27px;
  font-style: italic;
  margin-top: -8px;
  line-height: 13px;
}

.score-number_of_played_holes {
  vertical-align: sub;
  font-style: italic;
  font-size: smaller;
}

.blue_ribbon {
  display: flex;
}

.ribbon_text {
  color: #999;
  font-size: 14px;
  white-space: nowrap;
}
.ribbon_text i {
  margin-right: 6px;
}
.ribbon_text p {
  font-size: 15px;
}

.blue_ribbon.ribbon_hover .material-icons-outlined.blue:hover {
  cursor: pointer;
  color: #025b9f !important;
}

.blue_ribbon .material-icons-outlined {
  vertical-align: middle;
  color: #0089ce !important;
  font-size: 18px;
  display: flex;
  align-self: center;
}

.blue_ribbon:not(.ribbon_hover) .material-icons-outlined {
  display: flex;
  align-self: center;
}

.offer_legend {
  margin-top: 8px;
}

.offer_join_type li {
  margin-right: 30px;
}

.italic-placeholder::placeholder {
  font-style: italic;
  font-size: 14px;
}

.under_note {
  font-size: 13px;
  font-style: italic;
  line-height: 13px;
  margin-top: 4px;
}

.icon_label {
  flex: initial !important;
  justify-content: center !important;
  padding-right: 3px !important;
}

.icon_label {
  flex: initial !important;
  justify-content: center !important;
  padding-right: 3px !important;
}

.export_offer {
  text-decoration: underline;
  cursor: pointer;
  color: black !important;
  text-align: center;
  display: block;
  font-size: 18px;
}

.offer_code_name {
  display: inline-block;
  max-width: calc(100% - 18px);
  text-overflow: ellipsis;
  overflow: clip;
}

.col_offer_code .text_holder {
  text-overflow: initial !important;
}

.codes-display {
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.original-fee {
  color: #6c757d;
  white-space: nowrap;
  font-style: italic;
  line-height: 15px;
  font-size: 0.9em;
}

.discount-code-text {
  color: red;
  font-style: italic;
  white-space: nowrap;
  font-size: 0.9em;
}

.lock-icon-container {
  background-image: url("../img/lock.svg");
  background-size: cover;
  width: 18px;
  height: 18px;
  cursor: default !important;
}

.locked-membership-info-box .lock-icon-container-relative {
  position: relative !important;
  transform: translateY(3px);
  margin-left: 0 !important;
}

.locked-membership-info-box {
  border: 1px solid rgb(239, 239, 239);
  color: rgb(153, 153, 153);
  padding: 5px 20px 20px 20px;
  width: 230px;
  position: absolute;
  right: 40px;
  top: 166px;
}

.locked-membership-info-box .material-icons-outlined {
  position: absolute;
  margin-left: -20px;
  margin-top: -2px;
}

.locked-info-box-add-golfer {
  border: 1px solid rgb(239, 239, 239);
  color: rgb(153, 153, 153);
  padding: 5px 20px 15px 25px;
  width: 303px;
  position: absolute;
  right: 48px;
  top: 295px;
}

.locked-info-box-add-golfer .material-icons-outlined {
  position: absolute;
  margin-left: -20px;
  margin-top: -2px;
}

.locked-info-box-existing-golfer {
  border: 1px solid rgb(239, 239, 239);
  color: rgb(153, 153, 153);
  padding: 5px 20px 15px 25px;
  width: 307px;
  margin-left: 5px;
}

.locked-info-box-existing-golfer .material-icons-outlined {
  position: absolute;
  margin-left: -20px;
  margin-top: -2px;
}

.membership-description-dropdown {
  max-width: 90px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: end;
}

.change-membership-dropdown {
  max-width: 250px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: end;
}

.memberships-select-container .react-select__option--is-disabled {
  background-color: rgba(226, 226, 226, 0.768627451) !important;
}

.challenge-settings {
  height: 40px;
  font-family: "Arial", sans-serif;
}

.challenge-settings h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  height: 100%; /* Ensure the container has a defined height */
}

.challenge-form-settings {
  padding: 20px;
  padding-left: 0;
  font-family: "Arial", sans-serif;
}

.challenge-form-settings h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  height: 100%; /* Ensure the container has a defined height */
}

.challenge-form-settings p {
  margin: 12px 0;
  font-size: 16px;
  font-style: italic;
  color: #000;
}

.challenge-form-settings ul {
  padding-left: 20px;
  margin: 0;
  list-style-type: disc;
  color: #000;
}

.challenge-form-settings li {
  margin-bottom: 8px;
  font-size: 16px;
  color: #000;
}

.challenge-form-settings li sup {
  vertical-align: top;
  font-size: smaller;
  line-height: 0;
}

.challenge-button {
  margin-top: 10px;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  color: #00365f;
}

.btn.lnk.challenge-scores-button {
  color: rgb(74, 164, 74);
  border: solid 1.8px rgb(74, 164, 74) !important;
  border-radius: 5px;
  padding: 7px 5px 21px 5px;
  margin-left: 15px;
}

.challenge-invite-link {
  color: #048cee;
  text-decoration: underline;
  cursor: pointer;
}

.wrap-header {
  white-space: normal !important;
  word-wrap: break-word !important;
  text-align: center;
}

.share-challenge-modal__content {
  max-width: 800px;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.share-challenge-modal__header {
  background-color: #2f7bbd; /* blue header */
  color: white;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share-challenge-modal__header h2 {
  margin: 0;
  font-size: 18px;
}

.share-challenge-modal__close {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.share-challenge-modal__container {
  display: flex;
  flex-direction: column;
}

.share-challenge-modal__body {
  display: flex;
  padding: 20px;
  background-color: white;
}

.share-challenge-modal__content-left {
  padding-right: 20px;
  margin-left: 20px;
}

.share-challenge-modal__icon-check {
  color: #4CAF50;
  font-size: 36px;
  margin-bottom: 10px;
}

.share-challenge-modal__content-left h3 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 600;
  font-family: "National", sans-serif;
}

.share-challenge-modal__content-left p {
  margin: 10px 0;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.share-challenge-modal__link {
  color: #048cee;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  text-decoration: underline;
  display: block;
}

.share-challenge-modal__continue-btn {
  width: 75% !important;
  margin-top: 50px;
}

.share-challenge-modal__content-right img {
  max-width: 240px;
  height: auto;
}

@media (max-width: 600px) {
  .share-challenge-modal__body {
    flex-direction: column;
  }
  .share-challenge-modal__content-right {
    margin-top: 20px;
  }
}

