@use "mixins";
@use "variables";

@include mixins.keyframes(fade_in) {

    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }

}

@include mixins.keyframes(fade_in_up) {

    0% {
        opacity: 0;
        @include mixins.transform(translate3d(0,50%,0));
    }
    100% {
        opacity: 1;
        @include mixins.transform(translate3d(0,0,0));
    }

}

@include mixins.keyframes(error) {

	  0% {
		  color: variables.$white;
	  }
	  100% {
		  color: variables.$cardinal;
	  }

  }
@include mixins.keyframes(errorDisabled) {

	0% {
		color: variables.$white;
		background-color: variables.$cardinal;
	}
	100% {
		color: variables.$cardinal;
		background-color: gba(239, 239, 239, 0.5);
	}

}
