//  CIRCLE
.circle {

  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;

}
