.info-box-yellow{
  background-color: #FFFFCC;
  opacity: 34;
  border: 15px solid black;
  border-width: 1px;
  padding: 15px;
}

.color-red {
  color: #CC0000;
}

.font-weight-600 {
  font-weight: 600;
}
