.option-picker {
    padding: 15px;
    font-family: inherit;
    
    .first-title {
      font-weight: bold;
    }
    
    .title {
      margin-top: 10px;
      font-weight: bold;
    }
    
    .select-ranges-container {
      
      margin: 0px 0px;
      
      .line {
        margin-top: 12px;
        text-align: center;
        height: 40px;
      }
      
      .range-labels {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        margin: 0 0 8px 0;
        display: block;
        white-space: nowrap;
      }
    }  
    
    .group {
      .btn-group button {
        cursor: pointer;
        float: bottom;
        border: none;
        width: 50%;
        background: none;
        font-size: 15px;
        color: #999;
        text-align: left;
        font-family: 'National', sans-serif;
        -webkit-font-feature-settings: "tnum";
        font-feature-settings: "tnum";
        font-weight: 400;
        padding: 0px;
      }

      .btn-group button:hover {
        color: #00365f;
      }
      .btn-group button.active {
        color: #00365f;
      }
    }
    
    .apply-btn {
      margin-top: 2px;
      margin-left: auto;
    }
    
    .rm-pd{
      padding: 0px !important;
    }
  
}
