@use "../utils/variables";

.terms-and-privacy-title {
  font-weight: variables.$bold;
}

.terms-and-privacy-mt {
  margin-top: 10px;
}

.terms-and-privacy-url {
  word-break: break-all;
}
