@use "../utils/mixins";
@use "../utils/variables";

.radio__container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    input[type="radio"] {

        &.simple__radio {

            @include mixins.breakpoint() {
                display: none;
            }

            & + label {

                @include mixins.breakpoint() {

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    height: 40px;

                    margin: 0 16px 0 0;

                    cursor: pointer;

                }

                &::before {

                    @include mixins.breakpoint() {

                        content: '';
                        display: block;

                        width: 30px;
                        height: 30px;

                        margin: 0 16px 0 0;

                        border-radius: 50%;
                        border: solid 1px variables.$random_gray;

                        cursor: pointer;

                        background-image: radial-gradient(variables.$white 0px, variables.$white 0px);

                    }

                }

            }

            &:checked {

                @include mixins.breakpoint() {

                }

                & + label {

                    @include mixins.breakpoint() {

                    }

                    &::before {

                        @include mixins.breakpoint() {

                            background-image: radial-gradient(variables.$light_green 8px, variables.$white 8px);

                        }

                    }

                }

            }

			&:disabled{
				& + label {
					pointer-events: none;
					opacity: 0.25;
				}

                &.full-opacity + label {
                    opacity: 1;
                }
			}

        }

    }

}

//  BADGE RADIO CONTAINER
.badge-radio__container {

    .badge-radio {

        @include mixins.breakpoint() {
            margin: 0 0 16px 0;
        }

        input[type="radio"] {

            @include mixins.breakpoint() {
                display: none;
            }

            & + label {

                @include mixins.breakpoint() {

                    cursor: pointer;

                }

                &:after {

                    @include mixins.breakpoint() {

                        content: '';
                        display: block;

                        width: 30px;
                        height: 30px;

                        margin: 8px auto 0;

                        border-radius: 50%;
                        border: solid 1px variables.$random_gray;

                        cursor: pointer;

                        background-image: radial-gradient(variables.$white 0px, variables.$white 0px);

                    }

                }

            }

            &:checked {

                & + label {

                    &:after {

                        @include mixins.breakpoint() {

                            background-image: radial-gradient(variables.$light_green 8px, variables.$white 8px);

                        }

                    }

                }

            }

        }

    }

}

//  CUSTOM RADIO
.custom-radio__container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

        width: 100%;

        @include mixins.box-shadow(0 0 0 1px variables.$medium_gray);

    }

    input[type="radio"] {

        @include mixins.breakpoint() {
            display: none;
        }

        & + label {

            @include mixins.breakpoint() {

                flex: 1 1 0;

                font-size: 16px;
                line-height: 40px;

                cursor: pointer;

                text-align: center;

                margin: 0;

                border-right: solid 1px variables.$medium_gray;

                @include mixins.user-select(none);

            }

            &:last-child {
                @include mixins.breakpoint() {
                    border-right: none;
                }
            }

        }

        &:checked {

            & + label {

                @include mixins.breakpoint() {

                    color: variables.$white;
                    background-color: variables.$blue;

                }

            }

        }

        &:disabled {

            & + label {
                @include mixins.breakpoint() {
                    opacity: 0.25;
					pointer-events: none;
                }
            }

        }

    }

}
