@use "../utils/mixins";
@use "../utils/variables";

.dropzone {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 260px;

        background-color: variables.$light_gray;
        
        background-image: linear-gradient(to right, #ccc 70%, transparent 30%),
                        linear-gradient(to left, #ccc 70%, transparent 30%),
                        linear-gradient(to bottom, #ccc 70%, transparent 30%),
                        linear-gradient(to top, #ccc 70%, transparent 30%);
                            
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;

        cursor: pointer;

        padding: 32px;

    }

    .material-icons-outlined {

        @include mixins.breakpoint() {

            color: variables.$dark_gray;

            font-size: 72px;

            margin: 0 auto;

        }

    }

    p {

        @include mixins.breakpoint() {

            color: variables.$dark_gray;

            font-size: 16px;
            line-height: 24px;

            text-align: center;

        }

    }

    .btn {

        @include mixins.breakpoint() {

            margin: auto 0 0 0;

        }

    }

}